import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  Text,
  Row,
  Paper,
  CategoryLegend
} from '@ui';
import { LadTransactionsQuery } from 'generated';
import { useMemo, useState } from 'react';
import { ChartTooltip, Line, LinearAxisTickLabel, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';
import { TextData } from './styled';

import AllTypes from '@assets/icons/all.png';
import Room from '@assets/icons/residential.png';
import Studio from '@assets/icons/hospital.png';
import Bed1 from '@assets/icons/office.png';
import Bed2 from '@assets/icons/retail.png';
import Bed3 from '@assets/icons/health.png';
import Bed4 from '@assets/icons/health.png';

export default function PrivateSectorRents({ data }:{ data?: LadTransactionsQuery}) {
  const categoryData = [
    {
      key: '4+ Bed',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t6_rental_monthly_private_4bedplus_mean_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t6_rental_monthly_private_4bedplus_mean_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t6_rental_monthly_private_4bedplus_mean_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t6_rental_monthly_private_4bedplus_mean_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t6_rental_monthly_private_4bedplus_mean_2021 || 0
        }
      ]
    },
    {
      key: '3 Bed',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t6_rental_monthly_private_3bed_mean_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t6_rental_monthly_private_3bed_mean_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t6_rental_monthly_private_3bed_mean_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t6_rental_monthly_private_3bed_mean_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t6_rental_monthly_private_3bed_mean_2021 || 0
        }
      ]
    },
    {
      key: '2 Bed',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t6_rental_monthly_private_2bed_mean_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t6_rental_monthly_private_2bed_mean_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t6_rental_monthly_private_2bed_mean_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t6_rental_monthly_private_2bed_mean_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t6_rental_monthly_private_2bed_mean_2021 || 0
        },
      ]
    },
    {
      key: '1 Bed',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t6_rental_monthly_private_1bed_mean_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t6_rental_monthly_private_1bed_mean_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t6_rental_monthly_private_1bed_mean_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t6_rental_monthly_private_1bed_mean_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t6_rental_monthly_private_1bed_mean_2021 || 0
        },
      ]
    },
    {
      key: 'Studio',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t6_rental_monthly_private_studio_mean_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t6_rental_monthly_private_studio_mean_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t6_rental_monthly_private_studio_mean_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t6_rental_monthly_private_studio_mean_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t6_rental_monthly_private_studio_mean_2021 || 0
        },
      ]
    },
    {
      key: 'Room',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t6_rental_monthly_private_room_mean_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t6_rental_monthly_private_room_mean_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t6_rental_monthly_private_room_mean_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t6_rental_monthly_private_room_mean_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t6_rental_monthly_private_room_mean_2021 || 0
        }
      ]
    }
  ]
  

  const types = [
    {
      id: 'AllTypes',
      src: AllTypes,
    },
    {
      id: 'Studio',
      src: Studio,
    },
    {
      id: 'Room',
      src: Room,
    },
    {
      id: '1 Bed',
      src: Bed1,
    },
    {
      id: '2 Bed',
      src: Bed2,
    },
    {
      id: '3 Bed',
      src: Bed3,
    },
    {
      id: '4+ Bed',
      src: Bed4,
    }
  ] as const

  type Modes = (typeof types)[number]['id']
  const [mode, setMode] = useState<Modes>('AllTypes')

  const filtered = categoryData.filter(v=>mode==='AllTypes' || v.key===mode)
  const min = useMemo(()=>
    mode==='AllTypes'?0:
    Math.min(
    ...(filtered
        .map(v=>Math.min(...v.data.map(d=>d.data))
        ) as number[])) * 0.75,
    [filtered, mode])

  const max = useMemo(()=>
    Math.max(
    ...(filtered
        .map(v=>Math.max(...v.data.map(d=>d.data))
        ) as number[])) * 1.2,
    [filtered])

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Private Sector Rents:</Fonts.Header>
        <Row justifyContent='space-between' m='1rem 0 2rem 0'>
          {types.map((p,key)=>
            <Paper {...{key}} hoverable active={p.id===mode} onClick={()=>setMode(p.id)}>
              <img {...p} style={{height: '42px', margin: '2px 14px'}}/>
              <Text width='100%' mb='0.5rem' textAlign='center'>{p.id}</Text>
            </Paper>)}
        </Row>
        <Fonts.Header>Avg. Monthly Private Sector Rent</Fonts.Header>

        <ChartRow>
          <ChartContainer ml="2.5rem">
            <LineChart
              width={500}
              height={250}
              yAxis={<LinearYAxis
                domain={[min,max]}
                />}
              xAxis={<LinearXAxis
                domain={[2016,2022]}
                 type="value" tickSeries={
                <LinearAxisTickSeries label={
                  <LinearAxisTickLabel 
                    padding={{
                      fromAxis: 1,
                      alongAxis: 0
                    }}
                    position='end'
                    format={(v)=>`${v}`}/>} 
                    interval={5}/>} />} 
              data={filtered}
              series={
                <LineSeries
                  tooltip={<TooltipArea disabled={true} tooltip={<ChartTooltip visible={false}/>}/>}
                  interpolation="linear"
                  type="grouped"
                  colorScheme="#34c3ca"
                  line={
                    <Line  
                      strokeWidth={2} 
                      style={(data:any) => {
                        if(data?.[0]?.key?.includes?.('4+ Bed')){
                          return {
                            stroke: '#36c8d1',
                          };
                        }else if(data?.[0]?.key?.includes?.('3 Bed')){
                          return {
                            stroke: '#d15a35',
                          };
                        }else if(data?.[0]?.key?.includes?.('2 Bed')){
                          return {
                            stroke: '#e141e4',
                          };
                        }else if(data?.[0]?.key?.includes?.('1 Bed')){
                          return {
                            stroke: '#494e67',
                          };
                        }else if(data?.[0]?.key?.includes?.('Studio')){
                          return {
                            stroke: '#6468c0',
                          };
                        }else{
                          return {
                            stroke: '#bce8ec',
                          };
                        }
                      }}
                    />}
                />
              }
            />
          <CategoryLegend legends={[
            { label:'4+ Bed', color: '#36c8d1' },
            { label:'3 Bed', color: '#d15a35' },
            { label:'2 Bed', color: '#e141e4' },
            { label:'1 Bed', color: '#494e67' },
            { label:'Studio', color: '#6468c0' },
            { label:'Room', color: '#bce8ec' }
          ]} position='absolute' right='0rem' top='1rem' />

          <Text customColor='grey' position='absolute' left='-6rem !important' top='90px !important' transform='rotate(-90deg)'>Avg. Monthly Rent, £</Text>

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

import {
  StatsRow,
  Title,
  Row,
  Paper,
  Text
} from '@ui';
import { LadPlanningQuery } from 'generated';

export default function DistrictPlanningAuthorities({ data }:{ data?: LadPlanningQuery}) {

  return (
    <StatsRow>
      <div>
        <Title h='h4' m='0' mb='2rem !important' bold>
          District planning authorities - planning applications decided and granted, performance agreements and speed of decisions on major and minor residential developments
        </Title>
        <Row mb='5px'>
          <Paper noStyles gap='5px'>
            <Row height='2rem' width='2rem' gap='5px'></Row>
            <Row width='2.6rem' height='2rem' gap='5px'>
              <Text customSize='t7' textAlign='start'>Major residential</Text>
            </Row>
            <Row width='2.6rem' height='2rem' gap='5px'>
              <Text customSize='t7' textAlign='start'>Major residential</Text>
            </Row>
          </Paper>
          <Paper noStyles gap='5px' mr='5px'>
            <Row height='2rem' gap='5px'>
              <Paper width='3.5rem' height='100%' p='2px' alignItems='center' style={{backgroundColor:'#494e67'}}>
                <Text customSize='t7' bold customColor='white'>Last 12 month</Text>
              </Paper>
              <Paper width='2.5rem' height='100%' alignItems='center' style={{backgroundColor:'#494e67'}}>
                <Text customSize='t7' bold customColor='white'>2Y</Text>
              </Paper>
              <Paper width='2.5rem' height='100%' alignItems='center' style={{backgroundColor:'#494e67'}}>
                <Text customSize='t7' bold customColor='white'>5Y</Text>
              </Paper>
            </Row>
            <Row width='10.5rem' height='3rem' gap='5px'>
              <Paper flex='1 0 auto' height='100%' p='2px' >
                <Text customSize='t7' textAlign='start' >Total decisions:</Text>
                <Text  m='0' bold>688</Text>
              </Paper>
              <Paper flex='1 0 auto' height='100%' p='2px' >
                <Text customSize='t7' textAlign='start' >Total granted:</Text>
                <Text  m='0' bold>1,168</Text>
              </Paper>
              <Paper flex='1 0 auto' height='100%' p='2px' >
                <Text customSize='t7' textAlign='start' >Approval %</Text>
                <Text  m='0' bold>81%</Text>
              </Paper>
            </Row>
            <Row width='10.5rem' height='3rem' gap='5px'>
              <Paper flex='1 0 auto' height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Total decisions:</Text>
                <Text  m='0' bold>1123</Text>
              </Paper>
              <Paper flex='1 0 auto' height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Total granted:</Text>
                <Text  m='0' bold>1,168</Text>
              </Paper>
              <Paper flex='1 0 auto' height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Approval %:</Text>
                <Text  m='0' bold>89%</Text>
              </Paper>
            </Row>
          </Paper>
          <Paper noStyles gap='5px' mr='10px' style={{backgroundColor:'#494e67', padding:'5px', borderRadius:'10px'}}>
            <Row height='2rem' >
              <Title bold h='h5' customColor='white'>Speed of decisions</Title>
            </Row>
            <Row width='8rem' height='3rem' gap='5px'>
              <Paper flex='1 1 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Within 13 weeks or agreed time:</Text>
                <Text  m='0' bold>88%</Text>
              </Paper>
              <Paper flex='1 1 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Over 13 weeks or agreed time:</Text>
                <Text  m='0' bold>12%</Text>
              </Paper>
            </Row>
            <Row width='8rem' height='3rem' gap='5px'>
              <Paper flex='1 1 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Within 8 weeks or agreed time:</Text>
                <Text  m='0' bold>92%</Text>
              </Paper>
              <Paper flex='1 1 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Over 8 weeks or agreed time:</Text>
                <Text  m='0' bold>8%</Text>
              </Paper>
            </Row>
          </Paper>
          <Paper noStyles gap='5px'>
            <Row height='2rem' >
              <Text customSize='t7'>
                Performance agreements, extension of time or Environmental impact Assessments
              </Text>
            </Row>
            <Row width='9.6rem' height='3rem' gap='5px'>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Total decisions:</Text>
                <Text  m='0' bold>1,368</Text>
              </Paper>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Total granted:</Text>
                <Text  m='0' bold>1,168</Text>
              </Paper>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Approval %</Text>
                <Text  m='0' bold>86%</Text>
              </Paper>
            </Row>
            <Row width='9.6rem' height='3rem' gap='5px'>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Total decisions::</Text>
                <Text  m='0' bold>1,368</Text>
              </Paper>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Total granted::</Text>
                <Text  m='0' bold>1,168</Text>
              </Paper>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start' >Approval %</Text>
                <Text  m='0' bold>86%</Text>
              </Paper>
            </Row>
          </Paper>
        </Row>
        <Row mb='3rem' ml='6.8rem'>
          <Paper noStyles gap='5px'>
            <Row width='3.2rem' height='2rem' gap='5px'>
              <Paper noStyles>
                <Text customSize='t7' bold textAlign='start'>National Benchmark</Text>
                <Text customSize='t7' textAlign='start'>Major (Minor)</Text>
              </Paper>
            </Row>
          </Paper>
          <Paper noStyles gap='5px' mr='10px' style={{backgroundColor:'#494e67', padding:'5px', borderRadius:'10px'}}>
            <Row width='11.5rem' height='3rem' gap='5px'>
              <Paper flex='1 0 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start'>Approval %:</Text>
                <Text  m='0' bold>87%<Text customSize='t7' m='0'>(87%)</Text></Text>
              </Paper>
              <Paper flex='1 1 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start'>Within 13/8 weeks or agreed time:</Text>
                <Text  m='0' bold>88%<Text customSize='t7' m='0'>(72%)</Text></Text>
              </Paper>
              <Paper flex='1 1 auto'  height='100%' p='2px'>
                <Text customSize='t7' textAlign='start'>Over 13/8 weeks or agreed time:</Text>
                <Text  m='0' bold>12%<Text customSize='t7' m='0'>(28%)</Text></Text>
              </Paper>
            </Row>
          </Paper>
        </Row>
      </div>
    </StatsRow>
  );
}


import { useLadDemandQuery } from 'generated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GenerationSplit from './GenerationSplit';
import HouseholdSize from './HouseholdSize';
import HouseholdWaitlist from './HouseholdWaitlist';
import Population from './Population';
import PopulationChange from './PopulationChange';
import PopulationPyramid from './PopulationPyramid';

export default function Demand() {
  const { ladCode = '' } = useParams<{ ladCode: string }>();
  const { data:sourceData } = useLadDemandQuery({ variables: { ladCode } });
  const [data, setData] = useState(sourceData)
  
  useEffect(()=>{
    if(sourceData){
      setData(sourceData)
    }
  },[sourceData])

  return (
    <>
      <Population {...{data}}/>
      <HouseholdSize {...{data}}/>
      <HouseholdWaitlist {...{data}}/>
      <GenerationSplit {...{data}}/>
      <PopulationPyramid {...{data}}/>
      <PopulationChange {...{data}}/>
    </>
  );
}

import {
  StatsRow,
  Fonts,
  ChartContainer,
  Text,
  Paper,
  Title,
  Row
} from '@ui';
import { LadDemandQuery, LadIncomeQuery } from 'generated';
import { BarSeries } from 'misc/reaviz/BarSeries';
import { Bar, BarChart, BarLabel,  LinearAxisTickLabel, LinearAxisTickSeries, LinearXAxis, LinearXAxisTickSeries, LinearYAxis, LinearYAxisTickLabel, LinearYAxisTickSeries } from 'reaviz';

export default function AnnualHouseholdIncome({ data }:{ data?: LadIncomeQuery}) {
  
  const categoryData = [
    {
      key: 'Total',
      data: [
        data?.lad?.aff_income?.total_annual_income_2018_min,
        data?.lad?.aff_income?.total_annual_income_2018_max
      ]
    },
    {
      key: 'Net',
      data: [
        data?.lad?.aff_income?.net_annual_income_2018_min,
        data?.lad?.aff_income?.net_annual_income_2018_max
      ]
    },
    {
      key: 'Net, before housing',
      data: [
        data?.lad?.aff_income?.net_annual_income_before_house_2018_min,
        data?.lad?.aff_income?.net_annual_income_before_house_2018_max
      ]
    },
    {
      key: 'Net, after housing',
      data: [
        data?.lad?.aff_income?.net_annual_income_after_house_2018_min,
        data?.lad?.aff_income?.net_annual_income_after_house_2018_max
      ]
    },
  ]

  const normalData = [
    {
      key: 'Total',
      data: [
        data?.lad?.aff_income?.total_annual_income_2018,
        data?.lad?.aff_income?.total_annual_income_2018
      ]
    },
    {
      key: 'Net',
      data: [
        data?.lad?.aff_income?.net_annual_income_2018,
        data?.lad?.aff_income?.net_annual_income_2018
      ]
    },
    {
      key: 'Net, before housing',
      data: [
        data?.lad?.aff_income?.net_annual_income_before_house_2018,
        data?.lad?.aff_income?.net_annual_income_before_house_2018
      ]
    },
    {
      key: 'Net, after housing',
      data: [
        data?.lad?.aff_income?.net_annual_income_after_house_2018,
        data?.lad?.aff_income?.net_annual_income_after_house_2018
      ]
    },
  ]



  const min = 0; // Math.min(...(categoryData.map(v=>v.data[0]) as number[])) * 0.8;
  const max = Math.max(...(categoryData.map(v=>v.data[1]) as number[])) * 1.2; 

  const thikness = max*0.01;

  const modifiedData = normalData.map(v=>({
    ...v,
    data: [v.data[0] - thikness, v.data[1]]
  }))



  return (
    <StatsRow>
      <div>
        <Fonts.Header>Annual Household Income</Fonts.Header>
        <ChartContainer ml='2.5rem'>
          <BarChart
            style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
            width={500}
            height={250}
            data={categoryData}
            centerY
            yAxis={<LinearYAxis domain={[min,max]} />}
            series={
              <BarSeries
                isCategorical
                type='standard'
                tooltip={null}
                bar={
                  <Bar
                    gradient={null}
                    label={null}
                    color="#34c3ca"
                    style={(data:any) => {
                      if (data.key) {
                        return {
                          fill: '#34c3ca',
                          width: '70px',
                          transform: 'translateX(15px)'
                        };
                      }
                    }}
                  />
                }
              />
            }
          />
          <BarChart
            style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
            width={500}
            height={250}
            data={modifiedData}
            centerY
            yAxis={<LinearYAxis domain={[min,max]} />}
            series={
              <BarSeries
                isCategorical
                type='standard'
                tooltip={null}
                bar={
                  <Bar
                    gradient={null}
                    label={null}
                    style={(data:any) => {
                      if (data.key) {
                        return {
                          fill: '#4b4b4b',
                          strokeLocation: 'inside',
                          width: '100px'
                        };
                      }
                    }}
                  />
                }
              />
            }
          />
        <Paper noStyles position='absolute' right='-1rem' top='-1rem !important' justifyContent='center' alignItems='center'>
          <Title>
            Mean income (Total):
          </Title>
          <Title bold h='h3' m='0' >
          £{Math.round((data?.lad?.aff_income?.total_annual_income_2018_max + data?.lad?.aff_income?.total_annual_income_2018_min) / 2 || 0).toLocaleString()}
          </Title>
          <Row>
            <Paper noStyles mr='0.5rem' alignItems='center'>
              <Text customSize='t6'>Upper limit:</Text>
              <Text customSize='t6' bold>£{Math.round(data?.lad?.aff_income?.total_annual_income_2018_max || 0).toLocaleString()}</Text>
            </Paper>
            <Paper noStyles alignItems='center'>
              <Text customSize='t6'>Lower limit:</Text>
              <Text customSize='t6' bold>£{Math.round(data?.lad?.aff_income?.total_annual_income_2018_min || 0).toLocaleString()}</Text>
            </Paper>
          </Row>
          <Paper noStyles alignItems='center'>
            <Text customSize='t6'>UK average:</Text>
            <Text customSize='t6' bold>£32,100</Text>
          </Paper>
        </Paper>

        <Text customColor='grey' position='absolute' left='-5.5rem !important' top='90px !important' transform='rotate(-90deg)'>Annual Income, £</Text>

        </ChartContainer>
      </div>
    </StatsRow>
  );
}

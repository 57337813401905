import { Avatar } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import {
  Navigation,
  BarChart,
  Search,
  LocationCity,
  Description,
  SettingsOutlined,
  Exposure,
  ExitToApp,
} from '@material-ui/icons';
import { atoms, auth } from 'misc';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { BottomBlock, MenuWrapper, SidebarWrapper, TopBlock } from './styles';
import Logo from '@assets/icons/logo.png';
import { Divider } from 'containers/styles';

function Block({
  active = false,
  text = '',
  icon = <Navigation />,
  onClick = () => {
    return;
  },
}) {
  return (
    <>
      <MenuWrapper {...{ active, onClick }}>
        <div>{icon}</div>
        <Typography
          variant="body2"
          style={{
            color: '#969cba',
            fontSize: '0.7rem',
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        >
          {text}
        </Typography>
      </MenuWrapper>
      <Divider />
    </>
  );
}

export default function Sidebar() {
  const [user] = useRecoilState(atoms.user);
  const [userProfile, setUserProfile] = useRecoilState(atoms.userProfile);
  const [settings, setSettings] = useRecoilState(atoms.settings);
  const navigate = useNavigate();
  const location = useLocation();
  const [viewport, setViewport] = useRecoilState(atoms.viewport);

  

  return (
    <SidebarWrapper>
      <div>
        <TopBlock>
          <div onClick={() => window.location.href = 'https://insightsnow.co.uk'}>
            <img src={Logo} alt="" className="logo" />
          </div>
          <Divider />
          
          <Block
            onClick={() => navigate('/explore')}
            active={location.pathname.includes('explore')}
            text="Explore"
            icon={<Navigation />}
          />
          <Block
            onClick={() => {
              navigate('/market');
              setViewport({ ...viewport, zoom: 1.5, latitude: 25});
            }}
            active={location.pathname.includes('market')}
            text="Market Analysis"
            icon={<BarChart />}
          />
          <Block
            onClick={() => {
              navigate('/parcels');
              setViewport({
                ...viewport,
                zoom: 11.6,
                latitude: 51.4881,
                longitude: -0.1013,
              });
            }}
            active={location.pathname.includes('parcels')}
            text="Parcel Analysis"
            icon={<Search />}
          />
          {/* <Block
            onClick={() => navigate('/finance')}
            active={location.pathname.includes('finance')}
            text="Feasibility & Financials"
            icon={<Exposure />}
          />
          <Block
            onClick={() => navigate('/design')}
            active={location.pathname.includes('design')}
            text="Generative Design"
            icon={<LocationCity />}
          />
          <Block
            onClick={() => navigate('/project-brief')}
            active={location.pathname.includes('project-brief')}
            text="Project Brief"
            icon={<Description />}
          /> */}
        </TopBlock>
        <BottomBlock>
          {/* <Account /> */}
          <Avatar
            onClick={() => setUserProfile(!userProfile)}
            alt={user?.display_name || 'User'}
            src={user?.avatar_url || ''}
          >
            {(user?.display_name || 'User')
              .split(' ')
              .map((l) => l.slice(0, 1).toUpperCase())
              .join('')}
          </Avatar>
          <Divider />
          <Block
            onClick={() => {
              //setUserProfile(false);//?
              auth.logout();
              console.log('auth.logout()')
            }}
            text="Log out"
            icon={<ExitToApp fontSize="large" />}
          />
          <Block 
          onClick={() => {console.log('settings', !settings); setSettings(!settings);}}
          text="Settings" 
          icon={<SettingsOutlined fontSize="large" />} />

        </BottomBlock>
      </div>
    </SidebarWrapper>
  );
}

import {
  StatsRow,
  Fonts,
  ChartContainer,
  CategoryLegend,
  Text,
  ChartRow
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Bar, LinearYAxis, StackedBarChart } from 'reaviz';
import { useMemo } from 'react';
import { StackedBarSeries } from 'misc/reaviz/StackedBarSeries';

export default function VacanciesByType({ data }:{ data?: LadSupplyQuery}) {

  const categoryData = useMemo(()=>
  [
    {
      key: '2016',
      data: [
        {
          key: 'Short-time vacancies',
          data: data?.lad?.vacancy?.s9_short_term_vac_2016
        },
        {
          key: 'All long-term vacants (all tenures)',
          data: data?.lad?.vacancy?.s9_long_term_vac_2016
        },
        {
          key: 'Local authority vacants',
          data: data?.lad?.vacancy?.s9_local_auth_vac_2016
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only)',
          data: data?.lad?.vacancy?.s9_prp_vac_2016
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only) not available for lettings',
          data: data?.lad?.vacancy?.s9_prp_unavail_vac_2016
        }
      ]
    },
    {
      key: '2017',
      data: [
        {
          key: 'Short-time vacancies',
          data: data?.lad?.vacancy?.s9_short_term_vac_2017
        },
        {
          key: 'All long-term vacants (all tenures)',
          data: data?.lad?.vacancy?.s9_long_term_vac_2017
        },
        {
          key: 'Local authority vacants',
          data: data?.lad?.vacancy?.s9_local_auth_vac_2017
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only)',
          data: data?.lad?.vacancy?.s9_prp_vac_2017
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only) not available for lettings',
          data: data?.lad?.vacancy?.s9_prp_unavail_vac_2017
        }
      ]
    },
    {
      key: '2018',
      data: [
        {
          key: 'Short-time vacancies',
          data: data?.lad?.vacancy?.s9_short_term_vac_2018
        },
        {
          key: 'All long-term vacants (all tenures)',
          data: data?.lad?.vacancy?.s9_long_term_vac_2018
        },
        {
          key: 'Local authority vacants',
          data: data?.lad?.vacancy?.s9_local_auth_vac_2018
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only)',
          data: data?.lad?.vacancy?.s9_prp_vac_2018
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only) not available for lettings',
          data: data?.lad?.vacancy?.s9_prp_unavail_vac_2018
        }
      ]
    },
    {
      key: '2019',
      data: [
        {
          key: 'Short-time vacancies',
          data: data?.lad?.vacancy?.s9_short_term_vac_2019
        },
        {
          key: 'All long-term vacants (all tenures)',
          data: data?.lad?.vacancy?.s9_long_term_vac_2019
        },
        {
          key: 'Local authority vacants',
          data: data?.lad?.vacancy?.s9_local_auth_vac_2019
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only)',
          data: data?.lad?.vacancy?.s9_prp_vac_2019
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only) not available for lettings',
          data: data?.lad?.vacancy?.s9_prp_unavail_vac_2019
        }
      ]
    },
    {
      key: '2020',
      data: [
        {
          key: 'Short-time vacancies',
          data: data?.lad?.vacancy?.s9_short_term_vac_2020
        },
        {
          key: 'All long-term vacants (all tenures)',
          data: data?.lad?.vacancy?.s9_long_term_vac_2020
        },
        {
          key: 'Local authority vacants',
          data: data?.lad?.vacancy?.s9_local_auth_vac_2020
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only)',
          data: data?.lad?.vacancy?.s9_prp_vac_2020
        },
        {
          key: 'Private Registered Provider (housing association) vacants (general needs stock only) not available for lettings',
          data: data?.lad?.vacancy?.s9_prp_unavail_vac_2020
        }
      ]
    }
  ],
  [data])


  // const min = 0;
  const min = categoryData? Math.min(...(categoryData?.map(v=>Math.min(...v.data.map(d=>d.data))) as number[])) * 1.2: 1; 

  const max = categoryData? Math.max(...(categoryData?.map(v=>v.data.reduce((agg,d)=>agg+d.data,0)) as number[])) * 1.2: 1; 

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Vacancies by Type</Fonts.Header>
        <ChartRow>
          <ChartContainer ml="2.5rem">
            <StackedBarChart
              width={260}
              height={175}
              data={categoryData}
              yAxis={<LinearYAxis domain={[min,max]} />}              
              series={
                <StackedBarSeries
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      tooltip={null}
                      style={(data:any) => {
                        if(data.x.includes('Short-term')){
                          return {
                            fill: '#36c8d1',
                            key: data.key + data.x
                          };
                        }else if(data.x.includes('All long-term')){
                          return {
                            fill: '#e141e4',
                            key: data.key + data.x
                          };
                        }else if(data.x.includes('Local authority')){
                          return {
                            fill: '#c4c4c4',
                            key: data.key + data.x
                          };
                        }else if(data.x.includes('not available for lettings')){
                          return {
                            fill: '#7cd4d9',
                            key: data.key + data.x
                          };
                        }else if(data.x.includes('Private Registered Provider')){
                          return {
                            fill: '#e4bce5',
                            key: data.key + data.x
                          };
                        }else{
                          return {
                            fill: '#898989',
                            key: data.key + data.x
                          };                       
                        }
                      }}
                    />
                  }
                />
              }
            />
          <Text customColor='grey' position='absolute' left='-3.5rem !important' top='60px !important' transform='rotate(-90deg)'># units</Text>

          </ChartContainer>
          <ChartContainer ml="0px">
            <CategoryLegend customSize='t6' legends={[
              { label:<>Short term vacancies</>, color: '#36c8d1' },
              { label:<>All long term vacants (all tenures)</>, color: '#e141e4' },
              { label:<>Local authority vacants</>, color: '#c4c4c4' },
              { label:<>Private Registered Provider (housing association) vacants (general needs stock only)</>, color: '#e4bce5' },
              { label:<>Private Registered Provider (housing association) vacants (general needs stock only) not available for lettings</>, color: '#7cd4d9' },
              { label:<>Other public sector vacants (discontinued)</>, color: '#898989' },
            ]} width='260px' ml='-1.5rem' />

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

import { Header, Wrapper, TextInfo, MailIcon } from './styles';
import { useQuery } from 'misc';
import { useEffect, useState } from 'react';

export default function ActivateAccount() {
  const ticket = useQuery().get('ticket');
  const approved = useQuery().get('approved');

  const [status, setStatus] = useState<
    | { current: 'loading' }
    | { current: 'no-ticket' }
    | { current: 'activated'; data: any }
    | { current: 'refused'; data: any }
    | { current: 'error'; data: any }
  >(ticket ? { current: 'loading' } : { current: 'no-ticket' });

  useEffect(() => {
    if (ticket && approved !== null) {
      fetch(
        `${
          window.location.origin.includes('localhost')
            ? 'http://localhost:4000'
            : 'https://api.insightsnow.co.uk'
        }/admin/activate?ticket=${ticket}&activated=${approved}`
      )
        .then((data) => {
          if (data?.ok) {
            setStatus({
              current: approved === 'true' ? 'activated' : 'refused',
              data,
            });
          } else {
            setStatus({ current: 'error', data });
          }
        })
        .catch((err) => {
          console.log(err);
          setStatus({ current: 'error', data: err });
        });
    }
  }, [ticket, approved]);

  return (
    <div>
      <Wrapper>
        <MailIcon />
        <Header>
          {status.current === 'no-ticket'
            ? 'No ticket found'
            : status.current === 'loading'
            ? 'Activating account...'
            : status.current === 'activated'
            ? 'Account activated!'
            : status.current === 'refused'
            ? 'Refused!'
            : 'Error'}
        </Header>
        <TextInfo>
          {status.current === 'no-ticket'
            ? 'No ticket was found for this activation request.'
            : status.current === 'loading'
            ? 'Please, wait for a while, we are activating account.'
            : status.current === 'activated'
            ? 'Account has been activated! User will receive an email with further details.'
            : status.current === 'refused'
            ? 'Account has been refused - user will receive email with further details.'
            : 'There was an error activating account,'}
        </TextInfo>
        <span>Copyright &copy; InsightsNow, 2023</span>
      </Wrapper>
    </div>
  );
}


import { colors } from './colors'
import { buttonTypes, buttonSizes } from './inputs/buttons';
import { font } from './font'
import { css } from './css';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { getWindowDimensions } from '../hooks';

export type Theme = typeof styledTheme;

export const styledTheme = {
  id:"T-001",
  name:'main',
  shadow: css`box-shadow: 3px 3px 3px rgba(0,0,0,0.15);`,
  colors,
  buttonTypes,
  buttonSizes,
  font
} as const;

export const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      light: '#ffffff',
      main: '#35C8D1',
      dark: '#35C8D1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px rgba(0,0,0,0) inset',
            WebkitTextFillColor: '#000',
            WebkitHeight: '3rem',
            height: '72px',
            borderRadius: '3px',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 100,
        },
      },
    },
  },
};

const { width } = getWindowDimensions();
const layout = (width<960)? 'mobile': 'desktop'

export const theme = {...createTheme(themeConfig), ...styledTheme, layout};

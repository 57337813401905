import { animated } from 'react-spring';
import styled from 'styled-components';
import ReactCountryFlagUnstyled from 'react-country-flag';
import TextField from '@material-ui/core/TextField';
import MuiButton from '@material-ui/core/Button';
export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-align: center;
    background-color: hsl(185, 0%, 18%);
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
  `,
  ProfileWrapper = styled.div`
    background-color: #373b53;
    overflow-wrap: break-word;
    z-index: 60;
    min-width: 220px;
    max-width: 300px;
    //height: 320px;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
  `,
  EditProfileWrapper = styled.div`
    background-color: #fff;
    overflow-wrap: break-word;
    z-index: 60;
    min-width: 220px;
    max-width: 300px;
    //height: 320px;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
  `,
  ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
  `,
  Button = styled(MuiButton).attrs({
    variant: 'contained',
    color: 'primary',
  })`
    flex: 0 0 auto;
    height: 72px;
    color: #ffffff;
    background-color: #35c8d1;
    margin: 0rem 0rem 0rem 0.5rem !important;
    &:first-child {
      flex: 1 1 auto;
      margin: 0rem 0rem 0rem 0rem !important;
      font-family: 'Poppings', sans-serif;
      font-weight: bold;
      font-size: 24px;
    }
  `,
  InputRow = styled.div`
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    color: white;
    > div:nth-child(2) {
      width: 2rem;
    }
  `,
  TextInput = styled(TextField).attrs({
    color: 'primary',
  })`
    border-radius: 5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: 'Poppins', sans-serif;
    /* background-color: #767a8f; */
    color: white;
  `,
  // TextInfo = styled.div`
  //   color: white;
  //   box-sizing: border-box;
  //   width: calc(100% + 80px);
  //   line-height: 2rem;
  //   margin-bottom: 3rem;
  //   padding: 2rem;
  //   text-align: center;
  //   font-size: 18px;
  //   font-weight: bold;
  //   font-family: 'Poppins', sans-serif;
  // `,
  PopupWrapper = styled.div.attrs(({ x, y }: { x?: number; y?: number }) => ({
    style: { top: y, left: x },
  }))<{ x?: number; y?: number }>`
    position: absolute;
    pointer-events: none;
    min-width: 400px;
    min-height: 290px;
    width: 350px;
    height: 305px;
    overflow: visible;
  `,
  SmallPopupWrapper = styled.div.attrs(
    ({ x, y }: { x?: number; y?: number }) => ({
      style: { top: y, left: x },
    })
  )<{ x?: number; y?: number }>`
    position: absolute;
    pointer-events: none;
    overflow: visible;
    padding: 1rem;
  `,
  PopupAnimated = styled(animated.div)<{ hovered?: { [prop: string]: any } }>`
    position: relative;
    bottom: 0.5rem;
    left: -4.5rem;
    padding: ${(p) => (p.hovered?.id ? '1rem' : 0)};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align: start;
    background-color: ${(p) => (p.hovered ? '#fff' : undefined)};
    border-radius: 25px;
    width: 100%;
    height: 100%;
    padding: 1rem;
    /* border: 1px solid #ccc; */
    > div {
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;
      /* line-height: 0px; */
      > div {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        margin-top: 0;
      }
    }
    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 15px;
      margin: 10px 0 30px 0;
    }
    > div:last-child {
      display: flex;
      justify-content: center;
      align-items: bottom;
      line-height: 0px;
      width: 100%;
      padding: 0;
      margin: 0;
      line-height: 0px;
      > * {
        margin-left: 5px;
        margin-top: 5px;
      }
    }
  `,
  Index = styled.span`
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    color: #35c8d1;
    padding: 0;
    margin: 0;
    line-height: 0px;
  `,
  ReactCountryFlag = styled(ReactCountryFlagUnstyled).attrs({
    svg: true,
    style: {
      width: '2rem',
      height: '2rem',
    },
  })``,
  Flag = styled.span.attrs(({ code }: { code: string }) => ({
    code,
    children: <ReactCountryFlag countryCode={code} />,
  }))`
    margin-right: 2rem;
  `,
  ColorScaleWrapper = styled.div`
    position: absolute;
    left: 20px;
    bottom: 10px;
    z-index: 1;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: end;
  `,
  ColorScaleLabel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    align-items: start;
    > span{
      padding: 2px 6px;
      border-radius: 3px;
      background-color: rgba(.5,.5,.5,.35); 
    }
  `,
  

  ColorScaleGradient = styled.div`
    width: 30px;
    height: 150px;
    background: linear-gradient(#006d75, #ffffff);
  `;

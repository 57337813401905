import { createTheme, ThemeOptions } from '@material-ui/core/styles';

export const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      light: '#ffffff',
      main: '#35C8D1',
      dark: '#35C8D1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px rgba(0,0,0,0) inset',
            WebkitTextFillColor: '#000',
            WebkitHeight: '3rem',
            height: '72px',
            borderRadius: '3px',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 100,
        },
      },
    },
  },
};

export const styledTheme = {
  colors: {
    accent: '#35C8D1',
    mainColor: '#35C8D1',
  },
  fonts: {
    primary: "'Poppins', sans-serif",
  },
};

export const theme = createTheme(themeConfig);

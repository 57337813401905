import { ReactNode, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { atoms } from 'misc';
import ViewOptions from './ViewOptions';
import { Popup } from './Popup';
import { useLocation } from 'react-router-dom';
import Dropdowns from './Dropdowns';
import DrawOverlays from './DrawOverlays';
import Profile from './Profile';
import Measurements from './Measurements';
import { Wrapper } from './styles';
import ColorScale from './ColorScale';
import Settings from './Settings';
import SourceLabel from './SourceLabel';


export default function Map({ children }: { children: ReactNode }) {
  const [hovered] = useRecoilState(atoms.hovered);
  const [userProfile] = useRecoilState(atoms.userProfile);
  const [settings] = useRecoilState(atoms.settings);
  const location = useLocation();
  const mapLayers = useRecoilValue(atoms.mapLayers);
  const user = useRecoilValue(atoms.user);

  return (
    <Wrapper>
      {location.pathname.includes('market') && <ColorScale />}
      
      {/* side options */}
      <div style=
        {{  position: 'absolute',
            top: '7rem', right: '2rem',
            display: 'flex', flexDirection:'column',
            gap: '1rem',
            alignItems: 'flex-end',
          }}>

        {/* Draw Overlays (+/-) */}
        {location.pathname.includes('parcels') && <DrawOverlays />}

        {/* View Options */}
        {!location.pathname.includes('design') &&
          !location.pathname.includes('market') && <ViewOptions />}


        {/* Measurements: ruler and area measurement */}
        {(location.pathname.includes('explore') ||
          location.pathname.includes('parcels') || 
          location.pathname.split('/')[1]===undefined // landing (no path)
          ) && 
          !mapLayers.boundaries && <Measurements />}

      
      </div>
      
      <SourceLabel />
      
      {/* coming soon */}
      {location.pathname.includes('parcels') && <Dropdowns />}
      
      {/* pop ups */}
      {userProfile && <Profile />}
      {settings && <Settings />}
      {children}
      <Popup {...{ hovered }} />
    </Wrapper>
  );
}

import styled, { css } from 'styled-components';

type PaddingProps = {
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  p?: string;
}

type MarginProps = {
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  m?: string;
}

export type ComponentProps = PaddingProps & MarginProps;

export const 
  ComponentParams = css<ComponentProps>`
    ${({ pt }) => pt && `padding-top: ${pt};`}
    ${({ pb }) => pb && `padding-bottom: ${pb};`}
    ${({ pl }) => pl && `padding-left: ${pl};`}
    ${({ pr }) => pr && `padding-right: ${pr};`}
    ${({ mt }) => mt && `margin-top: ${mt};`}
    ${({ mb }) => mb && `margin-bottom: ${mb};`}
    ${({ ml }) => ml && `margin-left: ${ml};`}
    ${({ mr }) => mr && `margin-right: ${mr};`}
    ${({ m }) => m && `margin: ${m};`}
    ${({ p }) => p && `padding: ${p};`}
  `,
  Subheader = styled.span<ComponentProps>`
    font-family: Poppins;
    font-weight: 400;
    font-size: 12px;
    padding: 0;
    margin: 0;
    line-height: 0px;
    white-space: nowrap;
    ${ComponentParams}
  `,

  Header = styled.span`
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    line-height: 0px;
    ${ComponentParams}
  `,

  Numbers = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 18px;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    line-height: 0px;
    ${ComponentParams}
  `,

  Rank = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 10px;
    color: #c4c4c4;
    padding: 0;
    margin: 0;
    line-height: 0px;
    ${ComponentParams}

  `,

  Area = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 11px;
    padding: 0;
    margin: 0;
    line-height: 0px;
    ${ComponentParams}
  `;

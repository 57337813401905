import { PositionsProps } from "@material-ui/system";
import { FlexProps, LayoutProps, SpaceProps } from "styled-system";
import { LegendItem, LegendWrapper, Snippet } from "./styles";
import { Text, TextTypes } from '@ui';
import { ReactNode } from "react";

type LegendProps = {
  color: string;
  label: string|ReactNode;
  stroke?: string;
}

export function CategoryLegend({
  legends,
  customSize='t5',
  ...props
}: {
  legends: LegendProps[],
  customSize?: TextTypes
}&SpaceProps&LayoutProps&PositionsProps&FlexProps ){

  return <LegendWrapper {...props}>{
    legends.map(({color, label, stroke}, index) => {
      return <LegendItem key={index}>
        <Snippet {...{color, stroke}} />
        <Text {...{customSize}} textAlign="start">{label}</Text>
      </LegendItem>
    })
  }</LegendWrapper>
}

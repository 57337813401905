import { useLadSupplyQuery } from 'generated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DwellingsTenure from './DwellingsTenure';
import TotalDwellings from './TotalDwellings';
import NetAdditionalDwellings from './NetAdditionalDwellings';
import HouseholdProjections from './HouseholdProjections';
import VacancyAnalysis from './VacancyAnalysis';
import VacanciesByType from './VacanciesByType';
import MedianHouseholdSize from './MedianHouseholdSize';
import RealEstateCycleAnalysis from './RealEstateCycleAnalysis';

export default function Supply() {
  const { ladCode = '' } = useParams<{ ladCode: string }>();
  const { data:sourceData } = useLadSupplyQuery({ variables: { ladCode } });
  const [data, setData] = useState(sourceData)
  
  useEffect(()=>{
    if(sourceData){
      setData(sourceData)
    }
  },[sourceData])

  return (
    <>
      <TotalDwellings {...{data}}/>
      <DwellingsTenure {...{data}}/>
      <NetAdditionalDwellings {...{data}}/>
      <HouseholdProjections {...{data}}/>
      <VacancyAnalysis {...{data}}/>
      <VacanciesByType {...{data}}/>
      <MedianHouseholdSize {...{data}}/>
      <RealEstateCycleAnalysis {...{data}}/>
    </>
  );
}

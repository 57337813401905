import styled, {css as css_, ThemedCssFunction} from "styled-components";
const css = css_ as ThemedCssFunction<{}>

const titleSizes = (font:any)=>({
  /** font-size: 30 */
  h1: css<{}>`${font}; font-size: 30px;`,
  /** font-size: 24 */
  h2: css<{}>`${font}; font-size: 24px;`,
  /** font-size: 18 */
  h3: css<{}>`${font}; font-size: 18px;`,
  /** font-size: 14 */
  h4: css<{}>`${font}; font-size: 14px;`,
  /** font-size: 11 */
  h5: css<{}>`${font}; font-size: 11px;`,
})

const bodySizes = (font:any)=>({
  /** font-size: 18 */
  t1: css<{}>`${font}; font-size:18px;`,
  /** font-size: 18 */
  t2: css<{}>`${font}; font-size:16px;`,
  /** font-size: 14 */
  t3: css<{}>`${font}; font-size:14px;`,
  /** font-size: 12 */
  t4: css<{}>`${font}; font-size:12px;`,
  /** font-size: 11 */
  t5: css<{}>`${font}; font-size:11px;`,
  /** font-size: 9 */
  t6: css<{}>`${font}; font-size:9px;`,
  /** font-size: 7 */
  t7: css<{}>`${font}; font-size:7px;`,

})

export const font = {
  title:{
    regular: (()=>{
      const font = css<{}>`
        font-family: Poppins;
        font-weight: 400;
        color: rgba(0,0,0,0.87);
      `;
      return titleSizes(font);
    })(),
    bold: (()=>{
      const font = css<{}>`
        font-family: Poppins;
        font-weight: 600;
        color: rgba(0,0,0,0.87);
      `;
      return titleSizes(font);
    })(),
  } ,
  body:{
    regular: (()=>{
      const font = css<{}>`
        font-family: Poppins;
        font-weight: 300;
        color: rgba(0,0,0,0.87);
      `;
      return bodySizes(font);
    })(),

    semibold: (()=>{
      const font = css<{}>`
        font-family: Poppins;
        font-weight: 600;
        color: rgba(0,0,0,0.87);
      `;
      return bodySizes(font);
    })(),

    bold: (()=>{
      const font = css<{}>`
        font-family: Poppins;
        font-weight: 800;
        color: rgba(0,0,0,0.87);
      `;
      return bodySizes(font);
    })()
  } 
} 

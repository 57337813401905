import { useLadPlanningQuery } from 'generated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DistrictPlanningAuthorities from './DistrictPlanningAuthorities';
import Table from './Table';

export default function Panel() {
  const { ladCode = '' } = useParams<{ ladCode: string }>();
  const { data:sourceData } = useLadPlanningQuery({ variables: { ladCode } });
  const [data, setData] = useState(sourceData)
  
  useEffect(()=>{
    if(sourceData){
      setData(sourceData)
    }
  },[sourceData])
  
  return (<>
    <DistrictPlanningAuthorities {...{data}} />
    <Table {...{data}} />
  </>);
}

import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  CategoryLegend,
  Text,
  Paper,
  Title,
  Row
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Bar, BarChart, LinearYAxis } from 'reaviz';
import { format } from 'd3-format'
import { useMemo } from 'react';
import { BarSeries } from 'misc/reaviz/BarSeries';

export default function HouseholdProjections({ data }:{ data?: LadSupplyQuery}) {

  const values = useMemo(()=>
  [
    {
      key: 'Under 25',
      data: data?.lad?.supply?.s4_under25_2014
    },
    {
      key: '25-34',
      data: data?.lad?.supply?.s4_25_34_2014
    },
    {
      key: '35-44',
      data: data?.lad?.supply?.s4_35_44_2014
    },
    {
      key: '45-54',
      data: data?.lad?.supply?.s4_45_54_2014
    },
    {
      key: '55-64',
      data: data?.lad?.supply?.s4_55_64_2014
    },
    {
      key: '65-74',
      data: data?.lad?.supply?.s4_65_74_2014
    },
    {
      key: '75-84',
      data: data?.lad?.supply?.s4_75_84_2014
    },
    {
      key: '85+',
      data: data?.lad?.supply?.s4_over85_2014
    }
  ],
  [data])

  const min = 0;
  const max = Math.max(...(values.map(v=>v.data) as number[])) * 1.6; 


  const valuesNew = useMemo(()=>
  [
    {
      key: 'Under 25',
      data: data?.lad?.supply?.s4_under25_2039
    },
    {
      key: '25-34',
      data: data?.lad?.supply?.s4_25_34_2039
    },
    {
      key: '35-44',
      data: data?.lad?.supply?.s4_35_44_2039
    },
    {
      key: '45-54',
      data: data?.lad?.supply?.s4_45_54_2039
    },
    {
      key: '55-64',
      data: data?.lad?.supply?.s4_55_64_2039
    },
    {
      key: '65-74',
      data: data?.lad?.supply?.s4_65_74_2039
    },
    {
      key: '75-84',
      data: data?.lad?.supply?.s4_75_84_2039
    },
    {
      key: '85+',
      data: data?.lad?.supply?.s4_over85_2039
    }
  ],
  [data])

  const modified = valuesNew.map((v,i)=>({
    ...v,
    data: [values[i].data, v.data]
  }))

  const upper = modified.map(v=>({
    ...v,
    data: v.data[0]>= v.data[1]? [0,0] : [v.data[0], v.data[1]]
  }))

  const lower = modified.map(v=>({
    ...v,
    data: v.data[0]< v.data[1]? [0,0] : [v.data[1], v.data[0]]
  }))

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Household projections by Occupant age in 2014 - 2039 </Fonts.Header>
        <ChartRow>
          <ChartContainer ml="2.5rem">
            <BarChart
              style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
              width={500}
              height={250}
              data={values}
              centerY
              yAxis={<LinearYAxis domain={[min,max]} />}
              series={
                <BarSeries
                  isCategorical
                  type='standard'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      label={null}
                      color="#34c3ca"
                      style={(data:any) => {
                        if (data.key) {
                          return {
                            fill: '#34c3ca'
                          };
                        }
                      }}
                    />
                  }
                />
              }
            />
            <BarChart
              style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
              width={500}
              height={250}
              data={lower}
              centerY
              yAxis={<LinearYAxis domain={[min,max]} />}
              series={
                <BarSeries
                  isCategorical
                  type='standard'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      label={null}
                      
                      style={(data:any) => {
                        if (data.key) {
                          return {
                            fill: '#36c8d1',
                            stroke: '#e141e4',
                            strokeWidth: '2px',
                            strokeLocation: 'inside'
                          };
                        }
                      }}
                    />
                  }
                />
              }
            />
            <BarChart
              style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
              width={500}
              height={250}
              data={upper}
              centerY
              yAxis={<LinearYAxis domain={[min,max]} />}
              series={
                <BarSeries
                  isCategorical
                  type='standard'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      label={null}
                      
                      style={(data:any) => {
                        if (data.key) {
                          return {
                            fill: '#e141e4'
                          };
                        }
                      }}
                    />
                  }
                />
              }
            />
            <CategoryLegend legends={[
              { label:'2014', color: '#36c8d1' },
              { label:'2039 - net increase in category', color: '#e141e4' },
              { label:'2039 - net decline in category', color: 'transparent', stroke: '#e141e4' }
            ]} position='absolute' right='0rem' top='1rem' />
            <Text customColor='grey' position='absolute' left='-4.5rem !important' top='90px !important' transform='rotate(-90deg)'># households</Text>

          </ChartContainer>
        </ChartRow>
        <ChartRow>
            <Paper noStyles >
              <Row>
                <Text>Avg. Dwellings per hectare</Text>
                <Paper noStyles p='2rem'>
                  <Row>
                    <Title customColor='grey' width='60px' bold mr='1rem' h='h2' height='0px'>{data?.max[0].s6_density_2020 * 0.01||0}</Title>
                    <Text>{data?.max[0].geo_name}</Text>
                  </Row>
                  <Row>
                    <Title width='60px' bold mr='1rem' h='h2' height='0px'>{data?.lad?.supply?.s6_density_2020 * 0.01||0}</Title>
                    <Text>{data?.lad?.geo_name}</Text>
                  </Row>
                  <Row>
                    <Title customColor='grey' width='60px' bold mr='1rem' h='h2' height='0px'>{data?.min[0].s6_density_2020 * 0.01||0}</Title>
                    <Text>{data?.min[0].geo_name}</Text>
                  </Row>
                </Paper>
              </Row>
            </Paper>
        </ChartRow>
        <ChartRow>
              <Row display='flex' justifyContent='space-evenly' width='100%'>
                <Paper noStyles p='0px' flex='1 0 auto'>
                  <Text>Total Vacancies:</Text>
                  <Title bold mr='1rem' h='h3' height='0px'>{data?.lad?.vacancy?.s8_s7_vacancy_total_2020.toLocaleString()} units</Title>
                </Paper>
                <Paper noStyles p='0px' flex='1 0 auto'>
                  <Text>Total Vacancy:</Text>
                  <Title bold mr='1rem' h='h3' height='0px'>{data?.lad?.vacancy?.s8_s7_vacancy_percent_2020}%</Title>
                </Paper>
                <Paper noStyles p='0px' flex='1 0 auto'>
                  <Text >Long Term Avg. Vacancy (7Y):</Text>
                  <Title bold mr='1rem' h='h3' height='0px'>{data?.lad?.vacancy?.s9_long_term_vac_2020}%</Title>
                </Paper>
              </Row>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

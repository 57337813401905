import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  CategoryLegend,
  Text,
  LineLegend
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Bar, BarChart, BarLabel, Line, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';
import { BarSeries } from 'misc/reaviz/BarSeries';

export default function NetAdditionalDwellings({ data }:{ data?: LadSupplyQuery}) {
  
  const values = Boolean(data?.lad?.vacancy?.s7_vacancy_percent_2011) && [
    {
      key: '2011',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2011
    },
    {
      key: '2012',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2012
    },
    {
      key: '2013',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2013
    },
    {
      key: '2014',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2014
    },
    {
      key: '2015',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2015
    },
    {
      key: '2016',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2016
    },
    {
      key: '2017',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2017
    },
    {
      key: '2018',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2018
    },
    {
      key: '2019',
      data: data?.lad?.vacancy?.s7_vacancy_percent_2019
    },
    {
      key: '2020',
      data: data?.lad?.vacancy?.s8_s7_vacancy_percent_2020
    }

  ]

  const min = 0;
  const max = values? Math.max(...(values?.map(v=>v.data) as number[])) * 1.6: 1; 

  const modifiedValues = values && values.map(v=>({
    ...v,
    data: [0, v.data]
  }))

  // mean function
  const mean = (values: number[]) => {
    const sum = values.reduce((a, b) => a + b, 0);
    return sum / values.length;
  }

  const modified3y = values && values.map(v=>({
    ...v,
    data: mean([
      data?.lad?.vacancy?.s8_s7_vacancy_percent_2020,
      data?.lad?.vacancy?.s7_vacancy_percent_2019,
      data?.lad?.vacancy?.s7_vacancy_percent_2018
    ])
  }))

  const modified5y = values && values.map(v=>({
    ...v,
    data: mean([
      data?.lad?.vacancy?.s8_s7_vacancy_percent_2020,
      data?.lad?.vacancy?.s7_vacancy_percent_2019,
      data?.lad?.vacancy?.s7_vacancy_percent_2018,
      data?.lad?.vacancy?.s7_vacancy_percent_2017,
      data?.lad?.vacancy?.s7_vacancy_percent_2016
    ])
  }))

  const modified7y = values && values.map(v=>({
    ...v,
    data: mean([
      data?.lad?.vacancy?.s8_s7_vacancy_percent_2020,
      data?.lad?.vacancy?.s7_vacancy_percent_2019,
      data?.lad?.vacancy?.s7_vacancy_percent_2018,
      data?.lad?.vacancy?.s7_vacancy_percent_2017,
      data?.lad?.vacancy?.s7_vacancy_percent_2016,
      data?.lad?.vacancy?.s7_vacancy_percent_2015,
      data?.lad?.vacancy?.s7_vacancy_percent_2014
    ])
  }))

  const modified10y = values && values.map(v=>({
    ...v,
    data: mean([
      data?.lad?.vacancy?.s8_s7_vacancy_percent_2020,
      data?.lad?.vacancy?.s7_vacancy_percent_2019,
      data?.lad?.vacancy?.s7_vacancy_percent_2018,
      data?.lad?.vacancy?.s7_vacancy_percent_2017,
      data?.lad?.vacancy?.s7_vacancy_percent_2016,
      data?.lad?.vacancy?.s7_vacancy_percent_2015,
      data?.lad?.vacancy?.s7_vacancy_percent_2014,
      data?.lad?.vacancy?.s7_vacancy_percent_2013,
      data?.lad?.vacancy?.s7_vacancy_percent_2012,
      data?.lad?.vacancy?.s7_vacancy_percent_2011
    ])
  }))

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Vacancy Analysis </Fonts.Header>
        <ChartRow>
          <ChartContainer ml="2.5rem">
            <BarChart
              style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
              width={500}
              height={250}
              data={modifiedValues||[]}
              centerY
              yAxis={<LinearYAxis domain={[min,max]} />}
              series={
                <BarSeries
                  isCategorical
                  type='standard'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      label={<BarLabel {...{} as any} fill="#666666" position="top" />}
                      color="#666666"
                      style={(data:any) => {
                        if (data.key) {
                          return {
                            fill: '#34c3ca'
                          };
                        }
                      }}
                    />
                  }
                />
              }
            />
            {values && <>
              <LineChart
                width={480}
                height={250}
                xAxis={<LinearXAxis axisLine={null} tickSeries={<LinearAxisTickSeries tickValues={[]}/>}  />}
                yAxis={
                  <LinearYAxis 
                    domain={[min,max]} 
                    axisLine={null} 
                    tickSeries={
                      <LinearAxisTickSeries tickValues={[]}/>} 
                  />}
                data={modified3y||[]}
                gridlines={null}
                series={
                  <LineSeries
                    interpolation="smooth"
                    colorScheme='#ff2727'
                    tooltip={<TooltipArea disabled />}
                    line={<Line strokeWidth={2} style={{transform:'translate(28px,-5px)', strokeDasharray: '4,4'}} />}
                  />
                }
              />
              <LineChart
                width={480}
                height={250}
                xAxis={<LinearXAxis axisLine={null} tickSeries={<LinearAxisTickSeries tickValues={[]}/>}  />}
                yAxis={
                  <LinearYAxis 
                    domain={[min,max]} 
                    axisLine={null} 
                    tickSeries={
                      <LinearAxisTickSeries tickValues={[]}/>} 
                  />}
                data={modified5y||[]}
                gridlines={null}
                series={
                  <LineSeries
                    interpolation="smooth"
                    colorScheme='#e141e4'
                    tooltip={<TooltipArea disabled />}
                    line={<Line strokeWidth={2} style={{transform:'translate(28px,-5px)', strokeDasharray: '4,4'}} />}
                  />
                }
              />
              <LineChart
                width={480}
                height={250}
                xAxis={<LinearXAxis axisLine={null} tickSeries={<LinearAxisTickSeries tickValues={[]}/>}  />}
                yAxis={
                  <LinearYAxis 
                    domain={[min,max]} 
                    axisLine={null} 
                    tickSeries={
                      <LinearAxisTickSeries tickValues={[]}/>} 
                  />}
                data={modified7y||[]}
                gridlines={null}
                series={
                  <LineSeries
                    interpolation="smooth"
                    colorScheme='#d19335'
                    tooltip={<TooltipArea disabled />}
                    line={<Line strokeWidth={2} style={{transform:'translate(28px,-5px)', strokeDasharray: '4,4'}} />}
                  />
                }
              />
              <LineChart
                width={480}
                height={250}
                xAxis={<LinearXAxis axisLine={null} tickSeries={<LinearAxisTickSeries tickValues={[]}/>}  />}
                yAxis={
                  <LinearYAxis 
                    domain={[min,max]} 
                    axisLine={null} 
                    tickSeries={
                      <LinearAxisTickSeries tickValues={[]}/>} 
                  />}
                data={modified10y||[]}
                gridlines={null}
                series={
                  <LineSeries
                    interpolation="smooth"
                    colorScheme='#36d13b'
                    tooltip={<TooltipArea disabled />}
                    line={<Line strokeWidth={2}  style={{transform:'translate(28px,-5px)', strokeDasharray: '4,4'}} />}
                  />
                }
              />
            </>}
            <LineLegend legends={[
              { label:'3Y avg.', line: {stroke: '#ff2727', strokeDasharray: '4,4', strokeDashoffset:'2'} },
              { label:'5Y avg.', line: {stroke: '#e141e4', strokeDasharray: '4,4', strokeDashoffset:'2'} },
              { label:'7Y avg.', line: {stroke: '#d19335', strokeDasharray: '4,4', strokeDashoffset:'2'} },
              { label:'10Y avg.', line: {stroke: '#36d13b', strokeDasharray: '4,4', strokeDashoffset:'2'} }
            ]} position='absolute' right='0rem' top='1rem' />
            <Text customColor='grey' position='absolute' left='-4.5rem !important' top='90px !important' transform='rotate(-90deg)'># households</Text>
          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

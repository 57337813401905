import styled, { css } from "styled-components"

import { Systems, systems, theme } from "@ui/common"
import { space, layout, position, SpaceProps, LayoutProps, PositionProps, flexbox, FlexboxProps } from "styled-system"

type colorTypes = keyof typeof theme.colors
const colors:colorTypes[] = Object.keys(theme.colors) as colorTypes[]

type TitleTypes = 'h1'|'h2'|'h3'|'h4'|'h5'

export const 
Title = styled.span.attrs((p:{h:TitleTypes})=>({
  as: p.h
}))<{bold?:boolean, h?:TitleTypes, customColor?: colorTypes|string } & Systems >`
  ${({ bold, h='h4', ...p })=>bold?
    p.theme.font.title.bold[h]:
    p.theme.font.title.regular[h] }
  text-align: center; 
  ${p=>p.customColor && (
    colors.includes(p.customColor as colorTypes) ?
      css`color: ${p.theme.colors[p.customColor as colorTypes]};`:
      css`color: ${p.customColor};`
  )}
  display: flex;
  align-items: center;
  >svg{
    margin-right: 8px;
  }
  line-height: normal;
  ${systems}
`
import { useCountryStatsQuery } from 'generated';
import { ExternalMapContext, Fonts, useSize } from 'misc';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  Demographics,
  Flag,
  PanelHeader,
  PanelContainer,
  PanelWrapper,
  Sector,
  SectorsRow,
  StatsRow,
  Bar,
  BarRow,
  BarTooltip,
  Pyramid,
  Chart,
  Explication,
  Center,
  Slider,
  SliderContainer,
  ResidentialDemand,
  TopCities,
  Year,
  Divider,
  Attribution
} from '@ui';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {
  ChartTooltip,
  PieArc,
  PieArcSeries,
  PieChart,
  TooltipArea,
  TooltipTemplate,
  TreeMap,
  TreeMapLabel,
  TreeMapRect,
  TreeMapSeries,
  formatValue,
} from 'reaviz';
import { scaleLinear } from 'd3-scale';
import { useContext, useEffect, useState } from 'react';
import { simplifyAmount } from 'containers/Map/Overlays/ColorScale';

export default function Panel() {
  const { countryCode } = useParams<{ countryCode: string }>();
  const { data } = useCountryStatsQuery({
    variables: { countryCode: countryCode || '' },
  });

  const { map } = useContext(ExternalMapContext);

  useEffect(() => {
    const countryExtent = data?.country?.[0]?.geometry?.extent
      .replace('BOX(', '')
      .replace(')', '')
      .split(',')
      .map((t: any) => t.split(' ').map((v: any) => Number(v)));

    if (map && countryCode && countryExtent) {
      const offset = 10;
      map.fitBounds(countryExtent as any, {
        // zoom: 9,
        padding: {
          top: offset,
          bottom: offset,
          left: offset,
          right: 600 + offset,
        },
      });
      //Ebable LAD map
    }
  }, [map, countryCode, data?.country?.[0]?.geometry?.extent]);

  const navigate = useNavigate();
  const { height, ref } = useSize();
  const [popYear, setPopYear] = useState(2020);

  const [residentialSpan, setSpan] = useState(1);

  //highest demographic value in the chart.
  const max_pop = Math.max(
    ...(Object.values(data?.country[0].demographics_2020_male || {}).filter(
      (v) => !isNaN(v)
    ) as number[])
  );
  const colorScale = scaleLinear()
    .domain([0, 1])
    .range(['#83dbdf', '#17686e'] as any) as unknown as (n: number) => string;
  const total_pop =
    (data?.country[0].population?.urban_2018 || 0) +
    (data?.country[0].population?.rural_2018 || 0);
  
const demographic_male = Object.entries(data?.country[0].demographics_2020_male || {})
    .filter(([key, v]) => key !== '__typename' && !isNaN(v))
    .reverse()
    .reduce(
      (agg, [key, v], i) =>
        i < 5
          ? [['80+', (agg[0][1] as any) + v]]
          : [...agg, [key, v]],
      [['80+', 0]]
    ) as [string, number][]; 

// console.log('dem', demographic_male)

const demographic_female = Object.entries(data?.country[0].demographics_2020_female || {})
    .filter(([key, v]) => key !== '__typename' && !isNaN(v))
    .reverse()
    .reduce(
      (agg, [key, v], i) =>
        i < 5
          ? [['80+', (agg[0][1] as any) + v]]
          : [...agg, [key, v]],
      [['80+', 0]]
    ) as [string, number][];

  
  return (
    <PanelWrapper ref={ref}>
      <SimpleBar
        autoHide={false}
        style={{ boxSizing: 'border-box', maxHeight: height }}
      >
        <PanelContainer>
          <PanelHeader>
            <div>
              <h2>{data?.country[0].name}</h2>
              <Flag code={countryCode} />
            </div>
            <CloseButton onClick={() => navigate('/market')} />
          </PanelHeader>
          <h4>Key data</h4>
          <StatsRow>
            <div>
              <Fonts.Subheader>Population:</Fonts.Subheader>
              <p>
                <Fonts.Header>{(total_pop / 1000000).toFixed(1)}</Fonts.Header>{' '}
                <Fonts.Numbers>million </Fonts.Numbers>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>GDP:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  $
                  {Math.round(
                    data?.country[0].gdp?.gdp_real_usd / 1000
                  ).toLocaleString()}
                </Fonts.Header>{' '}
                <Fonts.Numbers>trillion </Fonts.Numbers>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>Unemployment:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {data?.country[0].ma?.unemployment}%
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>CPI:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(data?.country[0].ma?.cpi / 1).toFixed(1)}%
                </Fonts.Header>{' '}
              </p>
            </div>
          </StatsRow>
          <StatsRow>
            <div>
              <Fonts.Subheader>Population growth:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(
                    data?.country[0].population?.pop_growth_cagr_10y / 1
                  ).toFixed(1)}
                  %
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>GDP growth (5Y avg.):</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(data?.country[0].gdp?.gdp_growth_cagr_5y / 1).toFixed(1)}%
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>Avg. Mortgage Rate:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(data?.country[0].ma?.mortgage_rate_avg / 1).toFixed(1)}%
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>Inflation:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(data?.country[0].ma?.inflation / 1).toFixed(1)}%
                </Fonts.Header>{' '}
              </p>
            </div>
          </StatsRow>
          <br />
          {data?.country[0].gdp?.gdp_sector_manufacturing_2019 !== null && (
            <>
              <Fonts.Subheader>GDP by sector:</Fonts.Subheader>
              <SectorsRow>
                <Sector
                  name="Agriculture"
                  value={data?.country[0].gdp?.gdp_sector_agriculture_2019 || 0}
                  color="#35C8D1"
                />
                <Sector
                  name="Industry"
                  value={data?.country[0].gdp?.gdp_sector_industry_2019 || 0}
                  color="#73d5dc"
                />
                <Sector
                  name="Manufacturing"
                  value={
                    data?.country[0].gdp?.gdp_sector_manufacturing_2019 || 0
                  }
                  color="#8f9398"
                />
                <Sector
                  name="Services"
                  value={data?.country[0].gdp?.gdp_sector_services_2019 || 0}
                  color="#19686e"
                />
              </SectorsRow>
            </>
          )}
          <h4>Demographics</h4>
          <Demographics>
            <div>
              <Fonts.Subheader>Generation Split:</Fonts.Subheader>
              <br />
              <Chart>
                <PieChart
                  // height={250}
                  data={[
                    {
                      key: 'Gen Alpha',
                      data: data?.country[0].generation_2022?.alpha,
                    },
                    {
                      key: 'Gen Z',
                      data: data?.country[0].generation_2022?.z,
                    },
                    {
                      key: 'Millenials',
                      data: data?.country[0].generation_2022?.millennials,
                    },
                    {
                      key: 'Gen X',
                      data: data?.country[0].generation_2022?.x,
                    },
                    {
                      key: 'Boomers II',
                      data: data?.country[0].generation_2022?.booomers2,
                    },
                    {
                      key: 'Boomers I',
                      data: data?.country[0].generation_2022?.boomers1,
                    },
                    {
                      key: 'Pre & Post War',
                      data: data?.country[0].generation_2022?.prepostwar,
                    },
                  ]}
                  series={
                    <PieArcSeries
                      // arc={<PieArc tooltip={null} />}

                      padRadius={1}
                      padAngle={1}
                      colorScheme={[
                        '#7ed4d6',
                        '#17686e',
                        '#31bdc7',
                        '#78afb1',
                        '#31bdc7',
                        '#186267',
                        '#7db9cd',
                      ]}
                    />
                  }
                />
              </Chart>
            </div>
            <div>
              <Fonts.Subheader>Population Pyramid:</Fonts.Subheader>
              <br />
              <Pyramid>
                {demographic_male
                  .map(([key, v], idx) => (
                    <BarRow {...{ key }}>
                      <div>
                        {/* female */}
                        <Bar
                          value={
                            (Number(demographic_female[idx][1])/ max_pop) * 100 
                              
                          }
                          color="#31bdc7"
                        />
                        <BarTooltip className="bartooltip">
                        {
                          '['+(key+'').replace('age_', '').replace('_', '-')+']: '+
                          simplifyAmount(Number(v)+Number(demographic_female[idx][1])) + ' \n' +
                          '(F: '+Math.round(Number(demographic_female[idx][1])/(Number(v)+Number(demographic_female[idx][1]))*100)+'% '+
                          'M: '+ Math.round(Number(v)/(Number(v)+Number(demographic_female[idx][1]))*100)  
                          +'%)'
                          }
                          </BarTooltip>
                      </div>
                      <div>
                        {/* male */}
                        <Bar
                          value={(Number(v) / max_pop) * 100}
                          name={key + ''}
                          color="#19686e"
                        />
                      </div>
                    </BarRow>
                  ))}
                <Explication />
              </Pyramid>
            </div>
          </Demographics>

          <br />
          <Demographics>
            <div>
              <Fonts.Subheader>Avg. Annual Urban Growth Rate:</Fonts.Subheader>
              <br />
              <Center>
                <Fonts.Header>
                  {(
                    (data?.country[0].population?.urban_growth_rate_2020_2025 *
                      100) /
                    1
                  ).toFixed(1)}
                  %
                </Fonts.Header>
                <Fonts.Subheader>{` `}(2020-2025)</Fonts.Subheader>
              </Center>
            </div>
            <div>
              <Fonts.Subheader>Urban-Rural Population Ratio:</Fonts.Subheader>
              <br />
              <SectorsRow>
                <Sector
                  name="Urban Pop."
                  value={
                    ((data?.country[0].population?.urban_2018 || 0) /
                      total_pop) *
                    100
                  }
                  color="#19686e"
                />
                <Sector
                  name="Rural Pop."
                  value={
                    ((data?.country[0].population?.rural_2018 || 0) /
                      total_pop) *
                    100
                  }
                  color="#73d5dc"
                />
              </SectorsRow>
            </div>
          </Demographics>
          <br />
          <Fonts.Subheader>
            Top Cities / Urban Areas by Population ({'>'}300k):
          </Fonts.Subheader>
          <br />
          <br />
          <TopCities>
            <div>
              <Year active={popYear === 2020} onClick={() => setPopYear(2020)}>
                2020
              </Year>
              <Divider />
              <Year active={popYear === 2025} onClick={() => setPopYear(2025)}>
                2025
              </Year>
              <Divider />
              <Year active={popYear === 2030} onClick={() => setPopYear(2030)}>
                2030
              </Year>
              <Divider />
              <Year active={popYear === 2035} onClick={() => setPopYear(2035)}>
                2035
              </Year>
            </div>
            <TreeMap
              height={400}
              width={520}
              series={
                <TreeMapSeries
                  rect={<TreeMapRect 
                    tooltip={<ChartTooltip
                      // followCursor={true}
                      placement="top"
                      className='tooltip'
                      />}
                  />}
                  label={<TreeMapLabel fill="#fff" wrap fontSize={11} />}
                  colorScheme={Array(30)
                    .fill('')
                    .map((v, i) => colorScale(i / 29))}
                />
              }
              data={
                data?.country?.[0]?.city?.map((city) => ({
                  key: city.city || 'City',
                  data: (city as any)?.['pop_' + popYear] || 1,
                })) || [{ key: 'Loading', data: 1 }]
              }
            />
          </TopCities>

          <h4>Income</h4>
          <StatsRow narrow={false}>
            {data?.country[0].income?.per_capita_usd_2019 && (
              <div>
                <Fonts.Subheader>
                  Net national income per capita (US$)
                </Fonts.Subheader>
                <p>
                  <Fonts.Header>
                    $
                    {Math.round(
                      data?.country?.[0]?.income?.per_capita_usd_2019 || 0
                    ).toLocaleString()}
                  </Fonts.Header>
                </p>
              </div>
            )}
            {data?.country[0].income?.per_capita_growth_2019 && (
              <div>
                <Fonts.Subheader>
                  Net national Income per capita (annual % growth):
                </Fonts.Subheader>
                <p>
                  <Fonts.Header>
                    {data?.country?.[0]?.income?.per_capita_growth_2019?.toFixed?.(
                      1
                    )}
                    %
                  </Fonts.Header>{' '}
                </p>
              </div>
            )}
            {data?.country[0].gdp?.gdp_percapita_current_usd_2020 && (
              <div>
                <Fonts.Subheader>GDP per capita (USD):</Fonts.Subheader>
                <p>
                  <Fonts.Header>
                    $
                    {Math.round(
                      data?.country[0].gdp?.gdp_percapita_current_usd_2020||0
                    ).toLocaleString()}
                  </Fonts.Header>{' '}
                </p>
              </div>
            )}
            {data?.country[0].income?.disposable_per_capita_oecd_lcu && (
              <div>
                <Fonts.Subheader>
                  Mean Disposable Income, per capita (OECD):
                </Fonts.Subheader>
                <p>
                  <Fonts.Header>
                    $
                    {Math.round(
                      data?.country?.[0]?.income
                        ?.disposable_per_capita_oecd_lcu || 0
                    ).toLocaleString()}
                  </Fonts.Header>{' '}
                </p>
              </div>
            )}
          </StatsRow>
          {data?.country[0].gdp?.gdp_sector_manufacturing_2019 !== null && (
            <>
              <Fonts.Subheader>Income share held by:</Fonts.Subheader>
              <SectorsRow>
                <Sector
                  name="Highest 20%"
                  value={data?.country[0].income?.distribution_top_20 || 0}
                  color="#35C8D1"
                />
                <Sector
                  name="Second 20%"
                  value={data?.country[0].income?.distribution_second_20 || 0}
                  color="#73d5dc"
                />
                <Sector
                  name="Third 20$"
                  value={data?.country[0].income?.distribution_third_20 || 0}
                  color="#8f9398"
                />
                <Sector
                  name="Fourth 20%"
                  value={data?.country[0].income?.distribution_fourth_20 || 0}
                  color="#19686e"
                />
                <Sector
                  name="Bottom 20%"
                  value={data?.country[0].income?.distribution_bottom_20 || 0}
                  color="#619da1"
                />
              </SectorsRow>
            </>
          )}
          <h4>Residential Housing Demand</h4>
          <ResidentialDemand>
            <SliderContainer>
              <Slider
                value={
                  residentialSpan === 1
                    ? 0
                    : residentialSpan === 3
                    ? 1
                    : residentialSpan === 5
                    ? 2
                    : 3
                }
                onChange={(e: any) =>
                  setSpan(
                    e.target.value === '0'
                      ? 1
                      : e.target.value === '1'
                      ? 3
                      : e.target.value === '2'
                      ? 5
                      : 10
                  )
                }
              />
            </SliderContainer>
            <span>Years</span>
          </ResidentialDemand>
          <StatsRow narrow={false}>
            <div>
              <Fonts.Subheader>Increase in Population:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(
                    (data?.country[0].population as any)?.[
                      'delta_' + residentialSpan + 'y'
                    ] || 0
                  ).toLocaleString()}
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>New Household Demand (#units):</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(
                    (data?.country[0].household as any)?.[
                      `demand_${residentialSpan}y`
                    ] || 0
                  ).toLocaleString()}
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>
                Total Household Demand (#units):
              </Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {(
                    (data?.country[0].household as any)?.[
                      `total_${residentialSpan}y`
                    ] || 0
                  ).toLocaleString()}
                </Fonts.Header>{' '}
              </p>
            </div>
            <div>
              <Fonts.Subheader>Average Household Size:</Fonts.Subheader>
              <p>
                <Fonts.Header>
                  {data?.country[0].household?.size_avg / 1}
                </Fonts.Header>{' '}
              </p>
            </div>
          </StatsRow>
          <Attribution >
            Sources: <br/>
            United Nations ©, CC license 3.0 IGO <br/>
            World Bank Data, CC by 4.0 <br/>
            OECD (2021) <br/>
            © Crown copyright, licensed under the Open Government Licence v.3.0
          </Attribution>
        </PanelContainer>
      </SimpleBar>
    </PanelWrapper>
  );
}

import { Map } from 'mapbox-gl';
import { createContext, useRef } from 'react';

export const ExternalMapContext = createContext<{ map?: Map }>({
  map: undefined,
});

export const MapProvider = ({ children }: any) => {
  const map = useRef<{ map?: Map }>({ map: undefined });

  return (
    <ExternalMapContext.Provider value={map.current}>
      {children}
    </ExternalMapContext.Provider>
  );
};

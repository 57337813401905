import styled, { css } from 'styled-components';

type GridProps = {
  cols?: number;
  rows?: number;
}

export const 
GridArgs = css<GridProps>`
  ${({ cols }) => cols && css`grid-template-columns: repeat(${cols}, 1fr);`}
  ${({ rows }) => rows && css`grid-template-columns: repeat(${rows}, 1fr);`}
`,

Grid = styled.div`
  display: grid;
  grid-template-columns: 300px auto 80px;
  grid-template-rows: auto auto auto;
  border-bottom: 1px dotted rgba(0,0,0,0.2);
  border-right: 1px dotted rgba(0,0,0,0.2);

`,

GridCell = styled.div`
  border-top: 1px dotted rgba(0,0,0,0.2);
  border-left: 1px dotted rgba(0,0,0,0.2);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
// import * as d3 from 'd3-format';
import { atoms } from 'misc';
import { useRecoilState } from 'recoil';
// import { GeocodeControl } from 'containers/Map/GeocodeControl';

// const format: any = d3.format('~s');

export default function Explore() {
  const [tokens] = useRecoilState(atoms.tokens);
  return !tokens.mapbox ? null : (
    <>{/* <GeocodeControl accessToken={tokens.mapbox} /> */}</>
  );
}

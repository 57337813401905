import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import {
  NhostApolloProvider,
  cacheConfig,
  GlobalStyle,
  useLayout,
} from 'misc';
import { ThemeProvider } from '@material-ui/core/styles';
import { MapProvider } from 'misc';
import { BrowserRouter as Router } from 'react-router-dom';
import { InMemoryCache } from '@apollo/client';
import { RecoilRoot } from 'recoil';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as StyledTheme } from 'styled-components';
import { NhostAuthProvider } from '@nhost/react-auth';
import * as nhost from 'misc/nhost';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import { theme } from '@ui/common';

const Root = () => {
  const layout = useLayout();
  
  return (
    <React.StrictMode>
      <MapProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <StyledTheme {...{ theme: { ...theme, layout } }}>
              <RecoilRoot>
                <NhostAuthProvider {...{ nhost }}>
                  <NhostApolloProvider
                    {...{ nhost }}
                    cache={new InMemoryCache(cacheConfig)}
                    publicRole="anonymous"
                    graphqlUrl={nhost.GQL_ENDPOINT}
                  >
                    <GlobalStyle />
                    <CssBaseline>
                      <App />
                    </CssBaseline>
                  </NhostApolloProvider>
                </NhostAuthProvider>
              </RecoilRoot>
            </StyledTheme>
          </ThemeProvider>
        </Router>
      </MapProvider>
    </React.StrictMode>
  );
};



ReactDOM.render(<Root />, document.getElementById('root'));



import Overlays from './Overlays';
import Map from './Map';

export default function MapComponent() {
  return (
    <Overlays>
      <Map />
    </Overlays>
  );
}

import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  Text,
  Row,
  Paper,
  Title,
  CategoryLegend
} from '@ui';
import { LadTransactionsQuery } from 'generated';
import { useMemo } from 'react';
import { ChartTooltip, Line, LinearAxisTickLabel, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';
import { TextData } from './styled';

export default function AverageRentalRates({ data }:{ data?: LadTransactionsQuery}) {
  
  const categoryData = [
    {
      key: 'Private sector',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t5_rental_private_avg__monthly_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t5_rental_private_avg__monthly_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t5_rental_private_avg__monthly_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t5_rental_private_avg__monthly_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t5_rental_private_avg__monthly_2021 || 0
        }
      ]
    },
    {
      key: 'PRP / Housing association',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t5_rental_prp_monthly_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t5_rental_prp_monthly_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t5_rental_prp_monthly_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t5_rental_prp_monthly_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t5_rental_prp_monthly_2021 || 0
        }
      ]
    },
    {
      key: 'Social / Affordable',
      data: [
        {
          key: 2017,
          id: '0',
          data: data?.lad?.rentals?.t5_rental_social_monthly_2017 || 0
        },
        {
          key: 2018,
          id: '1',
          data: data?.lad?.rentals?.t5_rental_social_monthly_2018 || 0
        },
        {
          key: 2019,
          id: '2',
          data: data?.lad?.rentals?.t5_rental_social_monthly_2019 || 0
        },
        {
          key: 2020,
          id: '3',
          data: data?.lad?.rentals?.t5_rental_social_monthly_2020 || 0
        },
        {
          key: 2021,
          id: '4',
          data: data?.lad?.rentals?.t5_rental_social_monthly_2021 || 0
        },
      ]
    }
  ]
  
  const min = 0
  const max = useMemo(()=>
    Math.max(
    ...(categoryData
        .map(v=>Math.max(...v.data.map(d=>d.data))
        ) as number[])) * 1.6,
    [categoryData])

  return (
    <StatsRow>
      <div>
        <Fonts.Header>2021 Average Rental Rates:</Fonts.Header>
        <ChartRow>
          <ChartContainer ml="2.5rem" p='0px !important' m='0px'>
            <Paper noStyles p='3rem 0px' width='100%' height='100%' justifyContent='space-between'>
                <Row width='100%' justifyContent='space-between'>
                  <Text width='160px' mr='1rem' textAlign='start'>Avg. Private Sector Rent:</Text>
                  <Paper noStyles p='0px' width='70px'>
                    <Title bold h='h3' m='0'>{data?.lad?.rentals?.t5_rental_private_avg__monthly_2021||'...'}</Title>
                    <Text>per month</Text>
                  </Paper>
                </Row>
                <Row width='100%' justifyContent='space-between'>
                  <Text width='160px' mr='1rem' textAlign='start'>Avg. Private Registered Provider (PRP) Rent:</Text>
                  <Paper noStyles p='0px' width='70px'>
                    <Title bold h='h3' m='0'>{data?.lad?.rentals?.t5_rental_prp_monthly_2021||'...'}</Title>
                    <Text>per month</Text>
                  </Paper>
                </Row>
                <Row width='100%' justifyContent='space-between'>
                  <Text width='160px' mr='1rem' textAlign='start'>Avg. Local District Social & Affordable Rent:</Text>
                  <Paper noStyles p='0px' width='70px'>
                    <Title bold h='h3' m='0'>{data?.lad?.rentals?.t5_rental_social_monthly_2021||'...'}</Title>
                    <Text>per month</Text>
                  </Paper>
                </Row>
              </Paper>
          </ChartContainer>
          <ChartContainer ml="3.5rem">
            <LineChart
              width={220}
              height={200}
              yAxis={<LinearYAxis
                domain={[min,max]}
                />}
              xAxis={<LinearXAxis
                domain={[2016,2022]}
                 type="value" tickSeries={
                <LinearAxisTickSeries label={
                  <LinearAxisTickLabel 
                    padding={{
                      fromAxis: 1,
                      alongAxis: 0
                    }}
                    position='end'
                    format={(v)=>`${v}`}/>} 
                    interval={5}/>} />} 
              data={categoryData}
              series={
                <LineSeries
                  tooltip={<TooltipArea disabled={true} tooltip={<ChartTooltip visible={false}/>}/>}
                  interpolation="linear"
                  type="grouped"
                  colorScheme="#34c3ca"
                  line={
                    <Line  
                      strokeWidth={2} 
                      style={(data:any) => {
                        if(data?.[0]?.key?.includes?.('Social')){
                          return {
                            stroke: '#e141e4',
                          };
                        }else if(data?.[0]?.key?.includes?.('Private')){
                          return {
                            stroke: '#34c3ca',
                          };
                        }else{
                          return {
                            stroke: '#494e67',
                          };
                        }
                      }}
                    />}
                />
              }
            />
            <Fonts.Subheader mt="-2.8rem" ml="1rem">Avg. Monthly Rent per Provider type</Fonts.Subheader>
            <CategoryLegend customSize='t6' legends={[
              { label:'Private sector', color: '#34c3ca' },
              { label:'PRP / Housing association', color: '#494e67' },
              { label:'Social / Affordable', color: '#e141e4' }
            ]} position='absolute' right='0rem' top='1rem' />

            <Text customColor='grey' position='absolute' left='-6.5rem !important' top='90px !important' transform='rotate(-90deg)'>Average Monthly Rate, £</Text>

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

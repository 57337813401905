import {
  StatsRow,
  Fonts,
  ChartContainer,
  Donut,
  ChartRow,
  CategoryLegend,
  Text,
  Title,
  Row,
  Paper
} from '@ui';
import { LadPlanningQuery } from 'generated';

import { useMemo } from 'react';

export default function DistrictPlanningAuthorities({ data }:{ data?: LadPlanningQuery}) {

  const primaryData = useMemo(()=>
  [
    {
      key: 'Dwellings',
      data: data?.lad?.planning?.major_minor_dwellings
    },
    {
      key: 'Offices, research and development, light industry',
      data: data?.lad?.planning?.major_minor_light_industry
    },
    {
      key: 'General industry, storage, warehousing',
      data: data?.lad?.planning?.major_minor_general_industry
    },
    {
      key: 'Retail and service',
      data: data?.lad?.planning?.major_minor_retail
    },
    {
      key: 'Traveller pitches',
      data: data?.lad?.planning?.major_minor_traveller
    },
    {
      key: 'All other major and minor developments',
      data: data?.lad?.planning?.major_minor_other
    },
  ],
  [data])

  
  const additionalData = useMemo(()=>
  [
    {
      key: 'Change of use',
      data: data?.lad?.planning?.other_dev_change_of_use
    },
    {
      key: 'Householder developments',
      data: data?.lad?.planning?.other_dev_householder_dev
    },
    {
      key: 'Advertisements',
      data: data?.lad?.planning?.other_dev_advertisements
    },
    {
      key: 'Listed building censents (to alter/ extend)',
      data: data?.lad?.planning?.other_dev_listed_alterextend
    },
    {
      key: 'Listed building consents (to demolish)',
      data: data?.lad?.planning?.other_dev_listed_demolish
    },
    {
      key: 'Relevant demolition in a conservation area',
      data: data?.lad?.planning?.other_dev_demol_conservation_area
    },
  ],
  [data])


  return (
    <StatsRow>
      <div>
        
        <Title h='h4' m='0' mb='2rem !important' bold>District planning authorities: planning decisions, by development type</Title>
        <ChartRow>
          <ChartContainer p='0' m='0'>
            <Donut 
                data={primaryData} 
                width={160} 
                height={200} 
                thikness={40}/>
            <CategoryLegend customSize='t6' width="120px" legends={[
              { label:'Dwellings', color: '#36c8d1' },
              { label:'Offices, research and development, light industry', color: '#e141e4' },
              { label:'General industry, storage, warehousing', color: '#8ad0d5' },
              { label:'Retail and service', color: '#e68de7' },
              { label:'Traveller pitches', color: '#6a3d9a' },
              { label:'All other major and minor developments', color: '#cab2d6' }
            ]} position='absolute' left='10.5rem' top='1.5rem !important' />
            <Text width='150px' textAlign='start' position='absolute' bold customSize='t6' left='10.5rem' top='1rem' style={{textDecoration:'underline'}}>Major & Minor Developments:</Text>

          </ChartContainer>
          <ChartContainer ml="7.5rem" pt='1rem !important'>
            <Donut 
              data={additionalData} 
              width={110} 
              height={150} 
              threshold={5}
              thikness={35}/>

            <CategoryLegend customSize='t6' width="120px" legends={[
              { label:'Change of use', color: '#36c8d1' },
              { label:'Householder developments', color: '#e141e4' },
              { label:'Advertisements', color: '#8ad0d5' },
              { label:'Listed building consents (to alter/ extend)', color: '#e68de7' },
              { label:'Listed building consents (to demolish)', color: '#6a3d9a' },
              { label:'Relevant demolition in a conservation area', color: '#cab2d6' }
            ]} position='absolute' left='7.5rem' top='-1rem !important' />
            <Text width='150px' textAlign='start' position='absolute' bold customSize='t6' left='7.5rem' top='-2rem !important' style={{textDecoration:'underline'}}>Other developments:</Text>
          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

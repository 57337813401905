import { useRecoilState } from 'recoil';
import { atoms } from 'misc';
import { Fab } from '@material-ui/core';
import { AspectRatio } from '@material-ui/icons';
import { ReactComponent as Ruler } from '@assets/icons/ruler.svg';

export default function Measurements() {
  const [mode, setMode] = useRecoilState(atoms.measurementMode);
  const [cursor, setCursor] = useRecoilState(atoms.cursor);

  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '1em', alignItems: 'flex-end'}}>
      <Fab
        size="small"
        onClick={() => {
          setMode(mode === 'length' ? null : 'length');
          setCursor(mode === 'length' ? '' : 'crosshair');
        }}
        style={{
          marginRight: '.5rem',
          zIndex: 50,
          boxShadow: 'none',
          border: 'solid 1px rgba(0,0,0,0.3)',
          backgroundColor: mode === 'length' ? '#ddd' : 'white',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Ruler />
      </Fab>
      <Fab
        size="small"
        onClick={() => {
          setMode(mode === 'area' ? null : 'area');
          setCursor(mode === 'area' ? '' : 'crosshair');
        }}
        style={{
          marginRight: '.5rem',

          zIndex: 50,
          boxShadow: 'none',
          border: 'solid 1px rgba(0,0,0,0.3)',
          backgroundColor: mode === 'area' ? '#ddd' : 'white',
        }}
      >
        <AspectRatio fontSize="small" />
      </Fab>
    </div>
  );
}

import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabsContainer } from './styles';
import { ReactComponent as DemandIcon } from '@assets/icons/demand.svg';
import { ReactComponent as SupplyIcon } from '@assets/icons/supply.svg';
import { ReactComponent as TransactionsIcon } from '@assets/icons/transactions.svg';
import { ReactComponent as PlanningIcon } from '@assets/icons/planning.svg';
import { ReactComponent as IncomeIcon } from '@assets/icons/income&affordability.svg';

export default function NavPanel() {
  const { countryCode, ladCode, tab = 'demand' } = useParams<{ countryCode: string, ladCode: string, tab: string }>();
  const navigate = useNavigate();

  return (
      <TabsContainer>
        <Tab 
          selected={tab==='demand'} 
          onClick={()=>navigate(`/market/${countryCode}/${ladCode}/demand`)}>
            <DemandIcon/>
            Demand
        </Tab>
        <Tab 
          selected={tab==='supply'} 
          onClick={()=>navigate(`/market/${countryCode}/${ladCode}/supply`)}>
            <SupplyIcon/>
            Supply
        </Tab>
        <Tab 
          selected={tab==='transactions'} 
          onClick={()=>navigate(`/market/${countryCode}/${ladCode}/transactions`)}>
            <TransactionsIcon/>
            Transactions
        </Tab>
        <Tab 
          selected={tab==='planning'} 
          onClick={()=>navigate(`/market/${countryCode}/${ladCode}/planning`)}>
            <PlanningIcon/>
            Planning
        </Tab>
        <Tab 
          selected={tab==='income-and-affordability'} 
          onClick={()=>navigate(`/market/${countryCode}/${ladCode}/income-and-affordability`)}>
            <IncomeIcon/>
            Income {'&'} Affordability
        </Tab>
      </TabsContainer>

  );
}

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLadTransactionsQuery } from 'generated';
import TotalResidentialSales from './TotalResidentialSales';
import NumberResidentialSales from './NumberResidentialSales';
import MeanPricePaid from './MeanPricePaid';
import MedianPricePaid from './MedianPricePaid';
import AverageRentalRates from './AverageRentalRates';
import PrivateSectorRents from './PrivateSectorRents';

export default function Transactions() {
  const { ladCode = '' } = useParams<{ ladCode: string }>();
  const { data:sourceData } = useLadTransactionsQuery({ variables: { ladCode } });

  const [data, setData] = useState(sourceData)
  
  useEffect(()=>{
    if(sourceData){
      setData(sourceData)
    }
  },[sourceData])

  return (
    <>
      <TotalResidentialSales {...{data}}/>
      <NumberResidentialSales {...{data}}/>
      <MeanPricePaid {...{data}}/>
      <MedianPricePaid {...{data}}/>
      <AverageRentalRates {...{data}}/>
      <PrivateSectorRents {...{data}}/>
    </>
  );
}

import { useRecoilState, useRecoilValue } from 'recoil';
import { atoms} from 'misc';
import {Typography, IconButton} from '@material-ui/core';

import {} from 'containers/Landing/Forms/styles';
import { ProfileWrapper } from './styles';
import { Close } from '@material-ui/icons';


export default function Settings() {
  const [, setSettings] = useRecoilState(atoms.settings);
  return (
    <ProfileWrapper>
      <IconButton
        onClick={() => setSettings(false)}
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          color: 'white',
        }}
      >
        <Close />
      </IconButton>
      <Typography variant="subtitle1" style={{marginTop:'1rem'}}>Coming soon...</Typography>
    </ProfileWrapper>);
 
}

import { PositionsProps } from "@material-ui/system";
import { FlexProps, LayoutProps, SpaceProps } from "styled-system";
import { LegendItem, LegendWrapper, LineSnippet, Snippet } from "./styles";
import { Text } from '@ui';

type LegendProps = {
  label: string;
  line?: Partial<SVGLineElement['style']>,
}

export function LineLegend({
  legends,
  ...props
}: {
  legends: LegendProps[]
}&SpaceProps&LayoutProps&PositionsProps&FlexProps ){

  return <LegendWrapper {...props}>{
    legends.map(({label, line}, index) => {
      return <LegendItem key={index}>
        <LineSnippet {...(line as any)} />
        <Text customSize='t5'>{label}</Text>
      </LegendItem>
    })
  }</LegendWrapper>
}

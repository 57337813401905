import {
  StatsRow,
  Fonts,
  ChartContainer,
  Text
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Fragment, useEffect,  useState } from 'react';
import { useSpring, animated } from 'react-spring';

export default function RealEstateCycleAnalysis({ data }:{ data?: LadSupplyQuery}) {

  const [position, setPosition] = useState(0);

  useEffect(()=>{
    const values = [
      data?.lad?.vacancy?.s9_long_term_vac_2016,
      data?.lad?.vacancy?.s9_long_term_vac_2017,
      data?.lad?.vacancy?.s9_long_term_vac_2018,
      data?.lad?.vacancy?.s9_long_term_vac_2019,
      data?.lad?.vacancy?.s9_long_term_vac_2020]
  
    const max = Math.max(...values)
    const min = Math.min(...values)
    const average = values.reduce((a, b) => a + b, 0) / values.length
  
    const previous = data?.lad?.vacancy?.s9_long_term_vac_2019
    const current = data?.lad?.vacancy?.s9_long_term_vac_2020
    
    const delta = (current - average);
    if(delta>0){
      if(previous<=current){
        // Should be in range [0, 0.25]
        const position = ((delta/(max-average)) * 0.25)
        setPosition(position)
      }else{
        // Should be in range [0.25, 0.5]
        const position = ((delta/(max-average)) * 0.25) + 0.25
        setPosition(position)
      }
    }else{
      if(previous>=current){
        // Should be in range [0.5, 0.75]
        const position = ((delta/(max-average)) * 0.25) + 0.5
        setPosition(position)
      }else{
        // Should be in range [0.75, 1]
        const position = ((delta/(max-average)) * 0.25) + 0.75
        setPosition(position)
      }
    }
  
  },[data])

  const spring = useSpring({position: position - 0.1})

  const width = 440
  const height = 200
  const verticalPadding = 10
  const tick_number = 5
  const rightPadding = 30
  const cycles_number = 5
  const sine_subdiv = 50
  const graph_offset = 22

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Real Estate Cycle Analysis - Q2, 2020</Fonts.Header>
        <ChartContainer style={{paddingLeft: '2rem'}}>
          <animated.svg
            width={width}
            height={height}>
              <line x1="15" y1="0" x2="15" y2={height} stroke="black" strokeWidth={2} />
              <line x1="12" y1={height/2} x2={width} y2={height/2} stroke="black" strokeWidth={2} />
              <g>{[2,3,4,5,6].map((v,i)=>{
                const h = (height - (2*verticalPadding))/(tick_number-1) * i + verticalPadding
                return <Fragment key={i}>
                  <text         
                    x="0" 
                    y={h+3} 
                    fill="black">{v}</text>
                  <line 
                    x1="12" 
                    y1={h} 
                    x2='18' 
                    y2={h} 
                    stroke="black" 
                    strokeWidth={2} /> 
                  </Fragment>
              })}</g>
              <g>{['Expansion', 'Hypersupply', 'Recession', 'Recovery', ''].map((v,i)=>{
                const ratio_inner_padding = 0.75
                const cycles_total_number = cycles_number + (ratio_inner_padding * 2)
                const cycle_width = (width - rightPadding) / cycles_total_number
                const x = graph_offset + (cycle_width * i) + (ratio_inner_padding * cycle_width)
                return <Fragment key={i}>
                  <text x={x + (cycle_width/2)} textAnchor='middle' fill='grey' fontSize={8} y={6}>{v}</text>
                  <line 
                    x1={x} 
                    y1="0"
                    x2={x}
                    y2={height}
                    stroke="grey" 
                    strokeDasharray="2,2"
                    strokeWidth={1} /> 
                  </Fragment>
              })}</g>
              <path fill='none' strokeWidth={2} stroke='#36c8d1' d={Array(sine_subdiv).fill(0).map((_,i)=>{
                const x = graph_offset + ((width - (rightPadding*3)) / sine_subdiv) * i 
                const sine_height = height - (2*verticalPadding)
                const y = verticalPadding + sine_height/2 + (sine_height/2 * Math.sin((2.75 * Math.PI * i / sine_subdiv) + 2))
                if(i===0){
                  return `M${x},${y}`
                }else{
                  return `L${x},${y}`
                }
              }).join(' ')}/>
              <animated.circle 
                cx={
                  spring.position.to(v=>{
                    const offset = 0.132 + (v * 0.727);
                    const cx = graph_offset + (width - (rightPadding*3)) * offset  
                    if(isNaN(cx)){
                      return 0
                    }else{
                      return cx
                    }
                  })} 
                cy={
                  spring.position.to(v=>{
                    const offset = 0.132 + (v * 0.727);
                    const sine_height = height - (2*verticalPadding)
                    const cy = verticalPadding + sine_height/2 + (sine_height/2 * Math.sin((2.75 * Math.PI * offset) + 2))
                    if(isNaN(cy)){
                      return 0
                    }else{
                      return cy
                    }
                })} 
                r={5} 
                fill='#e141e4'
              />
              <text                     
                x={width}
                y={height/2 - 6} 
                textAnchor='end'
                fill="black">Long-Term</text>
              <text                     
                x={width}
                y={height/2 + 14} 
                textAnchor='end'
                fill="black">Average Vacancy</text>
          </animated.svg>
          <Text customColor='grey' position='absolute' left='-3.5rem !important' top='90px !important' transform='rotate(-90deg)'>Vacancy (%)</Text>

        </ChartContainer>
      </div>
    </StatsRow>
  );
}

import { useState } from 'react';
import {
  ButtonRow,
  Header,
  LockIcon,
  Wrapper,
  Button,
  Snackbar,
  Alert,
  TextInput,
  InputRow,
} from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { auth, useQuery } from 'misc';

export default function Signin() {
  const [credentials, setCredentials] = useState({
    comfirmation: '',
    password: '',
  });
  const [error, setError] = useState<any>();
  const [notification, setNotification] = useState<any>();
  const navigate = useNavigate();
  const ticket = useQuery().get('ticket');

  return (
    <>
      <div>
        <Wrapper>
          <LockIcon />
          <Header>Create New Password</Header>
          <InputRow>
            <TextInput
              required
              type="password"
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              id="password"
              placeholder="Enter new password *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              type="password"
              value={credentials.comfirmation}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  comfirmation: e.target.value,
                })
              }
              id="comfirmation"
              placeholder="Confirm new password *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              disabled={
                credentials.password.length < 6 ||
                credentials.password !== credentials.comfirmation
              }
              onClick={() => {
                if (ticket) {
                  auth
                    .confirmPasswordChange(credentials.password, ticket)
                    .then((res) => {
                      console.log(res, 'success');
                      navigate('/auth/signin');
                    })
                    .catch((err) => {
                      console.log(err);
                      setError(err.message);
                    });
                }
              }}
            >
              SAVE AND GO TO LOGIN
            </Button>
          </ButtonRow>
          <span>
            <div />
            <Link to="/auth/signin">Back to Sign in</Link>
          </span>

          <span>Copyright &copy; InsightsNow, 2023</span>
        </Wrapper>
      </div>

      <Snackbar open={error} onClose={() => setError(undefined)}>
        <Alert onClose={() => setError(undefined)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={notification} onClose={() => setNotification(undefined)}>
        <Alert onClose={() => setNotification(undefined)} severity="success">
          {notification}
        </Alert>
      </Snackbar>
    </>
  );
}

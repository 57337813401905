import {
  StatsRow,
  Fonts,
  ChartContainer
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Bar, BarChart, LinearAxisTickLabel, LinearXAxis, LinearYAxis, LinearYAxisTickSeries } from 'reaviz';
import { format } from 'd3-format'
import { useMemo } from 'react';
import { BarSeries } from 'misc/reaviz/BarSeries';

const formatter = format('.2~s')

export default function VacanciesByType({ data }:{ data?: LadSupplyQuery}) {

  const categoryData = useMemo(()=>
  [
    {
      key: 'Flat',
      data: data?.lad?.supply?.s3_size_flat
    },
    {
      key: 'Bungalow',
      data: data?.lad?.supply?.s3_size_bungalow
    },
    {
      key: 'Maisonette',
      data: data?.lad?.supply?.s3_size_maisonette
    },
    {
      key: 'House',
      data: data?.lad?.supply?.s3_size_house
    },
    {
      key: 'All',
      data: data?.lad?.supply?.s3_size_all
    },
    {
      key: 'Other',
      data: data?.lad?.supply?.s3_size_other
    }
  ],
  [data])

  const min = 0
        
  const max = useMemo(()=>
    Math.max(
    ...(categoryData
        .map(v=>v.data) as number[])) * 1.2,
    [categoryData])
  
  return (
    <StatsRow>
      <div>
        <Fonts.Header>Vacancies by Type</Fonts.Header>
        <ChartContainer style={{paddingLeft: '2rem'}}>
          <BarChart
            width={440}
            height={400}
            data={[...categoryData].reverse()}
            xAxis={<LinearXAxis type="value" />}
            yAxis={
              <LinearYAxis
                type="category"
                tickSeries={
                  <LinearYAxisTickSeries 
                    label={
                      <LinearAxisTickLabel 
                        position='start'
                        rotation={0}

                        padding={{alongAxis:0, fromAxis: 15}}
                      />} 
                    tickSize={20} />}
              />
            }
            series={
              <BarSeries
                tooltip={null}
                layout="horizontal"
                padding={0.4}
                bar={
                  <Bar
                    gradient={null}
                    tooltip={null}
                    style={(data:any) => {
                        return {
                          fill: '#36c8d1',
                          key: data.key + data.x
                        };                       
                    }}
                  />
                }
              />
            }
          />
        </ChartContainer>
      </div>
    </StatsRow>
  );
}

import { css } from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, position, PositionProps, space, SpaceProps, system } from 'styled-system';

export { theme } from './theme'


export type Systems = LayoutProps & PositionProps & FlexboxProps & SpaceProps & { 
  transform?: string, 
  textAlign?:string, 
  gap?:string 
};

export const systems = css`
  ${space}
  ${flexbox}
  ${layout}
  ${position}
  ${system({
    transform: {
      property: "transform"
    },
    textAlign:{
      property: 'textAlign'
    },
    gap:{
      property: 'gap'
    }
  })}
`
import { useApolloClient } from '@apollo/client';
import { useUserLazyQuery } from 'generated';
import { atoms, auth, setCookie, useQuery } from 'misc';
import { useEffect, ReactNode } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Sidemenu from './LeftMenu';
import {
  Signin,
  Signup,
  ForgotPassword,
  VerifyEmail,
  ResetLinkSent,
  AccountVerified,
  NewPassword,
  ActivateAccount,
  LoadingScreen,
} from './Landing';
import Map from './Map';
import Brief from './Pages/Brief';
import Design from './Pages/Design';
import Explore from './Pages/Explore';
import Finance from './Pages/Finance';
import Market from './Pages/Market';
import Parcel from './Pages/Parcel';
import { DashboardWrapper } from './styles';

export default function App() {
  const [user, setUser] = useRecoilState(atoms.user);
  const [getUser, { data: userData }] = useUserLazyQuery();
  const client = useApolloClient();

  useEffect(() => {
    if (userData?.users_by_pk?.id) {
      setUser(userData?.users_by_pk);
      // navigate('/');
    }
  }, [userData]);

  useEffect(() => {
    auth.onAuthStateChanged((loggedIn) => {
      // console.log('JWT', auth.getJWTToken(), auth.isAuthenticated());
      if (loggedIn) {
        const userId = auth.getClaim('x-hasura-user-id');
        setUser({ id: userId, created_at: new Date()});
        getUser({ variables: { user_id: userId } });
      } else {
        setUser(null);
        client.resetStore();
      }
    });
  }, []);

  return (
    <>
    <Routes>
      {user===undefined?
        <Route
          path="/"
          element={
            <LoadingScreen/>
          }
        />:
      user?.id ? (
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Explore />
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path="/" element={
          <AnonimousRoute>
            <Signin />
            <CheckRefreshToken />
          </AnonimousRoute>
        }/>
      )}

      <Route path="/explore" element={
        <ProtectedRoute>
          <Explore />
        </ProtectedRoute>
      }/>

      <Route path="/market">
        <Route path=":countryCode/:ladCode/:tab" element={
          <ProtectedRoute>
            <Market />
          </ProtectedRoute>
        }/>
        <Route path=":countryCode/:ladCode" element={
          <ProtectedRoute>
            <Market />
          </ProtectedRoute>
        }/>
        <Route path=":countryCode" element={
          <ProtectedRoute>
            <Market />
          </ProtectedRoute>
        }/>
        <Route path="" element={
          <ProtectedRoute>
            <Market />
          </ProtectedRoute>
        }/>
      </Route>
      
      <Route path="/parcels" element={
        <ProtectedRoute>
          <Parcel />
        </ProtectedRoute>
      }/>

      <Route path="/finance" element={
        <ProtectedRoute >
          <Finance />
        </ProtectedRoute>
      }/>

      <Route path="/design" element={
        <ProtectedRoute >
          <Design />
        </ProtectedRoute>
      }/>

      <Route path="/project-brief" element={
        <ProtectedRoute >
          <Brief />
        </ProtectedRoute>
      }/>


      <Route path="/auth/signin" element={
        <AnonimousRoute >
          <Signin />
        </AnonimousRoute>
      }/>

      <Route path="/auth/signup" element={
        <AnonimousRoute>
          <Signup />
        </AnonimousRoute>
      }/>

      <Route path="/auth/verify-email" element={
        <AnonimousRoute >
          <VerifyEmail />
        </AnonimousRoute>
      }/>

      <Route path="/auth/activate" element={
        <AnonimousRoute >
          <AccountVerified />
        </AnonimousRoute>
      }/>

      <Route path="/auth/forgot-password" element={
        <AnonimousRoute >
          <ForgotPassword />
        </AnonimousRoute>
      }/>

      <Route path="/auth/change-password" element={
        <AnonimousRoute >
          <NewPassword />
        </AnonimousRoute>
      }/>

      <Route path="/auth/reset-link-sent" element={
        <AnonimousRoute>
          <ResetLinkSent />
        </AnonimousRoute>
      }/>

      <Route path="/admin/activate-account" element={
        <ActivateAccount />
      }/>
        
      <Route path="*" element={
        <Navigate to="/" />
      }/>
        
    </Routes>
    
    </>
  );
}

const ProtectedRoute = ({
  children
}: {
  children: ReactNode;
}) => {
  const [user] = useRecoilState(atoms.user);

  const location = useLocation()
  useEffect(()=>{
    if(user===undefined){
      console.log('Loading...')
    }else if(user===null){
      if(location.pathname!=='/'){
        console.log('Set cookie redirect ', location.pathname)
        setCookie('redirect', location.pathname, 5);
      }
    }
  },[user])

  return <>{
    user === undefined?
      <LoadingScreen/>:
    user === null?
    <Navigate to="/" />:
    <>
      <CheckRefreshToken />
      <DashboardWrapper>
        <Sidemenu />
        <Map />
        {children}
      </DashboardWrapper>
    </>
  }</>;
};

const AnonimousRoute = ({
  children
}: {
  children: ReactNode;
}) => {
  const [user] = useRecoilState(atoms.user);

  return !user?.id ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
};

function CheckRefreshToken() {
  const refresh_token = useQuery().get('refresh_token');

  useEffect(() => {
    if (refresh_token) {
      auth.refreshSession();
    }
  }, [refresh_token]);
  return null;
}

const memoize = (func:any) => {
  const cache:any = {};
  return (...args:any) => {
    const key = JSON.stringify(args);
    if (cache[key]) {
      return cache[key];
    }
    const result = func(...args);
    cache[key] = result;
    return result;
  };
};

// ignore in-browser warnings until its fixed.
const mutedConsole = memoize((console:any) => ({
  ...console,
  warn: (...args:any) => (
    args[0].includes?.('Encountered two children with the same key')
  )
    ? null
    : console.warn(...args)
}))

if(process.env.NODE_ENV === 'development'){
  global.console = mutedConsole(global.console);
}

import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  Center,
  Pyramid,
  BarRow,
  Explication,
  Bar,
  Paper,
  Title,
  BarTooltip
} from '@ui';
import { simplifyAmount } from 'containers/Map/Overlays/ColorScale';
import { LadDemandQuery } from 'generated';

export default function PopulationPyramid({ data }:{ data?: LadDemandQuery}) {
  
  const max_pop = Math.max(
    ...(Object.entries(data?.pop || {})
      .filter(
        ([key, v]) => key !== '__typename' && 
          !isNaN(v) &&
          key.includes('d5_m_age')
        ).map(([_,v])=>v) as number[]
    )
  );

  return (
    <StatsRow>
      <div>
        <ChartRow>
          <ChartContainer ml="0.5rem">
            <Paper noStyles width='240px' p='1rem 0.5rem' height='100%'>
              <Paper p='1rem !important'>
                <div><Title bold h='h3' textAlign='start'>Total Population <br/>(all ages):</Title></div>
                  <Title bold h='h1' mt='1rem' height='0px'>
                    {data?.pop?.d6_d9_pop_total_2020.toLocaleString()}
                  </Title>
              </Paper>
            </Paper>
          </ChartContainer>
          <ChartContainer ml="-0.5rem" mr='-1.5rem' style={{flex: '1 1 auto'}}>
            <div>
              <Pyramid>
                {Object.entries(data?.pop || {})
                  .filter(
                    ([key, v]) => key !== '__typename' && 
                      !isNaN(v) &&
                      key.includes('d5_m_age')
                    )
                  .reverse()
                  .map(([key, v]) => {
                    return [key.replace('d5_m_',''), v]})
                  .map(([key, v]) => {
                    return <BarRow {...{key}}>
                        <div>
                          {/* female */}
                          <Bar
                            value={ (((data?.pop as any)?.['d5_f_'+ key]) / max_pop ) * 100 }
                            color="#31bdc7"
                            align="end"
                            name={key.replace('90plus','90+') + ''}
                          />
                          <BarTooltip className="bartooltip">
                            {
                              '['+key.replace('90plus','90+').replace('age_', '').replace('_', '-')+']: ' +
                              simplifyAmount((data?.pop as any)?.['d5_f_'+ key]+Number(v)) +' \n'+ //total
                              '(F:' + Math.round((data?.pop as any)?.['d5_f_'+ key]/((data?.pop as any)?.['d5_f_'+ key]+Number(v))*100) + //percent
                              '%, M:' + Math.round((Number(v))/((data?.pop as any)?.['d5_f_'+ key]+Number(v))*100) + //percent
                              '%)'
                            }
                          </BarTooltip>
                        </div>
                        <div>
                          {/* male */}
                          <Bar
                            value={(Number(v) / max_pop) * 100}
                            
                            color="#19686e"
                          />
                        </div>
                      </BarRow>
                  })} 
                <Explication />
              </Pyramid>
            </div>
            <Fonts.Header mt="-2.8rem" ml="1rem">Population Pyramid:</Fonts.Header>

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

import { auth } from 'misc';
import { useEffect, useState } from 'react';
import {
  ButtonRow,
  Header,
  LockIcon,
  Wrapper,
  Button,
  Snackbar,
  Alert,
  TextInput,
  InputRow,
} from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';

export default function ForgotPassword() {
  const [credentials, setCredentials] = useRecoilState(
    ForgotPassword.credentials
  );
  const [error, setError] = useState<any>();
  const [notification, setNotification] = useState<any>();
  const navigate = useNavigate();
  useEffect(() => {
    error && console.log(error);
  }, [error]);

  return (
    <>
      <div>
        <Wrapper>
          <LockIcon />
          <Header>Forgot Password?</Header>
          <InputRow>
            <TextInput
              required
              value={credentials.email}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              id="email"
              placeholder="Email address *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              onClick={() =>
                auth
                  .requestPasswordChange(credentials.email)
                  .then(() => {
                    console.log('requested');
                    navigate('/auth/reset-link-sent');
                  })
                  .catch((err) => {
                    setError(
                      err?.response?.data?.message?.replaceAll('"', '') ||
                        err.message
                    );
                  })
              }
            >
              Reset password
            </Button>
          </ButtonRow>
          <span>
            <div />
            <Link to="/auth/signin">Back to Sign in</Link>
          </span>

          <span>Copyright &copy; InsightsNow, 2023</span>
        </Wrapper>
      </div>

      <Snackbar open={error} onClose={() => setError(undefined)}>
        <Alert onClose={() => setError(undefined)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={notification} onClose={() => setNotification(undefined)}>
        <Alert onClose={() => setNotification(undefined)} severity="success">
          {notification}
        </Alert>
      </Snackbar>
    </>
  );
}

ForgotPassword.credentials = atom({
  key: 'ForgotPassword.credentials',
  default: {
    email: '',
  },
});

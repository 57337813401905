import {
  StatsRow,
  Fonts,
  ChartContainer,
  Grid,
  GridCell
} from '@ui';
import { LadDemandQuery } from 'generated';
import { Arrow } from './styles';

export default function PopulationChange({ data }:{ data?: LadDemandQuery}) {
  const v = data?.pop_changes;

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Change in Population (1Y):</Fonts.Header>
        <ChartContainer>
          <Grid >
              <GridCell>Estimated Population mid-2019</GridCell>
              <GridCell>{Math.abs(v?.pop_mid_2019||0).toLocaleString()}</GridCell>
              <GridCell>Net effect</GridCell>

              <GridCell>Births</GridCell>
              <GridCell>{Math.abs(v?.births||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.births>0}/></GridCell>

              <GridCell>Deaths</GridCell>
              <GridCell>{Math.abs(v?.deaths||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.deaths>0}/></GridCell>

              <GridCell>Birth minus Deaths</GridCell>
              <GridCell>{Math.abs(v?.births_minus_deaths||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.births_minus_deaths>0}/></GridCell>

              <GridCell>Internal Migration Inflow</GridCell>
              <GridCell>{Math.abs(v?.internal_migration_inflow||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.internal_migration_inflow>0}/></GridCell>

              <GridCell>Internal Migration Outflow</GridCell>
              <GridCell>{Math.abs(v?.internal_migration_outflow||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.internal_migration_outflow>0}/></GridCell>

              <GridCell>Internal Migration Net</GridCell>
              <GridCell>{Math.abs(v?.internal_migration_net||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.internal_migration_net>0}/></GridCell>

              <GridCell>International Migration Inflow</GridCell>
              <GridCell>{Math.abs(v?.international_migration_inflow||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.international_migration_inflow>0}/></GridCell>

              <GridCell>International Migration Outflow</GridCell>
              <GridCell>{Math.abs(v?.international_migration_outflow||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.international_migration_outflow>0}/></GridCell>

              <GridCell>International Migration Net</GridCell>
              <GridCell>{Math.abs(v?.international_migration_net||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.international_migration_net>0}/></GridCell>

              <GridCell>Other</GridCell>
              <GridCell>{Math.abs(v?.other||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.other>0}/></GridCell>

              <GridCell>Estimated Population mid-2020</GridCell>
              <GridCell>{Math.abs(v?.estimated_population_mid_2020||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.estimated_population_mid_2020>0}/></GridCell>

              <GridCell>Net Population Increase (Decrease)</GridCell>
              <GridCell>{Math.abs(v?.net_change||0).toLocaleString()}</GridCell>
              <GridCell><Arrow up={v?.net_change>0}/></GridCell>

          </Grid>
        </ChartContainer>
      </div>
    </StatsRow>
  );
}

import styled, { css } from 'styled-components/macro';
import ReactCountryFlagUnstyled from 'react-country-flag';
import { Fonts } from '../Fonts';
import { ReactElement } from 'react';
import { ComponentParams, ComponentProps } from '../Fonts/fonts';

export const PanelWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    height: 100%;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
  `,

  PanelContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 2rem;
  `,

  ReactCountryFlag = styled(ReactCountryFlagUnstyled).attrs({
    svg: true,
    style: {
      width: '4rem',
      height: '4rem',
    },
  })``,

  Flag = styled.span.attrs(({ code }: { code: string }) => ({
    code,
    children: <ReactCountryFlag countryCode={code} />,
  }))`
    margin-left: 2rem;
  `,

  PanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      > h2 {
        white-space: nowrap;
        display: inline;
      }
    }
  `,

  CloseButton = styled.button.attrs({
    children: (
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7502 0L8.9992 7.18593L2.2498 0L0 2.39531L6.7494 9.58124L0 16.7672L2.2498 19.1625L8.9992 11.9766L15.7502 19.1625L18 16.7672L11.2506 9.58124L18 2.39531L15.7502 0Z"
        />
      </svg>
    ),
  })`
    border: none;
    background-color: rgba(0,0,0,0) !important;
    outline: none;
    padding: 0;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      transform: scale(1);
      fill: #666666;
      &:hover {
        fill: black;
      }
    }
  `,
  
  StatsRow = styled.div<{ narrow?: boolean }>`
    margin-top: 2rem;
    display: flex;
    font-size: 12px;
    font-weight: 300;
    font-family: Poppins;
    > div {
      flex: 1 1 auto;
      width: 25%;
      display: flex;
      flex-direction: column;
      > * {
        white-space: wrap;
        line-height: 1rem !important;
      }
      > :first-child {
        //white-space: wrap;
        ${({ narrow = true }) =>
          narrow
            ? css`
                min-height: 1rem;
              `
            : css`
                min-height: 3rem;
              `}
        margin-bottom: 0.5rem;
      }
    }
  `,
  SectorsRow = styled.div`
    margin-top: 1rem;
    display: flex;
    width: 100%;
    height: 62px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4rem;
  `,
  Label = styled.div`
    position: absolute;
    color: black;
    margin-top: calc(62px + 2rem);
    font-weight: 300;
    font-size: 11px;
  `,
  Sector = styled.span.attrs(
    (props: { color: string; value: number; name?: string }) => ({
      name: props.name,
      color: props.color,
      children: (
        <>
          <span>{props.value.toFixed(1) + '%'}</span>
          <Label>{props.name}</Label>
        </>
      ),
    })
  )<{ color: string; value: number }>`
    width: ${(p) => p.value}%;
    background-color: ${(p) => p.color};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 62px;
    white-space: nowrap;
    overflow: visible;
    min-width: 1.4rem;
    transition: width 0.2s ease-in-out;
  `,
  Demographics = styled.div`
    display: flex;
    width: 100%;
    > div {
      flex: 1 1 auto;

      > div {
        width: 250px;
        /* height: 180px; */
        box-sizing: border-box;
      }
    }
  `,
  BarRow = styled.div`
    display: flex;
    margin: 0;
    height: 16px !important;
    > div {
      flex: 1 1 auto;
      width: 50%;
      justify-content: start;
      display: flex;
      position: relative;
    }
    > div:first-child {
      justify-content: end;
    }
    & .bartooltip{
      display: none;
    }
    :hover .bartooltip{
      display: flex;
    }
    
  `,
  Bar = styled.div.attrs(
    (props: { color: string; value: number; align?: 'end'; name?: string }) => ({
      name: props.name,
      color: props.color,
      value: props.value,
      align: props.align,
      children: (
        <span>{props.name?.replace('age_', '').replace('_', '-')}</span>
      ),
    })
  )<{ color: string; align?: 'end'; value: number }>`
    width: ${(p) => p.value}%;
    margin-bottom: 0.2rem;
    background-color: ${(p) => p.color};
    height: 14px;
    font-weight: 300;
    font-size: 11px;
    display: flex;
    ${({ align })=> align === 'end' && css`justify-content: flex-end;`}
    > span {
      color: #e6e6e6;
      margin-left: 0.5rem;
      margin-right: 0.25rem;

      position: absolute;
      font-weight: 600;
    }
  `,
  BarTooltip = styled.div`
    z-index:3;
    font-size: 10px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 6px;
    border-radius: 3px;
    background-color: rgba(124, 211, 215, .5);
    white-space: pre-line; 
  `,

  Chart = styled.div`
    margin-top: 1rem;
    height: 180px;
  `,
  Pyramid = styled.div`
    width: 85%;
    margin-top: 1rem;
    position: relative;
  `,
  Explication = styled.div.attrs({
    children: (
      <>
        <div>
          <div />
          Female
        </div>
        <div>
          <div />
          Male
        </div>
      </>
    ),
  })`
    position: absolute;
    top: 0;
    right: 1rem;
    color: black;
    font-size: 11px;
    > div:first-child {
      display: flex;
      align-items: center;
      > div {
        background-color: #2cb0bb;
        width: 8px;
        height: 8px;
        margin-right: 5px;
      }
    }
    > div:last-child {
      display: flex;
      align-items: center;
      > div {
        background-color: #18555b;
        width: 8px;
        height: 8px;
        margin-right: 5px;
      }
    }
  `,
  Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 6rem;
  `,
  ResidentialDemand = styled.div`
  display: flex;
  align-items: baseline;
  >span{
    margin 0 2rem;
  }
`,
  SliderContainer = styled.div.attrs((p) => ({
    children: (
      <>
        <div>
          <Fonts.Header>1</Fonts.Header>
          <Fonts.Header>3</Fonts.Header>
          <Fonts.Header>5</Fonts.Header>
          <Fonts.Header>10</Fonts.Header>
        </div>
        {p.children}
      </>
    ),
  }))`
    display: flex;
    flex-direction: column;
    width: 100%;
    > div:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  `,
  Slider = styled.input.attrs({
    type: 'range',
    min: 0,
    max: 3,
  })`
    -webkit-appearance: none;
    display: flex;
    flex: 1 1 auto;
    /* width: 100%; */
    height: 5px;
    background: #b7b7b7;
    outline: none;
    opacity: 0.7;
    margin-right: 5px;
    margin-left: -2px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 8px;
      height: 20px;
      background: #393c54;
      cursor: pointer;
    }
    &::-moz-range-thumb {
      width: 8px;
      height: 20px;
      background: #393c54;
      cursor: pointer;
    }
  `,
  TopCities = styled.div`
    display: flex;
    > div:first-child {
      width: 6rem;
      align-items: flex-end;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-right: 1rem;
    }
  `,

  Year = styled.div.attrs((p:any) => ({
    children: (
      <>
        {p.children as ReactElement}
        <div />
      </>
    )
  }))<{ active?: boolean, children: any }>`
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    > div {
      ${(p) =>
        p.active
          ? css`
              background-color: #2fbdc8;
            `
          : css`
              background-color: #b7b7b7;
            `};
      height: 18px;
      width: 18px;
      border-radius: 50%;
      margin-left: 0.5rem;
    }
  `,

  // Divider = styled.div`
  //   height: 2rem;
  //   border-right: 1px solid #b7b7b7;
  //   width: 1px;
  //   margin-right: 9px;
  // `,

ChartRow = styled.div<{ml?: string}>`
  display: flex;
  >{
    flex: 1 1 auto;
  }
`,

ChartContainer = styled.div<ComponentProps>`
  margin: 2rem;
  position: relative;
  >:not(:first-child){
    position:absolute;
    top: 0;
  }
  ${ComponentParams}
`,

MultipleChartContainer = styled.div<ComponentProps>`
  margin: 2rem;
  position:relative;
  height: 300px;
  >div{
    position:absolute;
    bottom: 0;
    >svg{
      position:absolute;
      bottom: 0;
    }
  }
  ${ComponentParams}
`,
Attribution = styled.div`
  margin-top: 2rem;
  font-size: small;`
;

import styled from 'styled-components';

export const MenuWrapper = styled.div<{ active: boolean }>`
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
    background-color: ${(p) => (p.active ? '#272a3d' : 'unset')};
    :hover {
      background-color: ${(p) => (p.active ? '#343747' : '#4b4f66')};
    }
    > div:first-child {
      color: #969cba;
    }
  `,
  SidebarWrapper = styled.div`
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    flex: 0 0 5rem;
    min-height: 100vh;
    background-color: #373b53;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: scroll;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  `,
  TopBlock = styled.div`
    > div:first-child {
      position: relative;
      width: 100%;
      height: 5rem;
      background-color: #373b53;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  `,
  BottomBlock = styled.div`
    > :first-child {
      max-width: 2rem;
      cursor: pointer;
      max-height: 2rem;
      margin: 1.5rem auto 1rem auto;
      position: relative;
    }
  `;

import { useLadIncomeQuery } from 'generated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnnualHouseholdIncome from './AnnualHouseholdIncome';
import MeanEquivalisedIncome from './MeanEquivalisedIncome';

export default function Panel() {
  const { ladCode = '' } = useParams<{ ladCode: string }>();
  const { data:sourceData } = useLadIncomeQuery({ variables: { ladCode } });
  const [data, setData] = useState(sourceData)
  
  useEffect(()=>{
    if(sourceData){
      setData(sourceData)
    }
  },[sourceData])

  return (<>
    <AnnualHouseholdIncome {...{data}} />
    <MeanEquivalisedIncome {...{data}} />
  </>);
}

import { useState, useEffect, useContext } from 'react';
import MapGL, { MapContext, Source } from '@urbica/react-map-gl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { atoms, ExternalMapContext, mapStyle } from 'misc';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useLocation } from 'react-router-dom';
import Layers from './Layers';
import Sources from './Sources';
import Draw from './Draw';
import Measurements from './MeasurementLayers';
import { Map, Projection } from 'mapbox-gl';
import { CountryFeatureQuery, useCountryFeatureQuery, useFeatureQuery, useMapboxKeyQuery } from 'generated';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import { GeocodeControl } from '../GeocodeControl';
import {auth} from 'misc';
import { countriesMode, selected } from 'misc/recoil/atoms';

// import fs from 'fs';

export function deleteSelected({
  setData = (v: GeoJSON.FeatureCollection) => {
    return;
  },
  data = {} as GeoJSON.FeatureCollection,
  selectedFeatures = [] as GeoJSON.Feature[],
  remove = (v: any) => {
    return;
  },
}) {
  setData({
    ...data,
    features: [...data.features].filter(
      (v) =>
        !(
          selectedFeatures.map((s: GeoJSON.Feature) => s.id) as string[]
        ).includes(String(v.id || ''))
    ),
  });
  remove({
    variables: {
      polygons: selectedFeatures.map((s: GeoJSON.Feature) => s.id) as string[],
    },
  });
}

export default function MapComponent() {
  const [viewport, setViewport] = useRecoilState(atoms.viewport);
  const [cursor, setCursor] = useRecoilState(atoms.cursor);
  const [, setLoaded] = useState(false);
  const [hovered, setHovered] = useRecoilState(atoms.hovered);
  const [tokens, setTokens] = useRecoilState(atoms.tokens);
  const [valuesToDisplay, setValuesToDisplay] = useState<T>({
    type: 'FeatureCollection',
    features: [],
  });
  const user = useRecoilValue(atoms.user);
  // const [map, setMap] = useState<Map>();
  const [mode] = useRecoilState(atoms.measurementMode);

  const location = useLocation();
  const [mapLayers, setMapLayers] = useRecoilState(atoms.mapLayers);
  // const {data: comparison} = useCountryFeatureQuery({onCompleted: data => add(map, data)});
  
  const url = location.pathname.split('/')[1];
  // console.log('url',location.pathname,  url || 'explore');
  useEffect(() => {
    hovered ? setCursor('pointer') : setCursor('');
  }, [hovered]);

  useEffect(() => {
    if (location.pathname.includes('market')) {
      setMapLayers({ ...mapLayers, countriesData: true });
    } else {
      setMapLayers({ ...mapLayers, countriesData: false });
    }
  }, [location.pathname]);

  const { data: keys } = useMapboxKeyQuery();

  useEffect(() => {
    if (typeof keys?.mapbox?.token === 'string') {
      setTokens((prev) => ({ ...prev, mapbox: keys?.mapbox?.token }));
    }
  }, [keys, setTokens]);

  // fs.writeFileSync('style.json', JSON.stringify(mapStyle));
  // console.log('jwt', auth.getJWTToken());
  return !tokens.mapbox ? null : (
    <MapGL
      accessToken={tokens.mapbox}
      onViewportChange={(v:any)=>{setViewport(v);if (mapLayers.terrain && viewport.zoom > 6 && url === 'explore')setViewport({...v, pitch:75})}}
      onLoad={(e: any) => {
        setLoaded(true);
      }}
      
      cursorStyle={cursor}
      logoPosition="bottom-right"
      attributionControl={false}
      viewportChangeMethod="flyTo"
      // hash
      style={{
        width: '100vw',
        height: '100vh',
      }}
      {...{ ...viewport, mapStyle }}
      
      transformRequest={(url, resourceType) => {
        // console.log('using token', auth.getJWTToken());
        const authHeaders = {
          'Sec-Fetch-Dest': 'empty',
          'Sec-Fetch-Mode': 'cors',
          'TE':'trailers',
          'Sec-Fetch-Site': 'same-site',
          'Authorization': "Bearer "+ auth.getJWTToken()
        };
        const noAuthHeaders = {
          'Sec-Fetch-Dest': 'empty',
          'Sec-Fetch-Mode': 'cors',
          'TE':'trailers',
          'Sec-Fetch-Site': 'same-site'};
        
        //for local testing
        // const apiURL= process.env.REACT_APP_TILESERVER??'insightsnow.co.uk';

        //temp unsecure fix
        const apiURL= 'insightsnow.co.uk';

        return {url: url, 

          //temp unsecure fix
          headers:noAuthHeaders,
          //secure, not working on server
          // headers:(url.indexOf(apiURL) > -1)?authHeaders:noAuthHeaders,


          //worked on server >
          // headers:(url.indexOf(apiURL) > -1)?authHeaders:noAuthHeaders,

          // headers:(resourceType === 'Source' && url.indexOf(apiURL) > -1)?authHeaders:noAuthHeaders,
          //works on local >
          // headers:authHeaders,//(resourceType === 'Source' && url.indexOf(apiURL) > -1)?authHeaders:noAuthHeaders,
          credentials: 'same-origin',
          method: 'GET'}; 
      }}
      // pitch={0}
      
      pitch={(mapLayers.terrain || mapLayers.buildings) && viewport.zoom > 6 && url === 'explore' ? 75 : 0}
    >
      <MapContext.Consumer>
        {(map: any) => { 
            // setMap(map);
            if (!map.getSource('mapbox-dem')){
              console.log('map style load');
              map.addSource('mapbox-dem', {
                type: 'raster-dem',
                url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                tileSize: 512,
                maxzoom: 14,
              });
              // add the DEM source as a terrain layer with exaggerated height
              map.setTerrain({ source: 'mapbox-dem', exaggeration: 
              [
                'interpolate', ['linear'],
                  ['zoom'], 5, 3, 7, 1.2
                ]
               });
                map.setProjection('mercator'); //to make sure fog is working
              map.setFog({

                color: 'white', // Lower atmosphere
                'high-color': 'rgb(201, 217, 235)', // Upper atmosphere
                'horizon-blend': 0.05, // Atmosphere thickness (default 0.2 at low zooms)
                'space-color': 'rgb(124, 186, 214)', // Background color
                'star-intensity': 0 // Background star brightness (default 0.35 at low zoooms )
            } as mapboxgl.Fog);


            }
            
          return <MapContextSetter {...{ map }} 
          // setter={setValuesToDisplay} 
          />; }}
      </MapContext.Consumer>
      {/* <Source id="values" type ="geojson" data={valuesToDisplay} /> */}

      <Sources />
      {mode && <Measurements />}
      {location.pathname.includes('parcels') ? <Draw /> : <Layers />}
    </MapGL>
  );
}

type T = GeoJSON.FeatureCollection<GeoJSON.Point>;

function MapContextSetter({
  map,
}: 
{
  map: Map;
  
}) {
  const featLayer = "country-fill";
  const  hovered = useRecoilValue(atoms.hovered);
  const selected = useRecoilValue(atoms.selected);
  const mapContext = useContext(ExternalMapContext);
  mapContext.map = map; // set map context
  const tokens = useRecoilValue(atoms.tokens);
  GeocodeControl({ accessToken: tokens.mapbox as string });
  const {data} = useCountryFeatureQuery();
  useEffect(()=>{
    data?.country.forEach((country)=>{

      (map as Map).setFeatureState(
        {
          source: 'country_extended', 
          sourceLayer: "public.country_extended",
          id: country.id
        },
        {
          pop_growth_10y: country.macro?.pop_growth_10y,
          pop_growth_cagr_10y: country.population?.pop_growth_cagr_10y, 
          urban_growth_rate: country.population?.urban_growth_rate_2020_2025,
          unemployment: country.ma?.unemployment,
          avg_household_size: country.household?.size_avg,
          gdp_per_capita: country.gdp?.gdp_percapita_current_usd_2020,
          hover: hovered?.id === country.id,
          selected: selected?.id === country.id,
      });
      })
  }, [map, data, hovered?.id, selected?.id]);

  useEffect(() => {
    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [featLayer],
      });
      console.log('features', features);
    });}, [map]);
    return null
}
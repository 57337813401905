import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const ViewOptions = styled.div<{ hovered: boolean; open: boolean }>`
    z-index: 50;
    position: absolute;
    top: 7rem;
    right: 2rem;
    background-color: rgba(255, 255, 255, 1);
    min-width: ${(p) => (p.open ? '16rem' : '3.5rem')};
    min-height: ${(p) => (p.open ? '19rem' : '3.5rem')};
    transition: all 0.3s ease-in-out;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    border: ${(p) =>
      p.hovered
        ? css<{}>`0.2px solid rgba(0,0,0,0.6)`
        : css<{}>`0.1px solid rgba(0,0,0,0.2)`};
  `,
  Name = ({ children }: any) => (
    <Typography variant="body2">{children}</Typography>
  );

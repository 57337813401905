import { Systems, systems } from '@ui/common';
import styled from 'styled-components';

export const
LegendWrapper = styled.div<Systems>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  line-height: normal;
  ${systems}
`,

LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`,

Snippet = styled.div<{color: string, stroke?: string}>`
  width: 8px;
  min-width: 8px;
  margin-top: 4px;
  height: 8px;
  margin-right: 0.5rem;
  background-color: ${props => props.color};
  ${props => props.stroke && `border: 2px solid ${props.stroke}`}
`,

LineSnippet = styled.svg.attrs((p:Partial<SVGLineElement['style']>)=>({
  children: <line x1='0' y1='4' x2='32' y2='4' strokeWidth={2} style={p as any}/>
}))`
  width: 32px;
  height: 8px;
  margin-right: 0.5rem;
`,

RectSnippet = styled.svg.attrs((p:Partial<SVGRectElement['style']>)=>({
  children: <rect width='16' height='16' style={p as any}></rect>
}))`
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  outline: 2px solid ${p => p.fill};
  mask: none !important;
`
import { useSpring } from 'react-spring';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as d3 from 'd3-format';
import { useMousePosition, Fonts } from 'misc';
import {
  Flag,
  PopupAnimated,
  PopupWrapper,
  Index,
  SmallPopupWrapper,
} from './styles';
import { useCountryStatsQuery } from 'generated';

const format: any = d3.format('~s'); //d3.format(".4")

export function Popup({
  hovered = undefined as { [prop: string]: any } | undefined,
}) {
  const { data } = useCountryStatsQuery({
    variables: { countryCode: hovered?.properties?.iso || '' },
  });

  const { x, y } = useMousePosition();
  const popupSpring = useSpring({
    opacity: hovered ? 1 : 0,
  });

  return hovered?.properties?.pop_growth_10y ? (
    <PopupWrapper {...{ x, y }}>
      <PopupAnimated {...{ hovered }} style={popupSpring}>
        <div>
          <Fonts.Header>{hovered?.properties?.name || ''}</Fonts.Header>
          {hovered?.properties?.iso && (
            <Flag code={hovered?.properties?.iso || 'GB'} />
          )}
        </div>
        <div>
          {/* 1-1 */}
          <div>
            <Fonts.Subheader>Population Growth (10y):</Fonts.Subheader>
            <p>
                  {hovered?.properties?.pop_growth_10y >= 1000000 || hovered?.properties?.pop_growth_10y <= -1000000? 
                  <>
                    <Fonts.Header>
                    {(hovered?.properties?.pop_growth_10y / 1000000).toFixed(1)}
                    </Fonts.Header>{' '}
                    <Fonts.Numbers>million</Fonts.Numbers>{' '}
                  </>:<>
                    <Fonts.Header>
                      {hovered?.properties?.pop_growth_10y.toLocaleString()}
                    </Fonts.Header>{' '}
                  </>
                  }
                <Fonts.Rank>({hovered?.properties?.pop_rank})</Fonts.Rank>
            </p>
          </div>
          {/* 2-1 */}
          <div>
            <Fonts.Subheader>Population Growth % (10y):</Fonts.Subheader>
            <p>
              <div>
                <Fonts.Header>
                  {(data?.country?.[0]?.population?.pop_growth_cagr_10y*100).toFixed(2) ||
                    '-'}
                </Fonts.Header>{' '}
                {data?.country?.[0]?.population?.pop_growth_cagr_10y !== undefined && <> <Fonts.Numbers>%</Fonts.Numbers>{' '} <Fonts.Area>(CAGR)</Fonts.Area></>}
                {/* <Fonts.Rank>(10y, CAGR)</Fonts.Rank> */}
              </div>
            </p>
          </div>
        </div>
        <div>
          {/* 1-2 */}
          <div>
            <Fonts.Subheader>Average Household Size</Fonts.Subheader>
            <p>
              <Fonts.Header>
                {data?.country?.[0]?.household?.size_avg || '-'}
              </Fonts.Header>{' '}
              {data?.country?.[0]?.household?.size_avg !== undefined && <Fonts.Area>(people per household)</Fonts.Area>}{' '}
            </p>
          </div>

          
          {/* 2-2 */}
          <div>
            <Fonts.Subheader>Unemployment</Fonts.Subheader>
            <p>
              <Fonts.Header>
                {data?.country?.[0]?.ma?.unemployment || '-'}
              </Fonts.Header>{' '}
              {data?.country?.[0]?.ma?.unemployment !== undefined &&<Fonts.Numbers>%</Fonts.Numbers>}{' '}
            </p>
          </div>
          {/* <div>
            <Fonts.Subheader>Currency</Fonts.Subheader>
            <p>
              <Fonts.Subheader>
                {data?.country?.[0]?.currency_name || '-'}
              </Fonts.Subheader>{' '}
              <Fonts.Header>
                ({data?.country?.[0]?.currency_iso3 || '-'})
              </Fonts.Header>{' '}
            </p>
          </div> */}
        </div>
        <div>
          {/* 1-3 */}
          <div>
            <Fonts.Subheader>Urban Growth Rate</Fonts.Subheader>
            <p>
              <Fonts.Header>
                {Math.round(
                  data?.country?.[0]?.population?.urban_growth_rate_2020_2025) || '-'}
              </Fonts.Header>{' '}
              {data?.country?.[0]?.population?.urban_growth_rate_2020_2025 !== undefined &&<Fonts.Numbers>%</Fonts.Numbers>}{' '}
            </p>
          </div>
          {/* 2-3 */}
          <div>
            <Fonts.Subheader>GDP per Capita (USD)</Fonts.Subheader>
            <p>
              <Fonts.Header>
                {(data?.country?.[0]?.gdp?.gdp_percapita_current_usd_2020||0).toLocaleString()}
              </Fonts.Header>{' '}
            </p>
          </div>
        </div>
        {/* Bottom */}
        <div>
          <p>
            <Fonts.Header>now{'  '}</Fonts.Header>
            <Fonts.Area>index:{'  '}</Fonts.Area>
            <Index>{hovered?.properties?.index}</Index>{' '}
            <Fonts.Area>
              (Global Rank {hovered?.properties?.index_rank})
            </Fonts.Area>
          </p>
        </div>
      </PopupAnimated>
    </PopupWrapper>
  ) : (
    <SmallPopupWrapper {...{ x, y }}>
      <PopupAnimated {...{ hovered }} style={popupSpring}>
        <div>
          <Fonts.Header>{hovered?.properties?.name || ''}</Fonts.Header>
        </div>
      </PopupAnimated>
    </SmallPopupWrapper>
  );
}

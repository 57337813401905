import { Systems, systems } from '@ui/common'
import styled, { css } from 'styled-components/macro'

export const 
Paper = styled.div<Systems&{ noStyles?: boolean, active?: boolean, hoverable?: boolean }>`
  align-self:center;
  justify-self:center;
  display: flex; 
  justify-content: center; 
  align-items: start; 
  flex-direction: column;
  ${p=>!p.noStyles && css`
    border-radius: 10px;
    box-shadow: 3px 8px 5px 0px rgba(0,0,0,0.3);
    background-color: #F8F8FB;
  `}
  >img{
    mix-blend-mode: darken;
  }
  ${({hoverable})=>hoverable && css`
    :hover{
      cursor: pointer;
      background-color: #f4f4f4;
    }`}
  ${({active})=>active && css`
    background-color: #36c8d1 !important;
  `}
  ${systems}
`,

Row = styled.div<Systems>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${systems}
`
import { Switch as UnstyledSwitch } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';

export const Switch = withStyles((theme) =>
  createStyles({
    root: {
      width: '3rem',
      height: '1.4rem',
      padding: 0,
      display: 'flex',
      boxSizing: 'border-box',
    },
    switchBase: {
      padding: 2,
      color: '#969cba',
      '&$checked': {
        transform: 'translateX(1.6rem)',
        color: '#fff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#35c8d1',
          borderColor: '#969cba',
        },
      },
    },
    thumb: {
      width: '1.05rem',
      height: '1.05rem',
      boxShadow: 'none',
      marginTop: '0.06rem',
      backgroundColor: '#fff',
    },
    track: {
      // border: `1px solid #969cba`,
      borderRadius: '1.5rem',
      opacity: 1,
      boxSizing: 'border-box',
      backgroundColor: '#969cba',
    },
    checked: {},
  })
)(UnstyledSwitch);
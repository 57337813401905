import { atom } from 'recoil';
import { Maybe, UserQuery } from 'generated';
import { CameraParams } from 'misc';
import { Viewport } from '@urbica/react-map-gl';

export const user = atom({
  key: 'user',
  default: undefined as UserQuery['users_by_pk'] | undefined | null,
});

export const tokens = atom({
  key: 'tokens',
  default: {
    mapbox: undefined as Maybe<string> | undefined,
  },
});

export const userProfile = atom({
  key: 'userProfile',
  default: false,
});
export const settings = atom({
  key: 'settings',
  default: false,
});

export const drawMode = atom({
  key: 'drawMode',
  default: 'simple_select',
});

export const drawSelected = atom({
  key: 'drawSelcted',
  default: [] as GeoJSON.Feature[],
});

export const polygonForm = atom({
  key: 'polygonForm',
  default: null as {
    id: string;
    geom: string;
    centroid: number[];
    user_id: string;
    name?: string;
  } | null,
});

export const measurementMode = atom({
  key: 'measurementMode',
  default: null as 'length' | 'area' | null,
});

export const mapLayers = atom({
  key: 'mapLayers',
  default: {
    boundaries: true,
    satellite: false,
    terrain: false,
    green: false,
    buildings: false,
  } as {
    [layername: string]: boolean;
  },
});

export const dataLayers = [
  {
    name: 'Pop. Growth',
    slug: 'pop_growth_10y',
    source: 'United Nations ©, CC license 3.0 IGO',
  },
  {
    name: 'Pop. Growth % (10Y CAGR)',
    slug: 'pop_growth_cagr_10y',
    source: 'United Nations ©, CC license 3.0 IGO',
  },
  {
    name: 'Unemployment',
    slug: 'unemployment',
    source: 'World Bank Data, CC-BY 4.0'
  },
  {
    name: 'Urban Growth Rate',
    slug: 'urban_growth_rate',
    source: 'United Nations ©, CC license 3.0 IGO'
  },
  {
    name: 'Avg. Household Size',
    slug: 'avg_household_size',
    source: 'United Nations ©, CC license 3.0 IGO' //country level
  },
  {
    name: 'GDP per Capita',
    slug: 'gdp_per_capita',
    source: 'World Bank Data, CC-BY 4.0'
  },
  // everything for LAD level: ONS ©   Crown copyright, licensed under the Open Government Licence v.3.0
  // {
  //   name: 'Housing Deficit',
  //   slug: 'housing_deficit',
  //   source: ''
  // },
  // {
  //   name: 'Const. Cost Index',
  //   slug: 'constr_cost',
  //   source: ''

  // },
  // {
  //   name: 'Avg. Sales Rates',
  //   slug: 'sales_rate_eur_sqm',
  //   source: ''

  // },
  // {
  //   name: 'Avg. Rental Rates',
  //   slug: 'rental_rate_eur_sqm',
  //   source: ''

  // },
  // {
  //   name: 'Skills Deficit Index',
  //   slug: 'skills_deficit',
  // },
  // {
  //   name: 'CELA index',
  //   slug: 'index',
  //   source: ''

  // },
];
export const dataDomains = atom({
  key: 'dataDomains',
  default: 
    {} as {[slug: string] :{ min: number, max: number, isPercentage: boolean }}});

export const countriesMode = atom({
  key: 'countriesMode',
  //type of countriesMode is one of the slug of dataLayers
  default: dataLayers[0].slug as typeof dataLayers[number]['slug'],
});

export const drawData = atom({
  key: 'drawData',
  default: {
    type: 'FeatureCollection',
    features: [],
  } as GeoJSON.FeatureCollection,
});

export const defaultLocation = {
  latitude: 51.5,
  longitude: 0,
  zoom: 11,
  pitch: 0,
  bearing: 0,
  viewportChangeMethod: 'easeTo',
  viewportChangeOptions: { duration: 3000 },
} as Viewport//CameraParams;
//51.50944/-0.05923

export const viewport = atom({
  key: 'viewport',
  default: defaultLocation as Viewport, //CameraParams,
});

export const cursor = atom({
  key: 'cursor',
  default: '',
});

export const selected = atom({
  key: 'selected',
  default: undefined as undefined | { [prop: string]: any },
});

export const hovered = atom({
  key: 'hovered',
  default: undefined as undefined | { [prop: string]: any },
});

export const displayedDataSource = atom({
  key: 'dataSource',
  default: dataLayers[0].source as string | undefined
})
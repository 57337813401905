import { Header, Wrapper, TextInfo, MailIcon } from './styles';
import { useRecoilState } from 'recoil';
import ForgotPassword from './ForgotPassword';

export default function ResetLink() {
  const [credentials, setCredentials] = useRecoilState(
    ForgotPassword.credentials
  );
  return (
    <div>
      <Wrapper>
        <MailIcon />
        <Header>Reset link sent!</Header>
        <TextInfo>
          We've sent your password rest link to your email{' '}
          {credentials.email && 'at ' + credentials.email}. Please check your
          mail and click the link to reset your password.
        </TextInfo>
        <span>Copyright &copy; InsightsNow, 2023</span>
      </Wrapper>
    </div>
  );
}

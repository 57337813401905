import { useMacroRangesQuery } from 'generated';
import {
  ColorScaleGradient,
  ColorScaleWrapper,
  ColorScaleLabel,
} from '../styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { atoms } from 'misc/recoil';

function ColorScale() {
  const macroRanges = useMacroRangesQuery();
  const [viewport, setViewport] = useRecoilState(atoms.viewport);

  const [countriesMode] = useRecoilState(atoms.countriesMode);
  const dataDomains = useRecoilValue(atoms.dataDomains);
  const isPercentage = dataDomains[countriesMode]?.isPercentage;
  const min = dataDomains[countriesMode]?.min*(isPercentage?100:1);
  const max = dataDomains[countriesMode]?.max*(isPercentage?100:1);
  return (
      (viewport.zoom < 6 && countriesMode!=='') ?
      
        <ColorScaleWrapper>
          <ColorScaleGradient />
        <ColorScaleLabel>
        {/* <span>{max}</span> */}
        <span>{simplifyAmount(dataDomains[countriesMode]?.max, dataDomains[countriesMode]?.isPercentage)}</span>
        <span>
        {dataDomains[countriesMode]?.min < 0
          ? '-' + simplifyAmount(-dataDomains[countriesMode]?.min, dataDomains[countriesMode]?.isPercentage)
          : simplifyAmount(dataDomains[countriesMode]?.min, dataDomains[countriesMode]?.isPercentage)}{' '}
          </span>
          </ColorScaleLabel>
          </ColorScaleWrapper>
        : <></>
  );
}
export function simplifyAmount(number: number, isPercentage=false as boolean) {
  if (isPercentage) return amountAsPercentage(number);
  //function that returns a simplified number, e.g. 1.2M instead of 1,200,000
  if (number > 1000000000) {
    return (number / 1000000000).toFixed(1) + 'B';
  }
  if (number > 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  }
  if (number > 1000) {
    return (number / 1000).toFixed(1) + 'K';
  }

  return (number*1).toFixed(1)+'';
}

export function amountAsPercentage(number: number) {
  return (number*100).toFixed(2)+'%';
}

export default ColorScale;

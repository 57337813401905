import styled from 'styled-components';

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `,
  Divider = styled.div`
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
  `;

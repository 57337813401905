import styled from 'styled-components';

export const Subheader = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 12px;
    padding: 0;
    margin: 0;
    line-height: 0px;
  `,
  Header = styled.span`
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    line-height: 0px;
  `,
  Numbers = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 18px;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    line-height: 0px;
  `,
  Rank = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 10px;
    color: #c4c4c4;
    padding: 0;
    margin: 0;
    line-height: 0px;
  `,
  Area = styled.span`
    font-family: Poppins;
    font-weight: 400;
    font-size: 11px;
    padding: 0;
    margin: 0;
    line-height: 0px;
  `;

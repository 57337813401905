import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';
import { Source, Layer, MapContext } from '@urbica/react-map-gl';
import getCentroid from '@turf/centroid';
import getArea from '@turf/area';
import getLength from '@turf/length';
import { useContext } from 'react';
import { Map } from 'mapbox-gl';
import { Feature, FeatureCollection, GeometryCollection, Units } from "@turf/helpers";
export default function Measurements() {
  const [mode] = useRecoilState(atoms.measurementMode);
  const [data, setData] = useState<GeoJSON.FeatureCollection<GeoJSON.Point>>({
    type: 'FeatureCollection',
    features: [],
  });
  const map: Map | null = useContext(MapContext);

  useEffect(() => {
    map?.on('click', (e) =>
      setData({
        ...data,
        features: [
          ...data.features,
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [e?.lngLat?.lng || 0, e?.lngLat?.lat || 0],
            },
            properties: {},
          },
        ],
      })
    );
  }, [data.features]);

  const string = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: data.features.map((f) => f.geometry.coordinates),
    },
    properties: {},
  } as Feature<any>;

  const poly = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          ...data.features.map((f) => f.geometry.coordinates),
          data.features?.[0]?.geometry?.coordinates,
        ],
      ],
    },
  };

  return (
    <>
      <Source id="measurement-nodes" type="geojson" data={data} />
      <Source
        id="measurement-linestring"
        type="geojson"
        data={{
          ...data,
          features:
            data.features.length > 1
              ? [
                  {
                    ...string,
                    properties: {
                      length: (getLength(string) * 1000).toFixed(0),
                    },
                  } as GeoJSON.Feature<GeoJSON.LineString>,
                ]
              : [],
        }}
      />
      <Source
        id="measurement-polygon"
        type="geojson"
        data={{
          ...data,
          features:
            data.features.length > 1
              ? [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates:
                        data.features.length > 2
                          ? [
                              [
                                ...data.features.map(
                                  (f) => f.geometry.coordinates
                                ),
                                data.features[0].geometry.coordinates,
                              ],
                            ]
                          : [
                              [
                                ...data.features.map(
                                  (f) => f.geometry.coordinates
                                ),
                              ],
                            ],
                    },
                    properties: {},
                  },
                ]
              : [],
        }}
      />
      <Source
        id="measurement-centroid"
        type="geojson"
        data={{
          ...data,
          features:
            data.features.length > 2
              ? [
                  {
                    ...getCentroid({
                      ...poly.geometry,
                    }),
                    properties: {
                      area: getArea(poly.geometry).toFixed(0),
                    },
                  } as GeoJSON.Feature<GeoJSON.Point>,
                ]
              : [],
        }}
      />
      <Layer
        id="measurements-length"
        source={
          mode === 'length' ? 'measurement-linestring' : 'measurement-polygon'
        }
        type="line"
        paint={{
          'line-color': '#34a4c0',
        }}
      />
      {mode === 'area' && (
        <>
          <Layer
            id="measurements-area"
            source="measurement-polygon"
            type="fill"
            paint={{
              'fill-color': '#3bb2d0',
              'fill-opacity': 0.5,
            }}
          />
          <Layer
            id="measurements-area-label"
            source="measurement-centroid"
            type="symbol"
            paint={{
              'text-color': '#137791',
            }}
            layout={{
              'text-field': ['concat', ['get', 'area'], ' sq.m'],
              'text-size': 12,
            }}
          />
        </>
      )}
      {mode === 'length' && (
        <Layer
            id="measurements-length-label"
            source="measurement-linestring"
            type="symbol"
            paint={{
              'text-color': '#137791',
            }}
            layout={{
              'text-field': ['concat', ['get', 'length'], ' m'],
              'text-size': 12,
              'symbol-placement': 'line',
              'text-anchor': 'bottom',
            }}
          />
      )}
      <Layer
        id="measurements-nodes"
        source="measurement-nodes"
        type="circle"
        paint={{
          'circle-color': '#2189a3',
          'circle-radius': 3,
        }}
      />
    </>
  );
}

import {
  StatsRow,
  Fonts,
  ChartContainer,
  CategoryLegend,
  Text
} from '@ui';
import { LadDemandQuery } from 'generated';
import { Bar, BarChart, BarLabel, BarSeries, LinearYAxis } from 'reaviz';

export default function Population({ data }:{ data?: LadDemandQuery}) {
  
  const categoryData = [
    {
      key: '2016',
      data: data?.pop?.d9_pop_total_2016
    },
    {
      key: '2017',
      data: data?.pop?.d9_pop_total_2017
    },
    {
      key: '2018',
      data: data?.pop?.d9_pop_total_2018
    },
    {
      key: '2019',
      data: data?.pop?.d9_pop_total_2019
    },
    {
      key: '2020',
      data: data?.pop?.d6_d9_pop_total_2020
    },
    {
      key: '2021',
      data: data?.pop?.d9_pop_total_2021
    },
    {
      key: '2022',
      data: data?.pop?.d9_pop_total_2022
    },    
    {
      key: '2023',
      data: data?.pop?.d9_pop_total_2023
    },
    {
      key: '2024',
      data: data?.pop?.d9_pop_total_2024
    },
    {
      key: '2025',
      data: data?.pop?.d9_pop_total_2025
    }
  ]

  const min = Math.min(...(categoryData.map(v=>v.data) as number[])) * 0.8;
  const max = Math.max(...(categoryData.map(v=>v.data) as number[])) * 1.2; 

  const modifiedCategoryData = categoryData.map(v=>({
    ...v,
    data: [min, v.data]
  }))


  return (
    <StatsRow>
      <div>
        <Fonts.Header>Population</Fonts.Header>
        <ChartContainer>
          <BarChart
            style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
            width={500}
            height={250}
            data={modifiedCategoryData}
            yAxis={<LinearYAxis
            domain={[min,max]}
            />}
            series={
              <BarSeries
                isCategorical
                type='standard'
                tooltip={null}
                bar={
                  <Bar
                    gradient={null}
                    color="#34c3ca"
                    tooltip={null}
                    label={
                      <BarLabel 
                        {...{} as any} 
                        fill="#34c3ca" 
                        position="top" 
                        fontSize={10}
                      />}
                    style={(data:any) => {
                      if (Number(data.key)<=2020) {
                        return {
                          fill: '#34c3ca'
                        };
                      }else{
                        return {
                          fill: '#c4c4c4'
                        };
                      }
                    }}
                  />
                }
              />
            }
          />
          <CategoryLegend legends={[
            { label:'Population (actual) Table 426', color: '#34c3ca' },
            { label:'Population (forecast) Table 426', color: '#c4c4c4' }
          ]} position='absolute' right='2rem' top='1rem' />
          <Text customColor='grey' position='absolute' left='-4.5rem !important' top='90px !important' transform='rotate(-90deg)'># people</Text>

        </ChartContainer>
      </div>
    </StatsRow>
  );
}

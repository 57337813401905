import { PositionsProps } from "@material-ui/system";
import { FlexProps, LayoutProps, SpaceProps } from "styled-system";
import { LegendItem, LegendWrapper, LineSnippet, RectSnippet, Snippet } from "./styles";
import { Text } from '@ui';

type LegendProps = {
  label: string;
  rect?: Partial<SVGRectElement['style']>,
}

export function PatternLegend({
  legends,
  ...props
}: {
  legends: LegendProps[]
}&SpaceProps&LayoutProps&PositionsProps&FlexProps ){

  return <LegendWrapper {...props}>{
    legends.map(({label, rect}, index) => {
      return <LegendItem key={index}>
        <RectSnippet {...(rect as any)} />
        <Text customSize='t5'>{label}</Text>
      </LegendItem>
    })
  }</LegendWrapper>
}

import SigninForm from './Forms/Signin';
import SignupForm from './Forms/Signup';
import ForgotPasswordForm from './Forms/ForgotPassword';
import VerifyEmailForm from './Forms/VerifyEmail';
import ResetLinkSentForm from './Forms/ResetLinkSent';
import AccountVerifiedForm from './Forms/AccountVerified';
import NewPasswordForm from './Forms/NewPassword';
import ActivateAccountForm from './Forms/ActivateAccount';
import Loading from './Forms/Loading';

import { LandingLayout } from './styles';

export const Signin = () => (
  <LandingLayout>
    <SigninForm />
  </LandingLayout>
);
export const Signup = () => (
  <LandingLayout>
    <SignupForm />
  </LandingLayout>
);
export const ForgotPassword = () => (
  <LandingLayout>
    <ForgotPasswordForm />
  </LandingLayout>
);
export const VerifyEmail = () => (
  <LandingLayout>
    <VerifyEmailForm />
  </LandingLayout>
);
export const ResetLinkSent = () => (
  <LandingLayout>
    <ResetLinkSentForm />
  </LandingLayout>
);
export const AccountVerified = () => (
  <LandingLayout>
    <AccountVerifiedForm />
  </LandingLayout>
);
export const NewPassword = () => (
  <LandingLayout>
    <NewPasswordForm />
  </LandingLayout>
);
export const ActivateAccount = () => (
  <LandingLayout>
    <ActivateAccountForm />
  </LandingLayout>
);
export const LoadingScreen = () => (
  <LandingLayout>
    <Loading />
  </LandingLayout>
);

import {
  StatsRow,
  Fonts,
  ChartContainer,
  CategoryLegend,
  Text
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Bar, LinearAxisTickLabel, LinearAxisTickSeries, LinearYAxis, StackedBarChart } from 'reaviz';
import { format } from 'd3-format'
import { useMemo } from 'react';
import { StackedBarSeries } from 'misc/reaviz/StackedBarSeries';

const formatter = format('.2~s')

export default function TotalDwellings({ data }:{ data?: LadSupplyQuery}) {

  const categoryData = useMemo(()=>
  [
    {
      key: '2016',
      data: [
        {
          key: 'Existing units',
          data: data?.lad?.supply?.s2_existing_2016
        },
        {
          key: 'New units',
          data: data?.lad?.supply?.s2_new_2016
        }
      ]
    },
    {
      key: '2017',
      data: [
        {
          key: 'Existing units',
          data: data?.lad?.supply?.s2_existing_2017
        },
        {
          key: 'New units',
          data: data?.lad?.supply?.s2_new_2017
        }
      ]
    },
    {
      key: '2018',
      data: [
        {
          key: 'Existing units',
          data: data?.lad?.supply?.s2_existing_2018
        },
        {
          key: 'New units',
          data: data?.lad?.supply?.s2_new_2018
        }
      ]
    },
    {
      key: '2019',
      data: [
        {
          key: 'Existing units',
          data: data?.lad?.supply?.s2_existing_2019
        },
        {
          key: 'New units',
          data: data?.lad?.supply?.s2_new_2019
        }
      ]
    },
    {
      key: '2020',
      data: [
        {
          key: 'Existing units',
          data: data?.lad?.supply?.s2_existing_2020
        },
        {
          key: 'New units',
          data: data?.lad?.supply?.s2_new_2020
        }
      ]
    },
    {
      key: '2021',
      data: [
        {
          key: 'Forecast units',
          data: data?.lad?.supply?.s2_projected_2021
        }
      ]
    },
    {
      key: '2022',
      data: [
        {
          key: 'Forecast units',
          data: data?.lad?.supply?.s2_projected_2022
        }
      ]
    },
    {
      key: '2023',
      data: [
        {
          key: 'Forecast units',
          data: data?.lad?.supply?.s2_projected_2023
        }
      ]
    },
    {
      key: '2024',
      data: [
        {
          key: 'Forecast units',
          data: data?.lad?.supply?.s2_projected_2024
        }
      ]
    },
    {
      key: '2025',
      data: [
        {
          key: 'Forecast units',
          data: data?.lad?.supply?.s2_projected_2025
        }
      ]
    }
  ],
  [data])

  const min = useMemo(()=>
    Math.min(
    ...(categoryData
        .map(v=>v.data
          .reduce((agg, d)=>d.data+agg, 0)
        ) as number[])) * 0.8,
    [categoryData])
        
  const max = useMemo(()=>
    Math.max(
    ...(categoryData
        .map(v=>v.data
          .reduce((agg, d)=>d.data+agg, 0)
        ) as number[])) * 1.2,
    [categoryData])

  const modifiedCategoryData = useMemo(
    ()=>categoryData.map(v=>({
      ...v,
      data: v.data.map((d,i)=>i===0?(
        {
          ...d,
          data: d.data - min
        }
      ):d)
    }))
  ,[categoryData, min])

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Total Number of Dwellings</Fonts.Header>
        <ChartContainer>
          <StackedBarChart
            width={500}
            height={250}
            data={modifiedCategoryData}
            yAxis={
              <LinearYAxis 
                tickSeries={
                  <LinearAxisTickSeries
                    label={
                      <LinearAxisTickLabel 
                        angle={0}
                        align="start"
                        textAnchor='end'
                        orientation='vertical'
                        position='end'
                        padding={{
                          alongAxis: -8,
                          fromAxis: -10
                        }}
                        format={(v:number)=>`${formatter(v + min)}`} />
                    }/>}
                domain={[0, max - min]} />}  
            series={
              <StackedBarSeries
                tooltip={null}
                bar={
                  <Bar
                    gradient={null}
                    tooltip={null}
                    style={(data:any) => {
                      if(data.x.includes('Existing')){
                        return {
                          fill: '#34c3ca',
                          key: data.key + data.x
                        };
                      }else if(data.x.includes('New')){
                        return {
                          fill: '#e141e4',
                          key: data.key + data.x
                        };
                      }else{
                        return {
                          fill: '#c4c4c4',
                          key: data.key + data.x
                        };                       
                      }
                    }}
                  />
                }
              />
            }
          />
          <CategoryLegend legends={[
            { label:'New units (LT-122)', color: '#e141e4' },
            { label:'Existing units (LT-125 + 406)', color: '#34c3ca' },
            { label:'Forecasted units (406)', color: '#c4c4c4' }
          ]} position='absolute' right='2rem' top='1rem' />
          <Text customColor='grey' position='absolute' left='-3rem !important' top='90px !important' transform='rotate(-90deg)'># units</Text>

        </ChartContainer>
      </div>
    </StatsRow>
  );
}

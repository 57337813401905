import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Layer,
  NavigationControl,
  GeolocateControl,
} from '@urbica/react-map-gl';
import { useRecoilState } from 'recoil';
import { atoms, ExternalMapContext } from 'misc';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate, useParams } from 'react-router-dom';
import { FillPaint, LinePaint, LngLatLike } from 'mapbox-gl';
import { useMacroRangesQuery, useFeatureLazyQuery } from 'generated';
import centroid from '@turf/centroid';
import { dataLayers } from 'misc/recoil/atoms';
// import { isNumber } from '@turf/turf';

export default function Boundaries() {
  const [hovered, setHovered] = useRecoilState(atoms.hovered);
  const [selected, setSelected] = useRecoilState(atoms.selected);
  const [viewport, setViewport] = useRecoilState(atoms.viewport);
  const [mapLayers] = useRecoilState(atoms.mapLayers);
  const [countriesMode] = useRecoilState(atoms.countriesMode);
  const [dataDomains, setDataDomains] = useRecoilState(atoms.dataDomains);
  // const [dataLayers, setDataLayers] = useRecoilState(atoms.dataLayers);
  const navigate = useNavigate();
  const { data: macroRanges } = useMacroRangesQuery();
  const {
    countryCode,
    ladCode,
    tab = 'demand',
  } = useParams<{ countryCode?: string; ladCode?: string; tab?: string }>();
  const [getFeature] = useFeatureLazyQuery();
  
  // const [countryZoomLevel, setCountryZoomLevel] = useState(false); //true when zoomed out to country level 
  // useEffect(() => {
  //   if (viewport.zoom < 6 )
  //     setCountryZoomLevel(true);
  // }, [viewport.zoom]);
  useEffect(() => {
    (async () => {
      if (!selected && ladCode) {
        const response = await getFeature({ variables: { lad: ladCode } });
        const feature = response.data?.lad?.[0];

        if (feature) {
          setSelected({ id: feature.id, properties: feature });

          const lad = [centroid(feature.geom).geometry.coordinates[0], centroid(feature.geom).geometry.coordinates[1]]  as LngLatLike;

          if (map && lad) {
            const offset = 10;
            // console.log('Fly to lad', lad)

            map.flyTo({
              center: lad,
              ...(viewport.zoom < 10.1 ? { zoom: 10.1 } : {}),
              padding: {
                top: offset,
                bottom: offset,
                left: offset,
                right: 600 + offset,
              },
            });
          }
        }
      }
    })();
  }, [selected]);

  const macroPaint: FillPaint = useMemo(
    () =>
      {
        const min= dataDomains[countriesMode]?.min;
        const max= dataDomains[countriesMode]?.max;
        return (min!==undefined && max!==undefined )?
         {
            'fill-opacity':1,
            'fill-color': [
              'interpolate',
              ['linear'],
              ['coalesce', ['feature-state', countriesMode], 0],
              min,
              '#ffffff',
              max,
              '#006d75',
            ],
          }: {
            'fill-opacity': 1,
            'fill-color': [
              'interpolate',
              ['linear'],
              ['coalesce', ['get', countriesMode], 0],
              0,
              '#ffffff',
              0.1,
              '#006d75',
            ],
          };
          
        },
    [countriesMode, dataDomains]
  );

  useEffect(() => {
    const domains: any = {};
    dataLayers.forEach((layer) => {
      let max=undefined;
      let min=undefined;
      let isPercentage = false;
      // console.log('domain test', macroRanges?.macro_aggregate.aggregate?.max?.pop_growth_10y)
      switch(layer.slug){
        case 'pop_growth_10y':
        default:
          max = macroRanges?.macro_aggregate.aggregate?.max?.pop_growth_10y ;
          min = macroRanges?.macro_aggregate.aggregate?.min?.pop_growth_10y ;
          break;
        case 'pop_growth_cagr_10y':
          max = macroRanges?.population_country_aggregate?.aggregate?.max?.pop_growth_cagr_10y;
          min = macroRanges?.population_country_aggregate?.aggregate?.min?.pop_growth_cagr_10y;
          isPercentage = true;
          break;
        case 'urban_growth_rate':
          max = macroRanges?.population_country_aggregate?.aggregate?.max?.urban_growth_rate_2020_2025;
          min = macroRanges?.population_country_aggregate?.aggregate?.min?.urban_growth_rate_2020_2025;
          break;
        case 'unemployment':
          max = macroRanges?.ma_country_aggregate?.aggregate?.max?.unemployment ;
          min = macroRanges?.ma_country_aggregate?.aggregate?.min?.unemployment ;
          break;
        case 'avg_household_size':
          max = macroRanges?.household_country_aggregate?.aggregate?.max?.size_avg;
          min = macroRanges?.household_country_aggregate?.aggregate?.min?.size_avg;
          break;
        case 'gdp_per_capita':
          max = macroRanges?.gdp_country_aggregate?.aggregate?.max?.gdp_percapita_current_usd_2020 ;
          min = macroRanges?.gdp_country_aggregate?.aggregate?.min?.gdp_percapita_current_usd_2020;
          break;

      }
      if (max!==undefined && min!==undefined){
        // console.log('domain:', layer.slug, min, max)
        domains[layer.slug] = {min: min, max: max, isPercentage: isPercentage};
      }
    });
    setDataDomains(domains);
    // console.log('dataDomains', domains);
    
  }, [macroRanges]);

  const linePaint = {
    'line-color': '#bbb',
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      5,
      ['boolean', ['feature-state', 'hover'], false],
      2.5,
      0.5,
    ],
  } as LinePaint;

  const fillPaint = {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      'rgba(183,248,246,0.3)',
      ['boolean', ['feature-state', 'hover'], false],
      'rgba(183,248,246,0.1)',
      selected ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0)',
    ],
  } as FillPaint;

  const { map } = useContext(ExternalMapContext);

  return (
    <>
      <NavigationControl showCompass showZoom position="bottom-right" />
      <GeolocateControl position="bottom-right" />
      {mapLayers?.satellite && (
        <Layer
          {...{
            id: 'satellite',
            source: 'mapbox-satellite',
            'source-layer': 'mapbox-satellite-layer',
            type: 'raster',
            paint: {
              'raster-opacity': 1,
            },
            // before: 'road-labels',
          }}
        />
      )}
      {mapLayers?.green && (
        <Layer
          {...{
            id: 'green',
            source: 'mapbox-streets',
            'source-layer': 'landuse',
            type: 'fill',
            //filter parks and woods only
            filter: ['in', 'class', 'park', 'wood', 'pitch'],

            paint: {
              'fill-color': 'rgba(130,252,138, .3)',
            },
          }}
        />
      )}
      {mapLayers?.terrain && (
        <>
          <Layer
            {...{
              id: 'terrain-contour',
              source: 'mapbox-terrain',
              'source-layer': 'contour',
              type: 'line',
              // layout: {}
              paint: {
                'line-color': '#ccc',
              },
            }}
          />
          <Layer
            {...{
              id: 'terrain-hillshade',
              source: 'mapbox-terrain',
              'source-layer': 'hillshade',
              type: 'fill',
              paint: {
                'fill-color': 'rgba(90, 91, 97, 0.1)',
                // 'fill-outline-color': 'rgba(66,100,251, 1)',
              },
            }}
          />
          <Layer
            {...{
              id: 'terrain-labels',
              source: 'mapbox-terrain',
              'source-layer': 'contour',
              type: 'symbol',
              layout: {
                visibility: 'visible',
                'symbol-placement': 'line',
                'text-field': ['concat', ['to-string', ['get', 'ele']], 'm'],
                'text-size': 14,
              },
              paint: {
                'icon-color': '#bbb',
                'icon-halo-width': 1,
                'text-color': '#bbb',
                'text-halo-width': 1,
              },
            }}
          />
        </>
      )}
      {mapLayers?.buildings && (
        <Layer
          {...{
            id: 'add-3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 13,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height'],
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height'],
              ],
              'fill-extrusion-opacity': 0.6,
            },
          }}
        />
      )}

      {mapLayers?.countriesData && (
        <Layer
        id="country-fill"
        source="country_extended"
        source-layer="public.country_extended"
        type="fill"
        paint={macroPaint}
        before="road-label"
        // visibility={viewport.zoom < 6 ? 'visible' : 'none'}
        maxzoom={6} 
      />
        // <Layer
        //   id="countries-data"
        //   source="country_extended"
        //   source-layer="public.country_extended"
        //   type="fill"
        //   paint={macroPaint}
        //   before="road-label"
        //   maxzoom={6}
        // />
      )}
      {mapLayers?.boundaries && (
        <>
          <Layer
            id="countries-poly"
            source="country_extended"
            source-layer="public.country_extended"
            type="fill"
            onHover={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                setHovered({ id: feature.id, properties: feature.properties });
              }
            }}
            onLeave={(e: any) => setHovered(undefined)}
            onClick={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                //setSelected({id:feature.id, properties:feature.properties})
                navigate('/market/' + feature.properties.iso);
              }
            }}
            paint={fillPaint}
            maxzoom={6}
          />
          <Layer
            id="countries-line"
            source="country_extended"
            source-layer="public.country_extended"
            type="line"
            paint={linePaint}
            maxzoom={3}
          />

          <Layer
            id="ctryuk-poly"
            source="ctryuk"
            source-layer="public.ctryuk"
            type="fill"
            onHover={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                setHovered({ id: feature.id, properties: feature.properties });
              }
            }}
            onLeave={(e: any) => setHovered(undefined)}
            onClick={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                const ctryuk = [centroid(feature).geometry.coordinates[0], centroid(feature).geometry.coordinates[1]] as LngLatLike;
                // console.log('ctryuk', ctryuk);

                if (map && ctryuk) {
                  const offset = 10;
                  // console.log('Fly to ctryuk')
                  map.flyTo({
                    center: ctryuk,
                    zoom: 6.1,
                    padding: {
                      top: offset,
                      bottom: offset,
                      left: offset,
                      right: 600 + offset,
                    },
                  });
                }
              }
            }}
            paint={fillPaint}
            minzoom={3}
            maxzoom={6}
          />
          <Layer
            id="ctry-line"
            source="ctryuk"
            source-layer="public.ctryuk"
            type="line"
            paint={linePaint}
            minzoom={3}
            maxzoom={6}
          />

          <Layer
            id="eer-poly"
            source="eer"
            source-layer="public.eer"
            type="fill"
            onHover={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                setHovered({ id: feature.id, properties: feature.properties });
              }
            }}
            onLeave={(e: any) => setHovered(undefined)}
            onClick={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                const eer = [centroid(feature).geometry.coordinates[0], centroid(feature).geometry.coordinates[1]] as LngLatLike;
                // console.log('eer', eer);
                if (map && eer) {
                  const offset = 10;
                  // console.log('Fly to eer')
                  map.flyTo({
                    center: eer,
                    zoom: 10.1,
                    padding: {
                      top: offset,
                      bottom: offset,
                      left: offset,
                      right: 600 + offset,
                    },
                  });
                }
              }
            }}
            paint={fillPaint}
            minzoom={6}
            maxzoom={10}
          />
          <Layer
            id="eer-line"
            source="eer"
            source-layer="public.eer"
            type="line"
            paint={linePaint}
            minzoom={6}
            maxzoom={10}
          />
          {/**/}

          <Layer
            id="lad-poly"
            source="lad"
            source-layer="public.lad"
            type="fill"
            onHover={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                setHovered({ id: feature.id, properties: feature.properties });
              }
            }}
            onLeave={(e: any) => setHovered(undefined)}
            onClick={(e: any) => {
              const feature = e?.features?.[0];
              if (feature) {
                setSelected({ id: feature.id, properties: feature.properties });

                const lad = [centroid(feature).geometry.coordinates[0], centroid(feature).geometry.coordinates[1]] as LngLatLike;
                // console.log('lad', feature);
                if (map && lad) {
                  const offset = 10;
                  // console.log('Fly to lad', lad)

                  map.flyTo({
                    center: lad,
                    ...(viewport.zoom < 10.1 ? { zoom: 10.1 } : {}),
                    padding: {
                      top: offset,
                      bottom: offset,
                      left: offset,
                      right: 600 + offset,
                    },
                  });
                  console.log(countryCode);
                  if (countryCode) {
                    navigate(
                      `/market/${countryCode}/${feature.properties.lad17cd}/${tab}`
                    );
                  } else {
                    navigate(`/market/UK/${feature.properties.lad17cd}/${tab}`);
                  }
                }
              }
            }}
            paint={fillPaint}
            minzoom={10}
          />
          <Layer
            id="lad-line"
            source="lad"
            source-layer="public.lad"
            type="line"
            paint={linePaint}
            minzoom={10}
          />
        </>
      )}

      {mapLayers?.contours && (
        <Layer
          {...{
            id: 'contour',
            source: 'mapbox-terrain',
            'source-layer': 'contour',
            type: 'line',
            paint: {
              'line-color': '#000',
              'line-opacity': 0.3,
              'line-width': 2,
            },
          }}
        />
      )}
    </>
  );
}

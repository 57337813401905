import styled, { css } from 'styled-components/macro';

export const TabsContainer = styled.div`
  display: flex;
  height: 110px;
  background-color: #F0F0F0;
`,

Tab = styled.div<{selected?: boolean;}>`
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-right: 0.5px solid #D8D8D8;
  border-bottom: 0.5px solid #D8D8D8;
  text-align: center;
  text-transform: uppercase;
  &:first-child {
    border-left: 0.5px solid #D8D8D8;
  }
  :hover{
    background-color: #c5c5c522;
  }
  ${({selected}) => selected && 
    css`
      color: white;
      background-color: #35C8D1;
      :hover{
        background-color: #34c3ca;
      }
    `}
  >svg{
    flex: 1;
    ${({selected}) => selected ? 
    css`fill: #fff; stroke: #fff;`:
    css`fill: #35C8D1; stroke: #35C8D1;`}   
  }

`,

TabContent = styled.div`
  flex: 1 1 auto; 
  overflow: hidden; 
  padding: 0;
`
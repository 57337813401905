import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  CategoryLegend,
  Text
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { BarSeries } from 'misc/reaviz/BarSeries';
import { Bar, BarChart, BarLabel, Line, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';

export default function DwellingsTenure({ data }:{ data?: LadSupplyQuery}) {
  
  const categoryData = [
    {
      key: '2015',
      data: [
        {
          key: 'Private Registered Provider',
          data: data?.lad?.supply?.s1_prp_2015
        },
        {
          key: 'Private sector',
          data: data?.lad?.supply?.s1_private_2015
        },
        {
          key: 'Local Authority',
          data: data?.lad?.supply?.s1_localauth_2015
        },
        {
          key: 'Other public sector',
          data: data?.lad?.supply?.s1_opublic_2015
        }
      ]
    },
    {
      key: '2016',
      data: [
        {
          key: 'Private Registered Provider',
          data: data?.lad?.supply?.s1_prp_2016
        },
        {
          key: 'Private sector',
          data: data?.lad?.supply?.s1_private_2016
        },
        {
          key: 'Local Authority',
          data: data?.lad?.supply?.s1_localauth_2016
        },
        {
          key: 'Other public sector',
          data: data?.lad?.supply?.s1_opublic_2016
        }
      ]
    },
    {
      key: '2017',
      data: [
        {
          key: 'Private Registered Provider',
          data: data?.lad?.supply?.s1_prp_2017
        },
        {
          key: 'Private sector',
          data: data?.lad?.supply?.s1_private_2017
        },
        {
          key: 'Local Authority',
          data: data?.lad?.supply?.s1_localauth_2017
        },
        {
          key: 'Other public sector',
          data: data?.lad?.supply?.s1_opublic_2017
        }
      ]
    },
    {
      key: '2018',
      data: [
        {
          key: 'Private Registered Provider',
          data: data?.lad?.supply?.s1_prp_2018
        },
        {
          key: 'Private sector',
          data: data?.lad?.supply?.s1_private_2018
        },
        {
          key: 'Local Authority',
          data: data?.lad?.supply?.s1_localauth_2018
        },
        {
          key: 'Other public sector',
          data: data?.lad?.supply?.s1_opublic_2018
        }
      ]
    },
    {
      key: '2019',
      data: [
        {
          key: 'Private Registered Provider',
          data: data?.lad?.supply?.s1_prp_2019
        },
        {
          key: 'Private sector',
          data: data?.lad?.supply?.s1_private_2019
        },
        {
          key: 'Local Authority',
          data: data?.lad?.supply?.s1_localauth_2019
        },
        {
          key: 'Other public sector',
          data: data?.lad?.supply?.s1_opublic_2019
        }
      ]
    },
    {
      key: '2020',
      data: [
        {
          key: 'Private Registered Provider',
          data: data?.lad?.supply?.s1_prp_2020
        },
        {
          key: 'Private sector',
          data: data?.lad?.supply?.s1_private_2020
        },
        {
          key: 'Local Authority',
          data: data?.lad?.supply?.s1_localauth_2020
        },
        {
          key: 'Other public sector',
          data: data?.lad?.supply?.s1_opublic_2020
        }
      ]
    }
  ]

  const min = Math.min(
    ...(categoryData
        .map(v=>v.data
          .reduce((agg, d)=>d.data+agg, 0)
        ) as number[])) * 0.8;
        
  const max = Math.max(
    ...(categoryData
        .map(v=>v.data
          .reduce((agg, d)=>d.data+agg, 0)
        ) as number[])) * 1.2;

  const modifiedCategoryData = categoryData.map(v=>({
    ...v,
    data: v.data.map((d,i)=>i===0?(
      {
        ...d,
        data: d.data - min
      }
    ):d)
  }))

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Number of Dwellings by Tenure <Fonts.Subheader>(est.):</Fonts.Subheader></Fonts.Header>
        <ChartRow>
          <ChartContainer ml="2.5rem">
            <BarChart
              width={260}
              height={175}
              data={categoryData}
              // yAxis={<LinearYAxis
              //   domain={[min,max]}
              // />}
              series={
                <BarSeries
                  type='grouped'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      color="#34c3ca"
                      style={(data:any) => {
                        if(data.x.includes('Private Registered')){
                          return {
                            fill: '#86dee3'
                          };
                        }else if(data.x.includes('Private sector')){
                          return {
                            fill: '#e141e4'
                          };
                        }else if(data.x.includes('Local Authority')){
                          return {
                            fill: '#36c8d1'
                          };
                        }else{
                          return {
                            fill: '#a0a4a8'
                          };                       
                        }
                      }}
                    />
                  }
                />
              }
            />
            <Text customColor='grey' position='absolute' left='-3.5rem !important' top='60px !important' transform='rotate(-90deg)'># units</Text>

          </ChartContainer>
          <ChartContainer ml="0px">
            <CategoryLegend legends={[
              { label:'Local Authority (incl. owned by other LAs)', color: '#36c8d1' },
              { label:'Private Registered Provider', color: '#86dee3' },
              { label:'Other public sector', color: '#a0a4a8' },
              { label:<>Private sector (P)<sup>1</sup></> as any, color: '#e141e4' },
            ]} width='260px' ml='-1.5rem' />

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

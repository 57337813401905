import { useContext, useEffect } from 'react';
import mapboxgl, { Control } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ExternalMapContext } from 'misc';

export function GeocodeControl({
  position = 'top-right',
  accessToken,
}: {
  accessToken: string;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}) {
  const { map } = useContext(ExternalMapContext);

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken,
      mapboxgl: mapboxgl as any,
    });
    // if (map!==undefined)
    map?.addControl(geocoder as any, position);

    // const control = Control();
    //   control.onAdd = () => ;
    // control.onRemove = () => geocoder.onRemove();
    // if (map) 
    //   map.addControl(geocoder, position);

    return () => {
      map?.removeControl?.(geocoder as any);
    };
  }, [map, position]);

  return null;
}

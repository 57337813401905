import { random } from 'misc';
import { LineChart, LineSeries, Line, TooltipArea } from 'reaviz';

const getData = (r = 5) =>
  new Array(12).fill('').map((v, i) => ({
    key: new Date(new Date().setMonth(new Date().getMonth() - i)),
    data: (random(i + r) * 0.5 + 0.15) * 100000,
  }));

const getMultiData = (r = 1) =>
  new Array(2).fill('').map((v, i) => ({
    key: String((i + 1) * r),
    data: getData(i * r),
  }));

export default function Lines({ dimensions = 300, seed = 1 }) {
  return (
    <div style={{ marginTop: '2rem' }}>
      <LineChart
        width={250}
        height={200}
        series={
          <LineSeries
            type="grouped"
            tooltip={<TooltipArea disabled />}
            line={
              <Line
                strokeWidth={3}
                style={(data: any, i: number) => {
                  console.log(data, i);
                  if (
                    data &&
                    data.length &&
                    data[0] &&
                    data[0].key?.includes('1')
                  ) {
                    return {
                      strokeDasharray: '5',
                      stroke: '0.5',
                    };
                  }
                }}
              />
            }
            colorScheme={(v, i) => {
              return i === 1 ? '#e141e4' : '#35c8d1';
            }}
          />
        }
        data={getMultiData(seed)}
      />
    </div>
  );
}

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Project from '@assets/icons/project.jpg';

const CssFormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0,0,0,0.2)',
        borderRadius: '1rem',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0,0,0,0.3)',
        borderRadius: '1rem',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0,0,0,0.15)',
        borderRadius: '1rem',
      },
    },
  },
})(FormControl);

export default function Design() {
  const [tab, setTab] = useState<string>('strategy');
  const [project, setProject] = useState<string>('');
  const [pack, setPackage] = useState<string>('');

  return (
    <div
      style={{
        width: 'calc(100vw - 5rem)',
        flex: 'none',
        display: 'flex',
        backgroundColor: '#efefef',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderBottom: 'solid 1px #ccc',
        padding: '2rem',
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <Typography variant="h5" style={{ display: 'flex' }}>
        Generative Design & Massing
      </Typography>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <div>
          <CssFormControl
            size="small"
            variant="outlined"
            style={{
              backgroundColor: 'white',
              width: '15rem',
              borderRadius: '1rem',
              marginRight: '2rem',
            }}
          >
            <InputLabel id="project-label">
              Select Project / Prospect
            </InputLabel>
            <Select
              labelId="project-label"
              id="project"
              value={project}
              onChange={(e) => setProject(e.target.value as string)}
              label="Project"
              defaultValue="Project"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Default">Default</MenuItem>
            </Select>
          </CssFormControl>
        </div>
        <img
          src={Project}
          alt="Project"
          className="project"
          style={{ width: '5rem', objectFit: 'contain', position: 'relative' }}
        />
      </div>
    </div>
  );
}

import { Header, Wrapper, TextInfo, MailIcon } from './styles';
import { useRecoilState } from 'recoil';
import Signup from './Signup';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
  const [user] = useRecoilState(Signup.user);

  return (
    <div>
      <Wrapper>
        <MailIcon />
        <Header>Verify Your Email</Header>
        <TextInfo>
          Thanks for registering {user.options.userData.first_name}! We sent you
          an email {user.email && 'at ' + user.email} to confirm your email
          address before logging in. If you can't find it in your mailbox,
          please also check your spam folder.
        </TextInfo>
        <span>
          <div />
          <Link to="/auth/signin">Return to Sign in</Link>
        </span>
        <span>Copyright &copy; InsightsNow, 2023</span>
      </Wrapper>
    </div>
  );
}

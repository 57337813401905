import { Typography, IconButton } from '@material-ui/core';
import { Close, LayersOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';
import { Switch } from '@ui';
import { Name, ViewOptions } from './styles';
import { dataLayers } from 'misc/recoil/atoms';

function Row({ children = (<></>) as ReactNode }) {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '0.3rem',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        minHeight: '1.5rem',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
}

export default function SideBar() {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [, setDataSource] = useRecoilState(atoms.displayedDataSource);
  
  const defaultValue = dataLayers.reduce(
    (agg, v, i) => ({ ...agg, [v.slug]: i === 0 }),
    {}
  );
  const [check, setCheck] = useState<{ [key: string]: boolean }>(defaultValue);
  const [mode, setMode] = useRecoilState(atoms.countriesMode);

  useEffect(() => {
    // if nothing is checked:
    if (Object.values(check).every((v) => !v)) {
      setMode('');
      setDataSource(undefined);
    }
  }, [check]);

  

  return (
    <ViewOptions
      {...{ hovered, open }}
      onClick={() => {
        if (!open) setOpen(!open);
      }}
      onPointerEnter={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      {!open && <LayersOutlined style={{ pointerEvents: 'none' }} />}
      {open && (
        <div
          style={{
            display: 'flex',
            // position: 'absolute',
            // top: 0,
            // left: 0,
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '2rem',
            boxSizing: 'border-box',
            textAlign: 'left',
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            style={{ position: 'absolute', top: '1rem', right: '1rem' }}
          >
            <Close />
          </IconButton>
          <Typography
            variant="caption"
            style={{ marginTop: '0.5rem', color: '#969CBA', fontWeight: 800 }}
          >
            Global Datasets
          </Typography>
          {dataLayers.map((d, key) => (
            <Row {...{ key }}>
              <Name>{d.name}</Name>
              <Switch
                checked={check[d.slug] || false}
                onChange={() => {
                  setDataSource(d.source);
                  setMode(d.slug);
                  setCheck({
                    ...(Object.keys(check).reduce(
                      (agg, k) => ({ ...agg, [k as string]: false }),
                      {}
                    ) as typeof defaultValue),
                    [d.slug]: !check[d.slug],
                  });
                }}
              />
            </Row>
          ))}
        </div>
      )}
    </ViewOptions>
  );
}

import { StatsRow, Fonts, ChartContainer } from '@ui';
import { LadDemandQuery, useLadDemandQuery } from 'generated';
import { useParams } from 'react-router-dom';
import { PieArc, PieArcLabel, PieArcSeries, PieChart } from 'reaviz';

export default function GenerationSplit({ data }: { data?: LadDemandQuery }) {
  const categoryData = [
    {
      key: 'Gen Alpha',
      data: data?.pop?.d4_gen_alpha_2020_mid,
    },
    {
      key: 'Gen Z',
      data: data?.pop?.d4_gen_z_2020_mid,
    },
    {
      key: 'Millenials',
      data: data?.pop?.d4_gen_millenials_2020_mid,
    },
    {
      key: 'Gen X',
      data: data?.pop?.d4_gen_x_2020_mid,
    },
    {
      key: 'Boomers II',
      data: data?.pop?.d4_gen_boomers2_2020_mid,
    },
    {
      key: 'Boomers I',
      data: data?.pop?.d4_gen_boomers1_2020_mid,
    },
    {
      key: 'Pre & Post War',
      data: data?.pop?.d4_gen_prepostwar_2020_mid,
    },
  ];

  const min = Math.min(...(categoryData.map((v) => v.data) as number[])) * 0.8;
  const max = Math.max(...(categoryData.map((v) => v.data) as number[])) * 1.2;

  const modifiedCategoryData = categoryData.map((v) => ({
    ...v,
    data: [min, v.data],
  }));

  return (
    <StatsRow>
      <div>
        <Fonts.Header mt="-1rem">Generation Split:</Fonts.Header>
        <ChartContainer m="0px">
          <PieChart
            height={350}
            data={categoryData}
            series={
              <PieArcSeries
                // arc={<PieArc tooltip={null} />}
                padRadius={1}
                padAngle={1}
                colorScheme={[
                  '#7ed4d6',
                  '#17686e',
                  '#31bdc7',
                  '#78afb1',
                  '#31bdc7',
                  '#186267',
                  '#7db9cd',
                ]}
              />
            }
          />
        </ChartContainer>
      </div>
    </StatsRow>
  );
}

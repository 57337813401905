import {
  StatsRow,
  Text,
  ChartContainer,
  Title,
  Paper,
  Row
} from '@ui';
import { LadDemandQuery, LadIncomeQuery } from 'generated';
import { Bar, BarChart, BarLabel, BarSeries, LinearYAxis } from 'reaviz';

export default function MeanEquivalisedIncome({ data }:{ data?: LadIncomeQuery}) {
  
  const categoryData = [
    {
      key: 'Bottom',
      data: data?.lad?.aff_income?.disp_income_2017_2018_1
    },
    {
      key: '2nd',
      data: data?.lad?.aff_income?.disp_income_2017_2018_2
    },
    {
      key: '3rd',
      data: data?.lad?.aff_income?.disp_income_2017_2018_3
    },
    {
      key: '4th',
      data: data?.lad?.aff_income?.disp_income_2017_2018_4
    },
    {
      key: '5th',
      data: data?.lad?.aff_income?.disp_income_2017_2018_5
    },
    {
      key: '6th',
      data: data?.lad?.aff_income?.disp_income_2017_2018_6
    },
    {
      key: '7th',
      data: data?.lad?.aff_income?.disp_income_2017_2018_7
    },
    {
      key: '8th',
      data: data?.lad?.aff_income?.disp_income_2017_2018_8
    },
    {
      key: '9th',
      data: data?.lad?.aff_income?.disp_income_2017_2018_9
    },
    {
      key: 'Top',
      data: data?.lad?.aff_income?.disp_income_2017_2018_10
    },
  ]

  const min = Math.min(...(categoryData.map(v=>v.data) as number[])) * 0.8;
  const max = Math.max(...(categoryData.map(v=>v.data) as number[])) * 1.2; 

  const modifiedCategoryData = categoryData.map(v=>({
    ...v,
    data: [min, v.data]
  }))


  return (
    <StatsRow>
      <div>
        <Title h='h4' m='0' mb='2rem !important' bold>
          Mean equivalised household disposable income per decile ({data?.lad?.region})
        </Title>

        <ChartContainer ml='2.5rem'>
          <BarChart
            width={500}
            height={250}
            data={categoryData}
            yAxis={<LinearYAxis
            domain={[0,max]}
            />}
            series={
              <BarSeries
                isCategorical
                type='standard'
                tooltip={null}
                bar={
                  <Bar
                    gradient={null}
                    color="#34c3ca"
                    tooltip={null}
                    label={
                      <BarLabel 
                        {...{} as any} 
                        fill="#6f6f6f" 
                        position="top" 
                        fontSize={10}
                      />}
                    style={(data:any) => {
                      return {
                        fill: '#34c3ca'
                      };
                    }}
                  />
                }
              />
            }
          />
        <Text 
          customColor='grey' 
          position='absolute' 
          left='-6.5rem !important' 
          top='90px !important' 
          transform='rotate(-90deg)'>Disposable income, £</Text>

        </ChartContainer>
        <Title h='h4' m='0' mb='2rem !important' bold>
          Upfront Costs to Purchase a Residential Property, per house price decile
        </Title>
        <Text textAlign='start'>Deposit %:</Text>
        <Row>
          <Paper hoverable mr='2rem' p='0.8rem 3rem'>
            <Text customSize='t1' bold>10%</Text>
          </Paper>
          <Paper hoverable p='0.8rem 3rem'>
            <Text customSize='t1' bold customColor='grey'>Select Decile ▼</Text>
          </Paper>
        </Row>
        <Row m='2rem 0' justifyContent='space-between'>
          <Paper noStyles>
            <Text textAlign='start' mb='0.5rem'>Deposit Amount:</Text>
            <Title h='h2' m='0' bold>£ 1,368</Title>
          </Paper>
          <Paper noStyles>
            <Text textAlign='start' mb='0.5rem'>Stamp Duty Amount:</Text>
            <Title h='h2' m='0' bold>£ 868</Title>
          </Paper>
          <Paper noStyles>
            <Text textAlign='start' mb='0.5rem'>Total Savings Required:</Text>
            <Title h='h2' m='0' bold>£ 2,236</Title>
          </Paper>
        </Row>
      </div>
    </StatsRow>
  );
}

import RightSideMenu from './RightSideMenu';
import { useParams } from 'react-router-dom';
import CountryPanel from './CountryPanel';
import LadPanel from './LadPanel';

export default function Market() {
  const { countryCode, ladCode } =
    useParams<{ countryCode?: string; ladCode?: string }>();

  return (
    <>
      {!countryCode && <RightSideMenu />}
      {countryCode && !ladCode && <CountryPanel />}
      {countryCode && ladCode && <LadPanel />}
    </>
  );
}

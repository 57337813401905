import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as Lock } from '@assets/icons/lock.svg';
import { ReactComponent as Mail } from '@assets/icons/mail.svg';
import { ReactComponent as Check } from '@assets/icons/checkmark.svg';
import { ReactComponent as Rotate } from '@assets/icons/rotate.svg';
import MuiButton from '@material-ui/core/Button';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import { TextField } from '@ui';

export const Wrapper = styled.div`
    background-color: #ffffff;
    width: 480px;
    border-radius: 0.5rem;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 1rem;
      margin-bottom: 1rem;
      &:last-child {
        font-size: 14px;
        margin: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > a {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #a0a4a8;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `,
  IconStyle = css<{}>`
    flex: 0 0 89px;
    width: 89px;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(p) => p.theme.colors.accent};
    border-radius: 50%;
  `,
  LockIcon = styled.div.attrs({
    children: <Lock />,
  })`
    ${IconStyle}
  `,
  rotation = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `,
  LoadingIcon = styled.div.attrs({
    children: <Rotate />,
  })`
    ${IconStyle}
    >svg{
      fill: white;
      width: 60px;
      height: 60px;
      margin-top: 6px;
      transform-origin: 30px 26.5px;
      animation: ${rotation} 2s linear infinite;
    }
  `,
  MailIcon = styled.div.attrs({
    children: <Mail />,
  })`
    ${IconStyle}
  `,
  CheckIcon = styled.div.attrs({
    children: <Check />,
  })`
    ${IconStyle}
  `,
  Header = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 300;
    margin: 1rem 1rem 1rem 1rem;
  `,
  ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
  `,
  Button = styled(MuiButton).attrs({
    variant: 'contained',
    color: 'primary',
  })`
    flex: 0 0 auto;
    height: 72px;
    color: #ffffff;
    background-color: #35c8d1;
    margin: 0rem 0rem 0rem 0.5rem !important;
    &:first-child {
      flex: 1 1 auto;
      margin: 0rem 0rem 0rem 0rem !important;
      font-family: 'Poppings', sans-serif;
      font-weight: bold;
      font-size: 24px;
    }
  `,
  Snackbar = styled(MuiSnackbar).attrs({
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    autoHideDuration: 6000,
  })``,
  Alert = styled(MuiAlert).attrs({
    sx: { width: '100%' },
  })``,
  InputRow = styled.div`
    margin-top: 1rem;
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    > div:nth-child(2) {
      width: 2rem;
    }
  `,
  TextInput = styled(TextField)`
    border-radius: 5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: 'Poppins', sans-serif;
  `,
  TextInfo = styled.div`
    color: white;
    box-sizing: border-box;
    width: calc(100% + 80px);
    line-height: 2rem;
    margin-bottom: 3rem;
    padding: 2rem;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    background-color: ${(p) => p.theme.palette.primary.main};
  `;

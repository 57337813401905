import { auth } from 'misc';
import { useEffect, useState } from 'react';
import {
  ButtonRow,
  Header,
  LockIcon,
  Wrapper,
  Button,
  Snackbar,
  Alert,
  TextInput,
  InputRow,
} from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, atom } from 'recoil';

export default function Signup() {
  const [credentials, setCredentials] = useRecoilState(Signup.user);
  const [error, setError] = useState<any>();
  const [notification, setNotification] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    error && console.log(error);
  }, [error]);

  return (
    <>
      <div>
        <Wrapper>
          <LockIcon />
          <Header>Sign Up</Header>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.first_name}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      first_name: e.target.value,
                    },
                  },
                })
              }
              id="First name"
              placeholder="First name *"
            />
            <div />
            <TextInput
              required
              value={credentials.options.userData.last_name}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      last_name: e.target.value,
                    },
                  },
                })
              }
              id="Last name"
              placeholder="Last name *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.company.data.name}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      company: { data: { name: e.target.value } },
                    },
                  },
                })
              }
              id="Company"
              placeholder="Company *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.options.userData.role}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  options: {
                    userData: {
                      ...credentials.options.userData,
                      role: e.target.value,
                    },
                  },
                })
              }
              id="Designation / Role"
              placeholder="Designation / Role *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.email}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              id="Email address"
              placeholder="Email address *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              type="password"
              id="Password"
              placeholder="Password *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              onClick={() =>
                auth
                  .register(credentials)
                  .then((response) => {
                    console.log('resp', response);
                    navigate('/auth/verify-email');
                    if (!response.session) {
                      setNotification(
                        'Please, check your email (also, spam folder) and' +
                          'approve your registration.'
                      );
                    }
                  })
                  .catch((err) => {
                    setError(
                      err?.response?.data?.message?.replaceAll('"', '') ||
                        err.message
                    );
                  })
              }
            >
              SIGN UP
            </Button>
            {/* <Button onClick={() => auth.login({ provider: 'facebook' })}>
              <Facebook style={{ color: 'white' }} fontSize="large" />
            </Button>
            <Button onClick={() => auth.login({ provider: 'google' })}>
              <img src={google2} style={{height:'1.5rem'}} />
            </Button> */}
          </ButtonRow>
          <span>
            <div />
            <Link to="/auth/signin">Already have an account? Sign in</Link>
          </span>

          <span>Copyright &copy; InsightsNow, 2023</span>
        </Wrapper>
      </div>

      <Snackbar open={error} onClose={() => setError(undefined)}>
        <Alert onClose={() => setError(undefined)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={notification} onClose={() => setNotification(undefined)}>
        <Alert onClose={() => setNotification(undefined)} severity="success">
          {notification}
        </Alert>
      </Snackbar>
    </>
  );
}

Signup.user = atom({
  key: 'signup_user',
  default: {
    email: '',
    password: '',
    options: {
      userData: {
        first_name: '',
        last_name: '',
        company: { data: { name: '' } },
        role: '',
      },
    },
  },
});

import { useLadStatsQuery } from 'generated';
import { useSize } from 'misc';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  PanelHeader,
  PanelContainer,
  PanelWrapper,
  Attribution,
} from '@ui';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { scaleLinear } from 'd3-scale';
import NavPanel from './NavMenu';
import { TabContent } from './styles';
import Demand from './Demand';
import Supply from './Supply';
import Transactions from './Transactions';
import Planning from './Planning';
import Income from './Income';
import { useEffect, useState } from 'react';

export default function Panel() {
  const { countryCode, ladCode = '', tab = 'demand' } = useParams<{ countryCode: string, ladCode: string, tab: string }>();
  const { data:sourceData } = useLadStatsQuery({ variables: { ladCode } });
  const [data, setData] = useState(sourceData)
  
  useEffect(()=>{
    if(sourceData){
      setData(sourceData)
    }
  },[sourceData])
  
  const navigate = useNavigate();
  const { height, ref } = useSize();

  const colorScale = scaleLinear()
    .domain([0, 1])
    .range(['#83dbdf', '#17686e'] as any) as unknown as (n: number) => string;

  return (
    <PanelWrapper>
      <PanelContainer>
        <PanelHeader>
          <div>
            <h1 style={{marginBottom: '0rem', fontSize:'36px'}}>{data?.lad?.geo_name}</h1>
          </div>
          <CloseButton onClick={() => navigate('/market')} />
        </PanelHeader>
        <div style={{fontSize:'26px', fontWeight: 400}}>Local Area District</div>
        <div style={{fontSize:'18px', fontWeight: 400}}>{data?.lad?.id}</div>
      </PanelContainer>
      <NavPanel/>
      <TabContent {...{ref}}>
        <SimpleBar
          autoHide={false}
          style={{ boxSizing: 'border-box', maxHeight: height }}
        >
          <PanelContainer>
            {
              tab==='demand'?
                <Demand/>:
              tab==='supply'?
                <Supply/>:
              tab==='transactions'?
                <Transactions/>:
              tab==='planning'?
                <Planning/>:
                <Income/>
            }
          <svg style={{position:'fixed'}}>
            <defs>
              <pattern id="pattern-stripe" 
                width="4" height="4" 
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)">
                <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
              </pattern>
              <mask id="mask-stripe">
                <rect x="0" y="0" width="100vw"  height="100vh" fill="url(#pattern-stripe)" />
              </mask>      
            </defs>
          </svg>
          <Attribution>
            Source: <br/>
            ONS © Crown copyright, licensed under the Open Government Licence v.3.0
          </Attribution>
          </PanelContainer>
        </SimpleBar>
      </TabContent>
    </PanelWrapper>
  );
}

import { Typography } from '@material-ui/core';
import {
  DirectionsBus,
  LocalGroceryStore,
  LocalHospital,
  School,
  Train,
  Tram,
} from '@material-ui/icons';
import LineChart from './LineChart';

function Row({ prop = '', value = '' }) {
  return (
    <div style={{ display: 'flex', marginBottom: '1rem' }}>
      <Typography variant="body1" style={{ flex: '1 1 50%', color: '#999' }}>
        {prop}
      </Typography>
      <Typography variant="body1" style={{ flex: '1 1 50%' }}>
        {value}
      </Typography>
    </div>
  );
}

export default function Parcel() {
  return (
    <div
      style={{
        flex: '0 0 700px',
        height: '100vh',
        position: 'relative',
        right: 0,
        zIndex: 50,
        // padding: '4rem 2rem',
        boxSizing: 'border-box',
        borderLeft: '1px solid #ccc',
        boxShadow: '-5px 0px 5px rgba(0,0,0,0.1)',
        // overflow: 'auto',
        overflow: 'hidden'
      }}
      >
      <Typography
        variant="h4"
        style={{
          display: 'flex',
          position: 'absolute',
          zIndex: 1,
          paddingTop: '50%',
          // top: '50%',
          margin: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,.7)',//#eee when ready
          height: '100%',
          color: 'white',
          fontSize: '48px'
          // paddingRight: '2rem',
        }}
      >
        Coming soon...
      </Typography>
      <div style={{zIndex: 0, padding: '4rem 2rem', opacity: 0.4, filter: 'saturate(.2)' }}>
        <Typography variant="h5">Parcel Analysis</Typography>
        <Typography
          variant="h6"
          style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
        >
          3 Kensington High Street
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: '0 0 50%',
              padding: '1rem 0.5rem 1rem 0',
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
            }}
          >
            <Row prop="Description:" value="Established in 1877" />
            <Row prop="Plot Area:" value="1,495 sqm" />
            <Row prop="Description:" value="G + 3" />
          </div>
          <div
            style={{
              display: 'flex',
              flex: '0 0 50%',
              padding: '1rem 0 1rem 0.5rem',
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
            }}
          >
            <Row prop="Zoning:" value="Residential / Retail" />
            <Row prop="BUA:" value="895 sqm" />
          </div>
        </div>
        <Typography variant="h6">Transaction Data:</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: '0 0 50%',
              padding: '1rem 0 1rem 0.5rem',
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: '0 0 50%',
                padding: '1rem 0.5rem 1rem 0',
                boxSizing: 'border-box',
                position: 'relative',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h3" style={{ display: 'inline' }}>
                £1500
              </Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>
                per sqm
              </Typography>
            </div>
            <Typography
              variant="body2"
              style={{ display: 'inline', fontWeight: 'bold', width: '100%' }}
            >
              Average Sales Rate in Area
            </Typography>
            <LineChart seed={1} />
          </div>
          <div
            style={{
              display: 'flex',
              flex: '0 0 50%',
              padding: '1rem 0 1rem 0.5rem',
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: '0 0 50%',
                padding: '1rem 0 1rem 0.5rem',
                boxSizing: 'border-box',
                position: 'relative',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h3" style={{ display: 'inline' }}>
                £280
              </Typography>
              <Typography variant="body2" style={{ display: 'inline' }}>
                per sqm
              </Typography>
            </div>
            <Typography
              variant="body2"
              style={{ display: 'inline', fontWeight: 'bold', width: '100%' }}
            >
              Average Annual Rental Rate in Area
            </Typography>
            <LineChart seed={10} />
          </div>
        </div>
        <Typography variant="h6">Quality of space:</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: '0 0 50%',
              padding: '1rem 0 1rem 0.5rem',
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <Typography
              variant="body2"
              style={{ display: 'inline', fontWeight: 'bold' }}
            >
              Average Sales Rate in Area
            </Typography>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexBasis: '100%',
                boxSizing: 'border-box',
              }}
            >
              <School style={{ flex: '0 0 3rem' }} />
              <Typography variant="body2" style={{ flex: '1 0 5rem' }}>
                School
              </Typography>
              <Typography variant="body2" style={{ flex: '0 0 3rem' }}>
                250m
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexBasis: '100%',
                boxSizing: 'border-box',
              }}
            >
              <LocalHospital style={{ flex: '0 0 3rem' }} />
              <Typography variant="body2" style={{ flex: '1 0 5rem' }}>
                Hospital
              </Typography>
              <Typography variant="body2" style={{ flex: '0 0 3rem' }}>
                250m
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexBasis: '100%',
                boxSizing: 'border-box',
              }}
            >
              <LocalGroceryStore style={{ flex: '0 0 3rem' }} />
              <Typography variant="body2" style={{ flex: '1 0 5rem' }}>
                Grocery
              </Typography>
              <Typography variant="body2" style={{ flex: '0 0 3rem' }}>
                250m
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: '0 0 50%',
              padding: '1rem 0 1rem 0.5rem',
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <Typography
              variant="body2"
              style={{ display: 'inline', fontWeight: 'bold' }}
            >
              Average Annual Rental Rate in Area
            </Typography>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexBasis: '100%',
                boxSizing: 'border-box',
              }}
            >
              <DirectionsBus style={{ flex: '0 0 3rem' }} />
              <Typography variant="body2" style={{ flex: '1 0 5rem' }}>
                Bus
              </Typography>
              <Typography variant="body2" style={{ flex: '0 0 3rem' }}>
                250m
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexBasis: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Tram style={{ flex: '0 0 3rem' }} />
              <Typography variant="body2" style={{ flex: '1 0 5rem' }}>
                Tram
              </Typography>
              <Typography variant="body2" style={{ flex: '0 0 3rem' }}>
                250m
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexBasis: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Train style={{ flex: '0 0 3rem' }} />
              <Typography variant="body2" style={{ flex: '1 0 5rem' }}>
                Train
              </Typography>
              <Typography variant="body2" style={{ flex: '0 0 3rem' }}>
                250m
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

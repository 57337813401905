import { createClient } from 'nhost-js-sdk';

const origin = window.location.origin;
export const BACKEND_ENDPOINT =
  origin === 'http://localhost:3000'
    ? 'https://auth.insightsnow.co.uk/dev'
    : 'https://auth.insightsnow.co.uk/v1';

export const { auth, storage } = createClient({
  baseURL: BACKEND_ENDPOINT,
});

export const GQL_ENDPOINT = `https://api.insightsnow.co.uk/v1/graphql`;

export * from './NhostApolloProvider';

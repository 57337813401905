import { useEffect, useState } from 'react';
import {
  Header,
  LoadingIcon,
  LockIcon,
  Wrapper,
} from './styles';

export default function Signin() {

  return (
    <>
      <div>
        <Wrapper>
          <LoadingIcon />
          <Header>Loading...</Header>
        </Wrapper>
      </div>
    </>
  );
}

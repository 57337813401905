import {
  StatsRow,
  Fonts,
  ChartContainer,
  Text,
  CategoryLegend,
  PatternLegend
} from '@ui';
import { LadTransactionsQuery } from 'generated';
import { Bar, LinearYAxis, LinearYAxisTickLabel, LinearYAxisTickSeries, StackedBarChart } from 'reaviz';
import { useMemo, useRef } from 'react';
import { StackedBarSeries } from 'misc/reaviz/StackedBarSeries';
import { format } from 'd3-format'

const formatter = format('.2~s')

export default function NumberResidentialSales({ data }:{ data?: LadTransactionsQuery}) {

  const categoryData = useMemo(()=>
  [
    {
      key: '2016',
      data: [
        {
          key: 'Detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_detached_2016
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_detached_2016
            }
          ]
        },
        {
          key: 'Semi-detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_semidetached_2016
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_semidetached_2016
            }
          ]
        },
        {
          key: 'Terraced',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_terraced_2016
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_terraced_2016
            }
          ]
        },
        {
          key: 'Flats / maisonettes',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_flats_2016
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_flats_2016
            }
          ]
        }
      ]
    },
    {
      key: '2017',
      data: [
        {
          key: 'Detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_detached_2017
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_detached_2017
            }
          ]
        },
        {
          key: 'Semi-detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_semidetached_2017
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_semidetached_2017
            }
          ]
        },
        {
          key: 'Terraced',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_terraced_2017
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_terraced_2017
            }
          ]
        },
        {
          key: 'Flats / maisonettes',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_flats_2017
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_flats_2017
            }
          ]
        }
      ]
    },
    {
      key: '2018',
      data: [
        {
          key: 'Detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_detached_2018
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_detached_2018
            }
          ]
        },
        {
          key: 'Semi-detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_semidetached_2018
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_semidetached_2018
            }
          ]
        },
        {
          key: 'Terraced',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_terraced_2018
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_terraced_2018
            }
          ]
        },
        {
          key: 'Flats / maisonettes',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_flats_2018
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_flats_2018
            }
          ]
        }
      ]
    },
    {
      key: '2019',
      data: [
        {
          key: 'Detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_detached_2019
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_detached_2019
            }
          ]
        },
        {
          key: 'Semi-detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_semidetached_2019
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_semidetached_2019
            }
          ]
        },
        {
          key: 'Terraced',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_terraced_2019
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_terraced_2019
            }
          ]
        },
        {
          key: 'Flats / maisonettes',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_flats_2019
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_flats_2019
            }
          ]
        }
      ]
    },
    {
      key: '2020',
      data: [
        {
          key: 'Detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_detached_2020
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_detached_2020
            }
          ]
        },
        {
          key: 'Semi-detached',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_semidetached_2020
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_semidetached_2020
            }
          ]
        },
        {
          key: 'Terraced',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_terraced_2020
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_terraced_2020
            }
          ]
        },
        {
          key: 'Flats / maisonettes',
          data: [
            {
              key: 'Existing units',
              data: data?.lad?.transaction?.t1_sales_existing_flats_2020
            },
            {
              key: 'Newly built units',
              data: data?.lad?.transaction?.t1_sales_new_flats_2020
            }
          ]
        }
      ]
    }
  ],
  [data])
      

  const ref = useRef<HTMLDivElement>(null)
  const max = useMemo(()=>
  Math.max(
  ...(categoryData
      .map(v=> Math.max(
        ...v.data.map(
            (d)=> d.data.reduce((a,v)=>v.data + a, 0)
          )
        )
      ) as number[])) * 1.6,
  [categoryData])

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Number of Residential Sales by tenure by type</Fonts.Header>
        <ChartContainer {...{ref}}>

          {categoryData[0].data.map(({key},i)=>
            <StackedBarChart 
              key={key}    
              width={500}
              height={250}
              data={
                categoryData.map(v=>({
                  ...v,
                  data: v?.data?.find?.(d=>d.key===key)?.data || []
                }))}  
              gridlines={i===0?undefined:null}
              yAxis={
                <LinearYAxis 
                  domain={[0,max]}
                  tickSeries={
                    <LinearYAxisTickSeries label={
                      <LinearYAxisTickLabel format={(v)=>formatter(Number(v))}/>
                    }/>
                  }
                />}
              series={
                <StackedBarSeries
                  tooltip={null}
                  padding={0.85}
                  bar={
                    <Bar
                      gradient={null}
                      tooltip={null}                  
                      style={(data:any) => {
                        if(key==='Detached'){
                          if(data.x.includes('Existing')){
                            return {
                              fill: '#34c3ca',
                              transform: 'translate(-18px, 0px)',
                              key: data.key + data.x
                            };
                          }else{
                            return {
                              fill: '#34c3ca',
                              mask: 'url(#mask-stripe)',
                              transform: 'translate(-18px, 0px)',
                              key: data.key + data.x
                            };
                          }
                        }else if(key==='Semi-detached'){
                          if(data.x.includes('Existing')){
                            return {
                              fill: '#86dee3',
                              transform: 'translate(-6px, 0px)',
                              key: data.key + data.x
                            };
                          }else{
                            return {
                              fill: '#86dee3',
                              mask: 'url(#mask-stripe)',
                              transform: 'translate(-6px, 0px)',
                              key: data.key + data.x
                            };
                          }
                        }else if(key==='Terraced'){
                          if(data.x.includes('Existing')){
                            return {
                              fill: '#a0a4a8',
                              transform: 'translate(6px, 0px)',
                              key: data.key + data.x
                            };
                          }else{
                            return {
                              fill: '#a0a4a8',
                              mask: 'url(#mask-stripe)',
                              transform: 'translate(6px, 0px)',
                              key: data.key + data.x
                            };
                          }
                        }else{
                          if(data.x.includes('Existing')){
                            return {
                              fill: '#e141e4',
                              transform: 'translate(18px, 0px)',
                              key: data.key + data.x
                            };
                          }else{
                            return {
                              fill: '#e141e4',
                              mask: 'url(#mask-stripe)',
                              transform: 'translate(18px, 0px)',
                              key: data.key + data.x
                            };
                          }
                        }
                      }}
                    />
                  }
                />
              }
            />)}
          <CategoryLegend legends={[
            { label:'Detached', color: '#34c3ca' },
            { label:'Semi-detached', color: '#86dee3' },
            { label:'Terraced', color: '#a0a4a8' },
            { label:'Flats / maisonettes', color: '#e141e4' }
          ]} position='absolute' left='4rem' top='1rem' />
          <Text position='absolute' left='13rem' top='1rem' style={{textDecoration:'underline'}}>Pattern:</Text>
          <PatternLegend legends={[
            { label:'Newly built units', rect:{ fill: '#5f6275', mask: 'url(#mask-stripe)' } },
            { label:'Existing units', rect:{ fill: '#5f6275' } }
          ]} position='absolute' left='13.1rem' top='1.2rem !important' />

          <Text customColor='grey' position='absolute' left='-3rem !important' top='90px !important' transform='rotate(-90deg)'># units</Text>

        </ChartContainer>
      </div>
    </StatsRow>
  );
}

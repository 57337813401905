import { useRef } from "react"
import { useSpring, a } from "react-spring"

type DougnutProps = {
  data: {
    key: string|number
    data: number
  }[],
  width: number
  height: number
  thikness: number,
  threshold?: number
}

export function Donut({ data, width, height, thikness, threshold }:DougnutProps){

  const sum = data.reduce((acc, { data }) => acc + data, 0)
  const colors = ['#36c8d1', '#e141e4', '#8ad0d5', '#e68de7', '#6a3d9a', '#cab2d6']
  const radius = (width - thikness) / 2 
  const perimeter = 2 * Math.PI * radius

  return <svg width={width} height={height} style={{ transform: 'rotate(-90deg)', position:'relative' }}>
    {data.map(({key, data}, index, arr) => {
      const length = data / sum * perimeter
      const offset = ((arr
          .slice(0, index)
          .reduce((a, d) => a + d.data, 0) / sum) * perimeter)

      return <>
        <Segment {...{  
          key: index,
          index, 
          width, 
          height, 
          thikness,
          length,
          offset, 
          color: colors[index],
          value: data,
          percent: data / sum * 100,
        }}/>
      </>
    })}
    {data.map(({key, data}, index, arr) => {
      const length = data / sum * perimeter
      const offset = ((arr
          .slice(0, index)
          .reduce((a, d) => a + d.data, 0) / sum) * perimeter)

      return <>
        <Label {...{  
          key: index,
          index, 
          width, 
          height, 
          thikness,
          length,
          offset, 
          color: colors[index],
          value: data,
          percent: data / sum * 100,
          threshold
        }}/>
      </>
    })}
  </svg>
}

type SegmentProps = {
  index: number
  width: number
  height: number
  thikness: number
  length?: number
  offset?: number
  color: string
  value?: number
  percent?: number,
  threshold?: number
}

function Segment({ 
  index, 
  width, 
  height, 
  thikness,
  length,
  offset, 
  color,
}:SegmentProps){
  
  const radius = (width - thikness) / 2 
  const perimeter = 2 * Math.PI * radius
  const ref = useRef<SVGCircleElement>(null)
    
  const spring = useSpring({
    length, offset, point: (offset||0) + ((length||0)/2)
  })
  
  return length? <>
    <a.circle
      ref={ref}
      key={index}
      cx={width/2}
      cy={height/2}
      r={radius}
      strokeDasharray={spring.length?.to(v=>`${v} ${perimeter - v}`)}
      strokeDashoffset={spring.offset?.to(v=>-v||0)}
      strokeWidth={thikness}
      fill="transparent"
      stroke={color}
    />
  </>: null
}

function Label({ 
  index, 
  width, 
  height, 
  thikness,
  length,
  offset, 
  threshold=2,
  value,
  percent
}:SegmentProps){
  
  const radius = (width - thikness) / 2 
  const perimeter = 2 * Math.PI * radius
  const ref = useRef<SVGCircleElement>(null)
    
  const spring = useSpring({
    length, offset, point: (offset||0) + ((length||0)/2)
  })
  
  return length? <>
    <a.circle
      ref={ref}
      key={index}
      cx={width/2}
      cy={height/2}
      r={radius}
      fill="transparent"
      stroke="transparent"
    />

    <a.text 
      x={spring?.point.to(v=>(ref.current?.getPointAtLength?.(v)?.x||0) )} 
      y={spring?.point.to(v=>(ref.current?.getPointAtLength?.(v)?.y||0) - 5 )} 
      fontWeight="bold"
      fontSize={10}
      transform="rotate(90)"
      transform-origin={spring?.point.to( v=>{
        const center = ref.current?.getPointAtLength?.(v)
        return `${center?.x||'center'} ${center?.y||'center'}`
      })}
      textAnchor='middle'>
        {(percent||0)>=threshold ? value: ''}
    </a.text>
    <a.text 
      x={spring?.point.to(v=>(ref.current?.getPointAtLength?.(v)?.x||0) )} 
      y={spring?.point.to(v=>(ref.current?.getPointAtLength?.(v)?.y||0) + 5 )} 
      transform="rotate(90)"
      transform-origin={spring?.point.to( v=>{
        const center = ref.current?.getPointAtLength?.(v)
        return `${center?.x||'center'} ${center?.y||'center'}`
      })}
      fontSize={10}
      textAnchor='middle'>
        {(percent||0)>=threshold ? percent?.toFixed(0) + '%': ''}
    </a.text>
  </>: null
}
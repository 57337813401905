import styled from 'styled-components';
import Background from '@assets/icons/background.png';
import Logo from '@assets/icons/logo_cropped.png';

export const LandingLayout = styled.div.attrs(({ children }) => ({
  children: (
    <>
      <div>
        <div>
          <span>Welcome to</span>
          <span>
            <span>I</span>
            <img src={Logo} alt="logo" style={{ height: '3.5em' }} />
            <span>sights</span>
            <span>.</span>
          </span>
        </div>
      </div>
      {children}
    </>
  ),
}))`
  > div:first-child {
    color: #ffffff;
    > div {
      align-items: start;
      font-weight: 600;
      > span:first-child {
        font-size: 50px;
        margin-bottom: 1rem;
        display: block;
      }
      > span:last-child {
        > span {
          font-size: 100px;
        }
        > span:last-child {
          color: #35c8d1;
          font-size: 100px;
        }
      }
    }
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #373740;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

import { Typography, Switch, IconButton } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { Close, LayersOutlined } from '@material-ui/icons';
import { useState } from 'react';
import { useSpring, animated as a } from 'react-spring';
import { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';

const AntSwitch = withStyles((theme) =>
  createStyles({
    root: {
      width: '2.8rem',
      height: '1.4rem',
      padding: 0,
      display: 'flex',
      boxSizing: 'border-box',
    },
    switchBase: {
      padding: '.05rem .3rem',
      color: '#969cba',
      '&$checked': {
        transform: 'translateX(1.4rem)',
        color: '#fff',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#35c8d1',
          borderColor: '#969cba',
        },
      },
    },
    thumb: {
      width: '.9rem',
      height: '.9rem',
      boxShadow: 'none',
      marginTop: '0.2rem',
      backgroundColor: '#fff',
    },
    track: {
      // border: `1px solid #969cba`,
      borderRadius: '1.5rem',
      opacity: 1,
      boxSizing: 'border-box',
      backgroundColor: '#969cba',
    },
    checked: {},
  })
)(Switch);

function Row({ children = (<></>) as ReactNode }) {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '0.3rem',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        minHeight: '1.5rem',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      {children}
    </div>
  );
}

export default function SideBar() {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { minWidth, minHeight } = useSpring({
    minWidth: open ? '15rem' : '3.5rem',
    minHeight: open ? '19rem' : '3.5rem',
  });
  // const [check, setCheck] = useState({
  //   boundaries: false,
  //   future: false,
  //   rightmove:false,
  //   elevation:false,
  //   sunlight:false
  // })
  const [check, setCheck] = useRecoilState(atoms.mapLayers);

  return (
    <a.div
      onClick={() => {
        if (!open) setOpen(!open);
      }}
      style={{
        zIndex: 50,
        // position: 'absolute',
        // top: '7rem',
        // right: '2rem',
        position: 'relative',
        backgroundColor: 'rgba(255,255,255,1)',
        minWidth,
        minHeight,
        borderRadius: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        overflow: 'hidden',
        border: hovered
          ? '0.2px solid rgba(0,0,0,0.6)'
          : '0.1px solid rgba(0,0,0,0.2)',
        boxShadow: '0px 7px 5px rgba(0,0,0,0.2)',
        width: 'fit-content'
      }}
      onPointerEnter={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      {!open && <LayersOutlined style={{ pointerEvents: 'none' }} />}
      {open && (
        <div
          style={{
            display: 'flex',
            // position: 'absolute',
            // top: '7rem',
            // left: 0,
            // width: '100%',
            height: '100%',
            flexDirection: 'column',
            padding: '2rem',
            boxSizing: 'border-box',
            textAlign: 'left',
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            style={{ position: 'absolute', top: '1rem', right: '1rem' }}
          >
            <Close />
          </IconButton>
          <Typography
            variant="caption"
            style={{
              textAlign: 'center',
              marginTop: '0.5rem',
              color: '#969CBA',
              fontWeight: 800,
            }}
          >
            Layers
          </Typography>
          <Row>
            <Typography variant="body2">Satellite view</Typography>
            <AntSwitch
              checked={check?.satellite || false}
              onChange={() =>
                setCheck({ ...check, satellite: !check?.satellite })
              }
              name="satellite"
            />
          </Row>
          <Row>
            <Typography variant="body2">Terrain view</Typography>
            <AntSwitch
              checked={check?.terrain || false}
              onChange={() => setCheck({ ...check, terrain: !check?.terrain })}
              name="terrain"
            />
          </Row>
          <Row>
            <Typography variant="body2">2D Contours</Typography>
            <AntSwitch
              checked={check?.contours || false}
              onChange={() =>
                setCheck({ ...check, contours: !check?.contours })
              }
              name="contours"
            />
          </Row>
          {/* <Row>
              <Typography variant="body2">Future</Typography>
              <AntSwitch
                checked={check?.future || false}
                onChange={() => setCheck({ ...check, future: !check?.future })}
                name="future"
              />
            </Row>

            <Typography
              variant="caption"
              style={{ marginTop: '1rem', color: '#969CBA', fontWeight: 800 }}
            >
              3rd Party Data
            </Typography>
            <Row>
              <Typography variant="body2">Rightmove</Typography>
              <AntSwitch
                checked={check?.rightmove || false}
                onChange={() =>
                  setCheck({ ...check, rightmove: !check?.rightmove })
                }
                name="rightmove"
              />
            </Row> */}

          <Typography
            variant="caption"
            style={{
              textAlign: 'center',
              marginTop: '1rem',
              fontWeight: 800,
              color: '#969CBA',
            }}
          >
            Context
          </Typography>
          <Row>
            <Typography variant="body2">Boundaries</Typography>
            <AntSwitch
              checked={check?.boundaries || false}
              onChange={() =>
                setCheck({ ...check, boundaries: !check?.boundaries })
              }
              name="boundaries"
            />
          </Row>

          <Row>
            <Typography variant="body2">3D Buildings</Typography>
            <AntSwitch
              checked={check?.buildings || false}
              onChange={() =>
                setCheck({ ...check, buildings: !check?.buildings })
              }
              name="buildings"
            />
          </Row>
          <Row>
            <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
              Parks & Green Spaces
            </Typography>
            <AntSwitch
              checked={check?.green || false}
              onChange={() => setCheck({ ...check, green: !check?.green })}
              name="green"
            />
          </Row>
        </div>
      )}
    </a.div>
  );
}

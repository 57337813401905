import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  CategoryLegend,
  Text
} from '@ui';
import { LadSupplyQuery } from 'generated';
import { Bar, BarChart, BarLabel, Line, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';
import { BarSeries } from 'misc/reaviz/BarSeries';

export default function NetAdditionalDwellings({ data }:{ data?: LadSupplyQuery}) {
  
  const values = [
    {
      key: '2016',
      data: data?.lad?.supply?.s5_net_additional_2016
    },
    {
      key: '2017',
      data: data?.lad?.supply?.s5_net_additional_2017
    },
    {
      key: '2018',
      data: data?.lad?.supply?.s5_net_additional_2018
    },
    {
      key: '2019',
      data: data?.lad?.supply?.s5_net_additional_2019
    },
    {
      key: '2020',
      data: data?.lad?.supply?.s5_net_additional_2020
    },
    {
      key: '2021',
      data: data?.lad?.supply?.s5_net_additional_2021
    },
    {
      key: '2022',
      data: data?.lad?.supply?.s5_net_additional_2022
    },
    {
      key: '2023',
      data: data?.lad?.supply?.s5_net_additional_2023
    },
    {
      key: '2024',
      data: data?.lad?.supply?.s5_net_additional_2024
    },
    {
      key: '2025',
      data: data?.lad?.supply?.s5_net_additional_2025
    }
  ]

  const min = 0;
  const max = Math.max(...(values.map(v=>v.data) as number[])) * 1.6; 

  const modifiedValues = values?.[0].data && values.map(v=>({
    ...v,
    data: [0, v.data]
  }))

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Net Additional Dwellings </Fonts.Header>
        <ChartRow>
          <ChartContainer ml="2.5rem">
            <BarChart
              style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
              width={500}
              height={250}
              data={modifiedValues}
              centerY
              yAxis={<LinearYAxis domain={[min,max]} />}
              series={
                <BarSeries
                  isCategorical
                  type='standard'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      label={<BarLabel {...{} as any} fill="#666666" position="top" />}
                      color="#666666"
                      style={(data:any) => {
                        if (Number(data.key)<=2020) {
                          return {
                            fill: '#e141e4'
                          };
                        }else{
                          return {
                            fill: '#c4c4c4'
                          };
                        }
                      }}
                    />
                  }
                />
              }
            />
          {modifiedValues && <LineChart
            width={480}
            height={250}
            xAxis={<LinearXAxis axisLine={null} tickSeries={<LinearAxisTickSeries tickValues={[]}/>}  />}
            yAxis={
              <LinearYAxis 
                domain={[min,max]} 
                axisLine={null} 
                tickSeries={
                  <LinearAxisTickSeries tickValues={[]}/>} 
              />}
            data={modifiedValues}
            gridlines={null}
            series={
              <LineSeries
                interpolation="smooth"
                colorScheme='#707070'
                tooltip={<TooltipArea disabled />}
                line={<Line strokeWidth={2} style={{transform:'translate(28px,-10px)'}} />}
              />
            }
          />}
          <CategoryLegend legends={[
            { label:'New units (LT-122)', color: '#e141e4' },
            { label:'Forecasted units (406)', color: '#c4c4c4' }
          ]} position='absolute' right='2rem' top='1rem' />
          <Text customColor='grey' position='absolute' left='-3rem !important' top='90px !important' transform='rotate(-90deg)'># units</Text>

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

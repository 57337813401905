import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';
import { Fab } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { usePolygonsSubscription, useDeletePolygonsMutation } from 'generated';
import { flexDirection } from 'styled-system';

export default function DrawOverlay() {
  const [user] = useRecoilState(atoms.user);
  const { data: request } = usePolygonsSubscription({
    variables: { user_id: user?.id || undefined },
  });
  const [remove] = useDeletePolygonsMutation();

  const [data, setData] = useRecoilState(atoms.drawData);
  const [, setMode] = useRecoilState(atoms.drawMode);
  const [selectedFeatures] = useRecoilState(atoms.drawSelected);

  useEffect(() => {
    if ((request?.user_polygons?.length || 0) > 0) {
      console.log('request');
      setData({
        type: 'FeatureCollection',
        features:
          request?.user_polygons.map(({ id, geometry, ...properties }) => ({
            id,
            geometry,
            type: 'Feature',
            properties,
          })) || [],
      });
    }
  }, [request]);

  function deleteSelected() {
    setData({
      ...data,
      features: [...data.features].filter(
        (v) =>
          !(selectedFeatures.map((s) => s.id) as string[]).includes(
            String(v.id || '')
          )
      ),
    });
    remove({
      variables: { polygons: selectedFeatures.map((s) => s.id) as string[] },
    });
  }

  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '.5rem',
    }}>
      <Fab
        size="large"
        style={{
          // position: 'absolute',
          boxShadow: 'none',
          border: 'solid 1px rgba(0,0,0,0.3)',
          backgroundColor: 'white',
          // right: '2rem',
          // top: '7rem',
          zIndex: 50,
        }}
        onClick={() => setMode('draw_polygon')}
      >
        <Add />
      </Fab>
      <Fab
        size="small"
        style={{
          // position: 'absolute',
          // right: '6rem',
          // top: '7.5rem',
          zIndex: 50,
          boxShadow: 'none',
          border: 'solid 1px rgba(0,0,0,0.3)',
          backgroundColor: 'white',
        }}
        onClick={() => deleteSelected()}
      >
        <Delete />
      </Fab>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';
import Draw from '@urbica/react-map-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Popup, Source, Layer } from '@urbica/react-map-gl';
import {
  usePolygonsSubscription,
  useInsertPolygonsMutation,
  useUpdatePolygonsMutation,
  useDeletePolygonsMutation,
} from 'generated';
import getCentroid from '@turf/centroid';
import getArea from '@turf/area';
import getLength from '@turf/length';

import { v4 as uuid } from 'uuid';
import { Button, TextField, Typography } from '@material-ui/core';

export default function DrawSetup() {
  const [user] = useRecoilState(atoms.user);

  const { data: request } = usePolygonsSubscription({
    variables: { user_id: user?.id || undefined },
  });
  const [insert] = useInsertPolygonsMutation();
  const [update] = useUpdatePolygonsMutation();
  const [remove] = useDeletePolygonsMutation();

  const [data, setData] = useRecoilState(atoms.drawData);
  const [mode, setMode] = useRecoilState(atoms.drawMode);
  const [polygonForm, setPolygonForm] = useRecoilState(atoms.polygonForm);
  const [selectedFeatures, setSelectedFeatures] = useRecoilState(
    atoms.drawSelected
  );
  const [UUID, setUUID] = useState<string>();

  useEffect(() => {
    if ((request?.user_polygons?.length || 0) > 0) {
      console.log('request', request?.user_polygons);
      setData({
        type: 'FeatureCollection',
        features:
          request?.user_polygons.map(({ id, geometry, ...properties }) => ({
            id,
            geometry,
            type: 'Feature',
            properties,
          })) || [],
      });
    }
  }, [request]);

  return (
    <>
      {polygonForm && (
        <Popup
          longitude={polygonForm.centroid[0]}
          latitude={polygonForm.centroid[1]}
          closeButton={false}
          closeOnClick={false}
        >
          <form noValidate autoComplete="off">
            <TextField
              style={{ marginBottom: '0.5rem' }}
              id="name"
              label="Name"
              value={polygonForm.name || ''}
              onChange={(v) =>
                setPolygonForm({ ...polygonForm, name: v.target.value })
              }
              variant="outlined"
            />
            <Typography>
              Area: {getArea(JSON.parse(polygonForm.geom))?.toFixed(0)} sq.m
            </Typography>
            <Typography>
              Perimeter:{' '}
              {(getLength(JSON.parse(polygonForm.geom)) * 1000)?.toFixed(0)} m
            </Typography>
            <Typography>
              Longitude: {polygonForm.centroid[0]?.toFixed(3)}
            </Typography>
            <Typography>
              Latitude: {polygonForm.centroid[1]?.toFixed(3)}
            </Typography>
            <Typography>UUID: {UUID}</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '0.5rem',
              }}
            >
              <Button
                onClick={() => {
                  const [removed, ...other] = [...data.features].reverse();
                  setData({
                    ...data,
                    features: other,
                  } as GeoJSON.FeatureCollection);
                  setPolygonForm(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  const { centroid, ...polygon } = polygonForm;
                  insert({ variables: { polygon: { ...polygon, id: UUID } } });
                  setPolygonForm(null);
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Popup>
      )}
      <Draw
        
        data={data}
        mode={mode}
        modeOptions={{ featureId: selectedFeatures[0]?.id || '' }}
        onChange={(data: GeoJSON.FeatureCollection) => setData(data)}
        onDrawSelectionChange={(e: any) => {
          console.log(e);
          setSelectedFeatures(e?.features || []);
        }}
        uncombineFeaturesControl={false}
        combineFeaturesControl={false}
        pointControl={false}
        lineStringControl={false}
        polygonControl={false}
        trashControl={false}
        onDrawCreate={(e: any) => {
          if (e.features[0] && user?.id) {
            setData({
              ...data,
              features: [...data.features, e.features[0]],
            } as GeoJSON.FeatureCollection);
            const { id, geometry } = e.features[0];
            console.log(getCentroid(geometry));
            setUUID(uuid());
            setPolygonForm({
              id,
              geom: JSON.stringify(geometry),
              centroid: (
                getCentroid(geometry) as GeoJSON.Feature<GeoJSON.Point>
              ).geometry.coordinates,
              user_id: user.id,
            });
            // insert({variables:{polygon:{id, geom:JSON.stringify(geometry), user_id: user?.id}}})
          }
        }}
        onDrawDelete={(e: any) => {
          if (e?.features?.length > 0) {
            remove({
              variables: {
                polygons: e.features.map((v: any) => String(v.id)) as string[],
              },
            });
          }
        }}
        onDrawUpdate={(e: any) => {
          setData({
            ...data,
            features: data.features.map((v) => {
              if (e.features.map((b: any) => b.id).includes(v.id)) {
                return e.features.find((b: any) => b.id === v.id);
              }
              return v;
            }),
          } as GeoJSON.FeatureCollection);

          e.features.forEach((f: GeoJSON.Feature) =>
            update({
              variables: {
                id: f.id as string,
                geometry: f.geometry as GeoJSON.Geometry,
              },
            })
          );
        }}
        onDrawModeChange={({ mode }: { mode: any }) => setMode(mode)}
      />
      <Source
        id="labels"
        type="geojson"
        data={{
          ...data,
          features: data.features.map((f) => ({
            ...f,
            geometry: f?.properties?.centroid || {
              type: 'Point',
              coordinates: [0, 0],
            },
          })),
        }}
      />
      <Layer
        id="labels"
        source="labels"
        type="symbol"
        paint={{
          'text-color': '#3bb2d0',
          'text-halo-color': 'white',
          'text-halo-width': 1,
        }}
        layout={{
          'text-field': ['get', 'name'],
          'text-anchor': 'bottom',
          'text-size': 15,
          'text-allow-overlap': true,
        }}
      />
      <Layer
        id="area"
        source="labels"
        type="symbol"
        paint={{
          'text-color': '#3bb2d0',
          'text-halo-color': 'white',
          'text-halo-width': 1,
        }}
        layout={{
          'text-field': ['concat', ['get', 'area'], ' sq.m.'],
          'text-size': 10,
          'text-anchor': 'top',
          'text-allow-overlap': true,
        }}
      />
    </>
  );
}

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Project from '@assets/icons/project.jpg';

const CssFormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0,0,0,0.2)',
        borderRadius: '1rem',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0,0,0,0.3)',
        borderRadius: '1rem',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0,0,0,0.15)',
        borderRadius: '1rem',
      },
    },
  },
})(FormControl);

export default function Finance() {
  const [tab, setTab] = useState<string>('inputs');
  const [project, setProject] = useState<string>('');
  return (
    <div
        style={{
          width: 'calc(100vw - 5rem)',
          flex: 'none',
          backgroundColor: 'white',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            width: '100%',
            flex: 'none',
            display: 'flex',
            backgroundColor: '#efefef',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            borderBottom: 'solid 1px #ccc',
            padding: '2rem',
            position: 'relative',
            right: 0,
            top: 0,
          }}
        >
          <Typography variant="h5" style={{ display: 'flex' }}>
            Feasibility & Financials
          </Typography>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <div>
              <CssFormControl
                size="small"
                variant="outlined"
                style={{
                  backgroundColor: 'white',
                  width: '15rem',
                  borderRadius: '1rem',
                  marginRight: '2rem',
                }}
              >
                <InputLabel id="project-label">
                  Select Project / Prospect
                </InputLabel>
                <Select
                  labelId="project-label"
                  id="project"
                  value={project}
                  onChange={(e) => setProject(e.target.value as string)}
                  label="Project"
                  defaultValue="Project"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Default">Default</MenuItem>
                </Select>
              </CssFormControl>
            </div>
            <img
              src={Project}
              alt="Project"
              className="project"
              style={{
                width: '5rem',
                objectFit: 'contain',
                position: 'relative',
              }}
            />
          </div>
        </div>
        <div style={{ padding: '0 2rem' }}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
              setTab(newValue);
            }}
            aria-label="Onboarding menu"
            style={{
              marginTop: '1rem',
              width: '100%',
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-between',
            }}
            // TabIndicatorProps={{style:{ top:'2.4rem'}}}
          >
            <Tab
              value="inputs"
              label="Inputs"
              style={{
                padding: '0 0',
                textTransform: 'none',
                minWidth: '4rem',
                color: 'black',
                flexGrow: 1,
              }}
            />
            <Tab
              value="revenue"
              label="Revenue Model"
              style={{
                padding: '0 0',
                textTransform: 'none',
                marginLeft: '2rem',
                minWidth: '4rem',
                color: 'black',
                flexGrow: 1,
              }}
            />
            <Tab
              value="cost"
              label="Cost Model"
              style={{
                padding: '0 0',
                textTransform: 'none',
                marginLeft: '2rem',
                minWidth: '4rem',
                color: 'black',
                flexGrow: 1,
              }}
            />
            <Tab
              value="cash"
              label="Cash Flow"
              style={{
                padding: '0 0',
                textTransform: 'none',
                marginLeft: '2rem',
                minWidth: '4rem',
                color: 'black',
                flexGrow: 1,
              }}
            />
            <Tab
              value="report"
              label="Report"
              style={{
                padding: '0 0',
                textTransform: 'none',
                marginLeft: '2rem',
                minWidth: '4rem',
                color: 'black',
                flexGrow: 1,
              }}
            />
            <Tab
              value="financials"
              label="Financials"
              style={{
                padding: '0 0',
                textTransform: 'none',
                marginLeft: '2rem',
                minWidth: '4rem',
                color: 'black',
                flexGrow: 1,
              }}
            />
          </Tabs>
        </div>
      </div>
  );
}

import { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CssFormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0,0,0,0)',
        borderRadius: '1rem',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0,0,0,0.3)',
        borderRadius: '1rem',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0,0,0,0.15)',
        borderRadius: '1rem',
      },
    },
  },
})(FormControl);

export default function Dropdowns() {
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');

  return (
    <div
      style={{ position: 'absolute', top: '2rem', left: '2rem', zIndex: 60 }}
    >
      <CssFormControl
        variant="outlined"
        style={{
          backgroundColor: 'white',
          width: '10rem',
          borderRadius: '1rem',
        }}
      >
        <InputLabel id="country">Country</InputLabel>
        <Select
          labelId="country"
          id="country"
          value={country}
          onChange={(e) => setCountry(e.target.value as string)}
          label="Country"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>United Kingdom</MenuItem>
          <MenuItem value={20}>France</MenuItem>
          <MenuItem value={30}>Spain</MenuItem>
        </Select>
      </CssFormControl>
      <CssFormControl
        variant="outlined"
        style={{
          backgroundColor: 'white',
          width: '10rem',
          marginLeft: '1rem',
          borderRadius: '1rem',
        }}
      >
        <InputLabel id="city">City</InputLabel>
        <Select
          labelId="city"
          id="city"
          value={city}
          onChange={(e) => setCity(e.target.value as string)}
          label="City"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>London</MenuItem>
          <MenuItem value={20}>Manchester</MenuItem>
          <MenuItem value={30}>Sheffield</MenuItem>
        </Select>
      </CssFormControl>
    </div>
  );
}

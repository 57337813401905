import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  Center,
  Text
} from '@ui';
import { LadDemandQuery } from 'generated';
import { Line, LinearAxisTickLabel, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';

export default function HouseholdWaitlist({ data }:{ data?: LadDemandQuery}) {
  
  const householdData = [
    {
      key: '2016',
      data: data?.lad?.demand?.d2_waitlist_2016
    },
    {
      key: '2017',
      data: data?.lad?.demand?.d2_waitlist_2017
    },
    {
      key: '2018',
      data: data?.lad?.demand?.d2_waitlist_2018
    },
    {
      key: '2019',
      data: data?.lad?.demand?.d2_waitlist_2019
    },
    {
      key: '2020',
      data: data?.lad?.demand?.d2_waitlist_2020
    }
  ]

  const min = Math.min(...(householdData.map(v=>v.data) as number[])) * 0.8;
  const max = Math.max(...(householdData.map(v=>v.data) as number[])) * 1.2; 

  const modifiedHouseholdData = householdData.map(v=>({
    ...v,
    data: [min, v.data]
  }))



  return (
    <StatsRow>
      <div>
        <ChartRow>
          <ChartContainer ml="0.5rem">
            <div style={{width:'240px', height:'160px', display:'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign:'center'}}>
                <div><Fonts.Subheader>Council-declared Housing shortfall:</Fonts.Subheader></div>
                <Fonts.Header mt='1rem'>
                  {data?.lad?.demand?.d1_housing_need.toLocaleString()}
                  <Fonts.Area>{` `}units</Fonts.Area>

                </Fonts.Header>
            </div>
          </ChartContainer>
          <ChartContainer ml="0px">
            <LineChart
              width={240}
              height={200}
              yAxis={<LinearYAxis
                domain={[min,max]}
                />}
              xAxis={<LinearXAxis type="value" tickSeries={
                <LinearAxisTickSeries label={
                  <LinearAxisTickLabel 
                    padding={{
                      fromAxis: 1,
                      alongAxis: 0
                    }}
                    position='end'
                    format={(v)=>`${v}`}/>} 
                    interval={5}/>} />} 
              data={modifiedHouseholdData}
              series={
                <LineSeries
                  interpolation="linear"
                  colorScheme="#34c3ca"
                  line={<Line strokeWidth={2} />}
                />
              }
            />
            <Text mt="-2.8rem" ml="1rem" customSize='t5'>Number of Households awaiting housing:</Text>

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
.custom-popup{
  opacity:0.95;
  font: 10px Helvetica;
  max-width: fit-content;
  max-height: none;
}
.mapboxgl-popup-content {
  overflow: hidden;
  z-index: 2;
  display: 'block';
  background-color:#eeeeee;
  opacity: 0.85;
  box-shadow: -15px 15px 15px rgba(0,0,0,0.2);
  text-align: left;
  padding: 1rem;
  border-radius: 0.5rem;
}
::-webkit-scrollbar {
  display: none;
}
body, html, #root {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
  height: 100%;
  padding: 0;
  margin:0;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin: 0 2rem 10px 0;
}
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  margin-top: 2rem;
  margin-right: 2rem;
  min-height: 3rem;
  background-color: rgba(255,255,255,0.8);
  min-width: 18rem;
  border-radius: 2rem;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  position: relative;
  z-index: 100;
}

svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
  transform: scale(1.8);
  fill: grey;
  box-sizing: border-box;
  margin-right: 1rem;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

input.mapboxgl-ctrl-geocoder--input {
  border:0;
  box-sizing: border-box;
  background: none;
  height: 1.5rem;
  font-size: 1rem;
  outline: none;
  box-sizing: border-box;
}

button.mapboxgl-ctrl-geocoder--button {
  border:none;
  outline: none;
  box-sizing: border-box;
  background: none;
}

div.suggestions-wrapper {
  position: absolute;
  transform: translateY(calc(100% - 2rem));
  bottom: 0;
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-width: 300;
  overflow: auto;
  background-color: rgba(255,255,255,0.8);
  z-index: -1;
  margin-left: -1rem;
  text-align: left;
}

div.suggestions-wrapper ul.suggestions {
  padding-top: 2rem;
}

div.mapboxgl-ctrl-geocoder--pin-right {
  width: 30px;
  max-width: 30px;
}

svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-loading{
  display: none;
}

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}
.tooltip{

}
.tooltip>div{
  padding: 4px 6px;
  border-radius: 3px;
  background-color: rgba(124, 211, 215, .8);
  gap: 0;

}
`;

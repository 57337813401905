import { atoms } from "misc";
import { useRecoilValue } from "recoil";

function Source() {
    // the displayed data source, not used anymore
    // const dataSource = useRecoilValue(atoms.displayedDataSource);
    const viewport = useRecoilValue(atoms.viewport);
    const dataLayers = atoms.dataLayers;
    // add source of the layers if it's not already there
    const hoverDataSources = eliminateDuplicates(dataLayers.map((layer) => layer.source));
    // const hoverDataLayers = useRecoilValue(atoms.hoverDataSources);
    return (viewport.zoom < 6)? 
    <div style={{ position: 'absolute', bottom: '0.5rem', right: '7rem', zIndex:1, opacity: .5, fontSize: '.8em'}}>
        { 'Sources: ' + hoverDataSources.join(' - ')}
    </div>:<></>;
}

const eliminateDuplicates = (arr:string[]) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

export default Source;
import {
  StatsRow,
  Fonts,
  ChartContainer,
  ChartRow,
  Text
} from '@ui';
import { LadDemandQuery } from 'generated';
import { Bar, BarChart, BarLabel, BarSeries, ChartTooltip, Line, LinearAxisTickLabel, LinearAxisTickSeries, LinearXAxis, LinearYAxis, LineChart, LineSeries, TooltipArea } from 'reaviz';

export default function HouseholdSize({ data }:{ data?: LadDemandQuery}) {
  
  const householdSizeData = [
    {
      key: '2019',
      data: data?.lad?.demand?.d8_household_size_2019
    },
    {
      key: '2024',
      data: data?.lad?.demand?.d8_household_size_2024
    },
    {
      key: '2029',
      data: data?.lad?.demand?.d8_household_size_2029
    },
    {
      key: '2034',
      data: data?.lad?.demand?.d8_household_size_2034
    },
    {
      key: '2039',
      data: data?.lad?.demand?.d8_household_size_2039
    }
  ]

  const sizeMin = Math.min(...(householdSizeData.map(v=>v.data) as number[])) * 0.8;
  const sizeMax = Math.max(...(householdSizeData.map(v=>v.data) as number[])) * 1.2; 

  const modifiedHouseholdSizeData = householdSizeData.map(v=>({
    ...v,
    data: [sizeMin, v.data]
  }))

  //d3_household_demand_2016
  const householdDemandData = [
    {
      key: '2016',
      data: data?.lad?.demand?.d3_household_demand_2016
    },
    {
      key: '2017',
      data: data?.lad?.demand?.d3_household_demand_2017
    },
    {
      key: '2018',
      data: data?.lad?.demand?.d3_household_demand_2018
    },
    {
      key: '2019',
      data: data?.lad?.demand?.d3_household_demand_2019
    },
    {
      key: '2020',
      data: data?.lad?.demand?.d3_household_demand_2020
    }
  ]

  const demandMin = Math.min(...(householdDemandData.map(v=>v.data) as number[])) * 0.8;
  const demandMax = Math.max(...(householdDemandData.map(v=>v.data) as number[])) * 1.2; 

  const modifiedHouseholdDemandData = householdDemandData.map(v=>({
    ...v,
    data: [demandMin, v.data]
  }))

  return (
    <StatsRow>
      <div>
        <Fonts.Header>Average Household Size <Fonts.Subheader>(est.):</Fonts.Subheader></Fonts.Header>
        <ChartRow>
          <ChartContainer ml="0.5rem">
            <BarChart
              style={{marginLeft: '1rem', left: '2rem', paddingLeft: '2rem'}}
              width={220}
              height={175}
              data={modifiedHouseholdSizeData}
              centerY
              yAxis={<LinearYAxis
              domain={[sizeMin,sizeMax]}
              />}
              series={
                <BarSeries
                  isCategorical
                  type='standard'
                  tooltip={null}
                  bar={
                    <Bar
                      gradient={null}
                      label={<BarLabel {...{} as any} fill="#34c3ca" position="top" />}
                      color="#34c3ca"
                      style={(data:any) => {
                        if (data.key) {
                          return {
                            fill: '#34c3ca'
                          };
                        }
                      }}
                    />
                  }
                />
              }
            />
            <LineChart
              width={200}
              height={175}
              xAxis={<LinearXAxis axisLine={null} tickSeries={<LinearAxisTickSeries tickValues={[]}/>}  />}
              yAxis={
                <LinearYAxis domain={[sizeMin,sizeMax]} axisLine={null} tickSeries={
                  <LinearAxisTickSeries tickValues={[]}/>} />}
              data={modifiedHouseholdSizeData}
              gridlines={null}
              series={
                <LineSeries
                  interpolation="smooth"
                  colorScheme='#707070'
                  tooltip={<TooltipArea disabled />}
                  
                  line={<Line strokeWidth={2} style={{transform:'translateX(24px)'}} />}
                />
              }
            />
            <Text customColor='grey' position='absolute' left='-3rem !important' top='70px !important' transform='rotate(-90deg)'># people</Text>

          </ChartContainer>
          <ChartContainer ml="40px">
            <LineChart
              width={220}
              height={200}
              yAxis={<LinearYAxis
                domain={[demandMin,demandMax]}
                />}
              xAxis={<LinearXAxis type="value" tickSeries={
                <LinearAxisTickSeries label={
                  <LinearAxisTickLabel 
                    padding={{
                      fromAxis: 1,
                      alongAxis: 0
                    }}
                    position='end'
                    format={(v)=>`${v}`}/>} 
                    interval={5}/>} />} 
              data={modifiedHouseholdDemandData}
              series={
                <LineSeries
                  interpolation="linear"
                  colorScheme="#34c3ca"
                  line={<Line strokeWidth={2} />}
                />
              }
            />
            <Text mt="-3.2rem" ml="1rem" customSize='t5'>Total estimated household demand:</Text>
            <Text customColor='grey' position='absolute' left='-4.5rem !important' top='70px !important' transform='rotate(-90deg)'># people</Text>

          </ChartContainer>
        </ChartRow>
      </div>
    </StatsRow>
  );
}

import styled, { css } from 'styled-components'
import { ReactComponent as ArrowUp } from '@assets/icons/arrow-circle.svg'

export const Arrow = styled.div.attrs({
  children: <ArrowUp />
})<{up?:boolean}>`
  >svg{
    width: 16px;
    height: 16px;
    border-radius: 8px;
    fill: white;
    stroke: white;
    stroke-width: 2;
    ${({up}) => up ? 
      css`
        transform: rotate(-90deg);
        background-color: #4fd1d9;
      `:
      css`
        transform: rotate(90deg);
        background-color: #e141e4;
      `
    }
  }
`

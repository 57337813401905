import { Header, Wrapper, TextInfo, MailIcon } from './styles';
import { useRecoilState } from 'recoil';
import Signup from './Signup';
import { auth, useQuery } from 'misc';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AccountVerified() {
  const [user] = useRecoilState(Signup.user);
  const ticket = useQuery().get('ticket');
  const navigate = useNavigate();

  useEffect(() => {
    if (ticket) {
      auth
        .activate(ticket)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      //window.location.href = `${BACKEND_ENDPOINT}/auth/activate?ticket=${ticket}`
    }
  }, [ticket]);

  return (
    <div>
      <Wrapper>
        <MailIcon />
        <Header>Account Verified</Header>
        <TextInfo>
          Congratulations {user.options.userData.first_name}! Your email has
          been verified. We'll notify you shortly once your account has been
          activated!
        </TextInfo>
        <span>Copyright &copy; InsightsNow, 2023</span>
      </Wrapper>
    </div>
  );
}

import styled, { css } from "styled-components"
import { systems, Systems, theme } from "@ui/common"

type colorTypes = keyof typeof theme.colors
const colors:colorTypes[] = Object.keys(theme.colors) as colorTypes[]
export type TextTypes = 't1'|'t2'|'t3'|'t4'|'t5'|'t6'|'t7'

export const 
Text = styled.span.attrs(({
  button
}:{
  button?:boolean
})=>(button?{as:'button'}:{}))
<{ semibold?: boolean, bold?:boolean, customSize?: TextTypes, customColor?: colorTypes|string, button?: boolean } & Systems >`
  ${({
    semibold,
    bold,
    customSize='t4',
    ...p
  })=>bold?
    p.theme.font.body.bold[customSize]:
    semibold?
    p.theme.font.body.semibold[customSize]:
    p.theme.font.body.regular[customSize] }
  text-align: center; 
  line-height: normal;
  ${p=>p.customColor && (
    colors.includes(p.customColor as colorTypes) ?
      css`color: ${p.theme.colors[p.customColor as colorTypes]};`:
      css`color: ${p.customColor};`
  )}
  ${p=>p.button && css`
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
  `}
  >svg{
    margin-right: 8px;
  }
  ${systems}
`,

Divider = styled.div<{ customColor?: colorTypes } & Systems>`
  width: 100%;
  height: 1px;
  background: rgba(0,0,0,0.1);
  margin-top: 2rem;
  margin-bottom: 1rem;
  ${p=>p.customColor && (
    colors.includes(p.customColor) ?
      css`background: ${p.theme.colors[p.customColor]};`:
      css`background: ${p.customColor};`
  )}
  ${systems}

`
import { Source, FeatureState } from '@urbica/react-map-gl';
import { useRecoilState } from 'recoil';
import { atoms } from 'misc';

export default function Sources() {
  const [hovered, setHovered] = useRecoilState(atoms.hovered);
  const [selected, setSelected] = useRecoilState(atoms.selected);
  //temporary fix: add:
  // const tileserver = 'https://api.insightsnow.co.uk';
  //temporary fix: delete:
  // const tileserver = process.env.REACT_APP_TILESERVER??'https://app.insightsnow.co.uk:4000';
  const tileserver = process.env.REACT_APP_TILESERVER??'https://api.insightsnow.co.uk';
  
  return (
    <>
      <Source
        id="country_extended"
        tiles={[
          tileserver+'/tiles/public.country_extended/{z}/{x}/{y}.pbf',
        ]}
        type="vector"
        promoteId="id"
        maxzoom={3}
      />

      <Source
        id="ctryuk"
        tiles={[
          tileserver+'/tiles/public.ctryuk/{z}/{x}/{y}.pbf',
        ]}
        type="vector"
        promoteId="id"
        minzoom={3}
        maxzoom={6}
      />

      <Source
        id="eer"
        tiles={[
          tileserver+'/tiles/public.eer/{z}/{x}/{y}.pbf',
        ]}
        type="vector"
        promoteId="id"
        minzoom={6}
        maxzoom={10}
      />

      <Source
        id="lad"
        tiles={[
          tileserver+'/tiles/public.lad/{z}/{x}/{y}.pbf',
        ]}
        type="vector"
        promoteId="id"
        minzoom={10}
      />

      <FeatureState
        id={selected?.id !== hovered?.id ? hovered?.id || '' : ''}
        source="ctryuk"
        sourceLayer="public.ctryuk"
        state={{ hover: true }}
      />
      <FeatureState
        id={selected?.id !== hovered?.id ? hovered?.id || '' : ''}
        source="eer"
        sourceLayer="public.eer"
        state={{ hover: true }}
      />
      <FeatureState
        id={selected?.id !== hovered?.id ? hovered?.id || '' : ''}
        source="lad"
        sourceLayer="public.lad"
        state={{ hover: true }}
      />

      <FeatureState
        id={selected?.id || ''}
        source="ctryuk"
        sourceLayer="public.ctryuk"
        state={{ selected: true }}
      />
      <FeatureState
        id={selected?.id || ''}
        source="eer"
        sourceLayer="public.eer"
        state={{ selected: true }}
      />
      <FeatureState
        id={selected?.id || ''}
        source="lad"
        sourceLayer="public.lad"
        state={{ selected: true }}
      /> 
    </>
  );
}

import { atoms, auth, deleteCookie, getCookie } from 'misc';
import { useEffect, useState } from 'react';
import {
  ButtonRow,
  Header,
  LockIcon,
  Wrapper,
  Button,
  Snackbar,
  Alert,
  TextInput,
  InputRow,
} from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export default function Signin() {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState<any>();
  const [,setUserProfile] = useRecoilState(atoms.userProfile);
  const [notification, setNotification] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    error && console.log(error);
  }, [error]);

  return (
    <>
      <div>
        <Wrapper>
          <LockIcon />
          <Header>Sign In</Header>
          <InputRow>
            <TextInput
              required
              value={credentials.email}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  email: e.target.value,
                })
              }
              id="email"
              placeholder="Email address *"
            />
          </InputRow>
          <InputRow>
            <TextInput
              required
              value={credentials.password}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  password: e.target.value,
                })
              }
              type="password"
              id="password"
              placeholder="Password *"
            />
          </InputRow>
          <ButtonRow>
            <Button
              onClick={() =>
                auth
                  .login(credentials)
                  .then((response) => {
                    // console.log('resp', response);
                    if (!response.session) {
                      setNotification(
                        'Please, check your email (also, spam folder) and' +
                          'approve your registration.'
                      );
                    }
                    const c = getCookie('redirect');
                    setUserProfile(false);
                    
                    if (c) {
                      console.log('Navigated to ', c);
                      navigate(c);
                      deleteCookie('redirect');
                    }
                  })
                  .catch((err) => {
                    setError(
                      err?.response?.data?.message?.replaceAll('"', '') ||
                        err.message
                    );
                  })
              }
            >
              Login
            </Button>
            {/* <Button onClick={() => auth.login({ provider: 'facebook' })}>
              <Facebook style={{ color: 'white' }} fontSize="large" />
            </Button>
            <Button onClick={() => auth.login({ provider: 'google' })}>
              <img src={google2} style={{height:'1.5rem'}} />
            </Button> */}
          </ButtonRow>
          <span>
            <Link to="/auth/forgot-password">Forgot password?</Link>
            <Link to="/auth/signup">Don't have an account? Sign up</Link>
          </span>

          <span>Copyright &copy; InsightsNow, 2023</span>
        </Wrapper>
      </div>

      <Snackbar open={!!error} onClose={() => setError(undefined)}>
        <Alert onClose={() => setError(undefined)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!notification}
        onClose={() => setNotification(undefined)}
      >
        <Alert onClose={() => setNotification(undefined)} severity="success">
          {notification}
        </Alert>
      </Snackbar>
    </>
  );
}

import { useRecoilState, useRecoilValue } from 'recoil';
import { atoms, auth } from 'misc';
import { Avatar, Typography, IconButton, Link } from '@material-ui/core';
// import { ThemeProvider as StyledTheme } from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme } from '@ui/common';
import { ExitToApp, Close } from '@material-ui/icons';
import {
  ProfileWrapper,
  Button,
  ButtonRow,
  InputRow,
  TextInput,
  EditProfileWrapper,
} from './styles';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useUpdateUserMutation, useUserQuery } from 'generated/graphql';
import { toast } from 'react-hot-toast';
import {} from 'containers/Landing/Forms/styles';
import { userProfile } from 'misc/recoil/atoms';
// import { ThemeProvider } from 'styled-components';

// const darkTheme = createTheme({
//   palette: {
//     mode: 'light',
//   },
// });

export default function Profile() {
  // const id = useUserId();
  // const user = useUserQuery({ variables: { id } }).data?.user;
  const user = useRecoilValue(atoms.user);
  const [, setUserProfile] = useRecoilState(atoms.userProfile);
  const [editMode, setEditMode] = useState(false);

  const [userId, setUserId] = useState(user?.id);
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [companyId] = useState(user?.company?.id || '');
  const [companyName, setCompanyName] = useState(user?.company?.name || '');
  // const [email, setEmail] = useState(user?.account?.email || '');
  const [
    mutateUser,
    { data: updatedData, loading: updatingProfile, error: updateError },
  ] = useUpdateUserMutation();
  const updateUserProfile = async (e: FormEvent) => {
    e.preventDefault();
    console.log('update user profile', user);
    try {
      await mutateUser({
        variables: {
          id: userId,
          first_name: firstName || '',
          last_name: lastName || '',
          company_id: companyId || '',
          company_name: companyName || '',
        },
      });
      toast.success('Updated succesfully', { id: 'updateProfile' });
    } catch (error) {
      toast.error(`'Unable to update profile'${error}`, {
        id: 'updateProfile',
      });
    }
  };
  useEffect(() => {
    setUserId(user?.id);
  }, [user?.id]);
  useEffect(() => {
    if (!userProfile)
      setEditMode(false);
  }, []);
  return !editMode ? (
    <ProfileWrapper>
      <IconButton
        onClick={() => setUserProfile(false)}
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          color: 'white',
        }}
      >
        <Close />
      </IconButton>
      <Avatar
        style={{
          maxWidth: '3rem',
          cursor: 'pointer',
          maxHeight: '3rem',
          margin: '1rem auto',
          position: 'relative',
        }}
        alt={user?.display_name || 'User'}
        src={user?.avatar_url || ''}
      >
        {(user?.display_name || 'User')
          .split(' ')
          .map((l: string) => l.slice(0, 1).toUpperCase())
          .join('')}
      </Avatar>
      <Typography variant="subtitle1">{firstName + ' ' + lastName}</Typography>
      {user?.company?.name && (
        <Typography variant="body2">{user?.company?.name}</Typography>
      )}
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        {user?.account?.email}
      </Typography>
      <Link
        variant="overline"
        style={{ marginTop: '1rem', cursor: 'pointer' }}
        onClick={() => {
          setEditMode(true);
          console.log('eedit mode');
        }}
      >
        Edit details
      </Link>
      {/* <Typography variant="body2">+44 123 123 1234</Typography> */}
      <Typography
        onClick={() => {
          auth.logout();
        }}
        variant="subtitle1"
        style={{
          marginTop: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        Logout <ExitToApp fontSize="large" style={{ marginLeft: '0.5rem' }} />
      </Typography>
    </ProfileWrapper>
  ) : (
    <EditProfileWrapper>
      <form onSubmit={updateUserProfile}>
        <InputRow>
          <TextInput
            required
            label="First name"
            value={firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
              console.log('e.target.value', e.target.value);
            }}
            // sx:{{color: white;}}
            placeholder="First name *"
            disabled={updatingProfile}
          />
        </InputRow>
        <InputRow>
          <TextInput
            required
            label="Last name"
            value={lastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setLastName(e.target.value)
            }
            placeholder="Last name *"
            disabled={updatingProfile}
          />
        </InputRow>
        <InputRow>
          <TextInput
            required
            label="Company"
            value={companyName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCompanyName(e.target.value)
            }
            placeholder="Company *"
            disabled={updatingProfile}
          />
        </InputRow>
        {/* <InputRow>
          <TextInput
            required
            label="E-mail address"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            placeholder="E-mail address *"
            disabled={updatingProfile}
          />
        </InputRow> */}
        <ButtonRow>
          <button
            type="submit"
            disabled={updatingProfile}
            onClick={(e: FormEvent) => {
              setEditMode(false);
              updateUserProfile(e);
              console.log('view mode');
            }}
          >
            Save
          </button>
        </ButtonRow>

        <Link
          variant="overline"
          style={{ marginTop: '1rem', cursor: 'pointer' }}
          onClick={() => {
            setEditMode(false);
            console.log('view mode');
          }}
        >
          Go Back
        </Link>
      </form>
    </EditProfileWrapper>
  );
}
